import styled, { css } from 'styled-components'
import { ThemeColor } from '../../utils/helpers'
import { IconButton } from '../../basic-components/IconButton'

export const BottomSheetWrapper = styled.div<{
  backgroundColor: string
  useDefaultPadding: boolean
  handlebarColor: string | ThemeColor
  minWidth: number
}>(
  ({
    theme: {
      xyz: { spacing, color, zIndex },
    },
    backgroundColor,
    useDefaultPadding,
    handlebarColor,
    minWidth,
  }) => css`
    position: relative;
    visibility: visible;
    z-index: ${zIndex.zIndex100};
    border-radius: 6px 6px 0 0;
    box-shadow: 0px 6px 37px rgba(57, 75, 88, 0.33);
    height: 100%;
    max-height: 100%;
    width: 100%;
    min-width: ${minWidth}px;
    display: flex;
    flex-direction: column;
    background-color: ${backgroundColor ? color[backgroundColor] || backgroundColor : color.neutralWhite};
    padding-bottom: ${spacing.space2}rem;

    ${useDefaultPadding &&
    css`
      padding: ${spacing.space5}rem ${spacing.space0}rem ${spacing.space3}rem ${spacing.space4}rem;
    `}

    &:before {
      position: absolute;
      content: '';
      border-radius: 0.375rem;
      top: ${spacing.space2}rem;
      left: 50%;
      transform: translateX(-50%);
      right: 50%;
      width: 3.25rem;
      height: 0.375rem;
      background-color: ${handlebarColor ? color[handlebarColor] || handlebarColor : color.neutralNetworkGray};
    }
  `
)

export const HeadlineAligner = styled.div<{ useDefaultPadding?: boolean }>`
  ${({
    theme: {
      xyz: { spacing },
    },
    useDefaultPadding,
  }) => css`
    position: relative;
    display: flex;
    word-break: break-word;
    padding-bottom: ${spacing.space2}rem;
    padding-right: ${useDefaultPadding ? `${spacing.space3}rem` : 0};
    padding-top: ${useDefaultPadding ? 0 : `${spacing.space5}rem`};
  `}
`

export const BottomSheetContent = styled.div<{
  size: number
  scrollbarColor?: string
  scrollbarBackgroundColor?: string
  useDefaultPadding?: boolean
  contentHeight?: string
  fullHeight?: boolean
}>`
  ${({
    theme: {
      xyz: { spacing, color },
    },
    size,
    scrollbarColor,
    scrollbarBackgroundColor,
    useDefaultPadding,
    contentHeight,
  }) => css`
    width: 100%;
    overflow-y: auto;
    scrollbar-color: ${scrollbarColor ? color[scrollbarColor] || scrollbarColor : color.neutralPassiveGray}
      ${scrollbarBackgroundColor ? color[scrollbarBackgroundColor] || scrollbarBackgroundColor : color.neutralWhite};
    scrollbar-width: thin;
    word-break: break-word;
    max-height: ${size}px;
    height: ${contentHeight ? contentHeight : 'auto'};
    padding-right: ${useDefaultPadding ? spacing.space3 : spacing.space0}rem;

    ::-webkit-scrollbar {
      width: ${spacing.space3}rem;
      background-color: ${scrollbarBackgroundColor
        ? color[scrollbarBackgroundColor] || scrollbarBackgroundColor
        : color.neutralWhite};
    }

    ::-webkit-scrollbar-thumb {
      background: ${scrollbarColor ? color[scrollbarColor] || scrollbarColor : color.neutralPassiveGray};
      background-clip: padding-box;
      border: 6px solid rgba(0, 0, 0, 0);
      border-radius: 25px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${scrollbarColor ? color[scrollbarColor] || scrollbarColor : color.neutralPassiveGray};
      background-clip: padding-box;
    }

    ::-webkit-scrollbar-thumb:active {
      background: ${scrollbarColor ? color[scrollbarColor] || scrollbarColor : color.neutralPassiveGray};
      background-clip: padding-box;
    }

    ::-webkit-scrollbar-track {
      background-color: ${scrollbarBackgroundColor
        ? color[scrollbarBackgroundColor] || scrollbarBackgroundColor
        : color.neutralWhite};
    }
  `}
`

export const BackDrop = styled.div<{ open: boolean }>(
  ({
    theme: {
      xyz: { zIndex },
    },
    open,
  }) => css`
    visibility: ${open ? 'visible' : 'hidden'};
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    z-index: ${zIndex.zIndex99};
    display: flex;
    align-items: flex-end;
    left: 0;
    bottom: 0;
    background-color: rgba(38, 44, 48, 0.64);
  `
)

export const StyledCloseButton = styled(IconButton)`
  ${({
    theme: {
      xyz: { spacing, zIndex },
    },
  }) => css`
    position: absolute;
    top: ${spacing.space2}rem;
    right: ${spacing.space2}rem;
    z-index: ${zIndex.zIndex10};
    border: none;

    &:hover,
    &:focus {
      background-color: transparent;
      border: none;
    }
  `}
`
