import React, { HTMLAttributes, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { SearchWithSuggestions } from '../../basic-components/SearchWithSuggestions'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import { IconButton } from '../../basic-components/IconButton'
import { CloseIcon } from '../../design-tokens/icons'
import { InputContent } from '../../basic-components/Input/Input.style'
import { useTheme } from '../../utils/useTheme'

const TopBarSearchContainer = styled.div`
  margin-right: 1rem;
  width: 100%;
`

const TopBarSearchBar = styled(SearchWithSuggestions)<{ isDark: boolean }>(
  ({
    theme: {
      xyz: { spacing },
    },
    isDark,
  }) => css`
    width: 100%;
    margin: 0 ${spacing.space2}rem;
    display: flex;
    align-items: center;

    + div {
      box-shadow: none;
      padding: ${spacing.space2}rem ${spacing.space4}rem;
    }

    ${InputContent} {
      height: 3.5rem;
      min-height: auto;

      ${isDark &&
      css`
        box-shadow: none;
      `}
    }
  `
)

const StyledIconButton = styled(IconButton)<{ isDark: boolean }>(
  ({
    theme: {
      xyz: { color },
    },
  }) => css`
    &:focus {
      outline: none;
      box-shadow: 0px 0px 7px ${color.signalBlue};
      border-color: transparent;
      background-color: ${color.neutralOnPressGray};
    }

    &:hover {
      background-color: ${color.neutralOnPressGray};
      border-color: transparent;
    }
  `
)

export interface TopBarSearchProps extends HTMLAttributes<HTMLInputElement> {
  id: string
  message?: string
  autoFocus?: boolean
  closeSearchLabel: string
  isDark?: boolean
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSearchSubmit: (query: string) => void
  onClose: () => void
  onItemSelect?: (value: any) => void
}

const TopBarSearch: React.FC<TopBarSearchProps> = ({
  id,
  message,
  isDark,
  closeSearchLabel,
  autoFocus,
  onFocus,
  onBlur,
  onSearchSubmit,
  onChange,
  onClose,
  onItemSelect,
  ...rest
}) => {
  const {
    xyz: { color },
  } = useTheme()

  const [value, setValue] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget?.value)
    onChange(e)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === KeyboardKeys.Escape) {
      e.preventDefault()
      onClose()
    }
  }

  const searchSubmitHandler = () => onSearchSubmit(value)

  return (
    <TopBarSearchContainer role="search" {...rest}>
      <TopBarSearchBar
        id={id}
        onKeyDown={handleKeyDown}
        label={message}
        onEnter={searchSubmitHandler}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={handleChange}
        value={value}
        isDark={isDark}
        rightTemplate={
          <StyledIconButton
            icon={CloseIcon}
            isDark={isDark}
            aria-label={closeSearchLabel}
            iconColor={color.neutralNetworkGray}
            onClick={onClose}
          />
        }
        autoFocus={autoFocus}
        onItemSelect={onItemSelect}
      />
    </TopBarSearchContainer>
  )
}

TopBarSearch.defaultProps = {
  message: 'Search...',
  autoFocus: true,
}

TopBarSearch.propTypes = {
  message: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSearchSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  isDark: PropTypes.bool,
  closeSearchLabel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onItemSelect: PropTypes.func,
}

export default TopBarSearch
