import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

interface Props extends HTMLAttributes<HTMLDivElement> {
  id: string
  open: boolean
}

const BodyWrapper = styled.div<{ open: boolean }>(
  ({ theme, open }) => css`
    padding: ${open ? `${theme.xyz.spacing.space2}rem` : '0'};
    margin: 0;
    opacity: ${open ? 1 : 0};
    visibility: ${open ? 'visible' : 'hidden'};
    user-select: none;
    ${!open &&
    css`
      height: 0;
      display: none;
    `}

    transition: opacity 200ms ease-in-out, height 200ms ease, padding 200ms ease, margin 200ms ease;
  `
)

const ExpanderBody: React.FC<Props> = ({ open, children, ...rest }) => {
  return (
    <BodyWrapper open={open} aria-hidden={!open} role="region" {...rest}>
      {children}
    </BodyWrapper>
  )
}

ExpanderBody.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
}

export default ExpanderBody
