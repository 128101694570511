import React from 'react'
import PropTypes from 'prop-types'

import { XyzTheme } from '@postidigital/posti-theme'
import useRadioGroup from '../RadioGroup/UseRadioGroup'
import {
  StyledRoot,
  StyledContainer,
  StyledInput,
  StyledLabel,
  StyledSuccessIcon,
  StyledRadio,
  StyledIconWrapper,
} from './Radio.style'

export enum RadioLabelPosition {
  left = 'left',
  right = 'right',
}

export enum RadioMode {
  default = 'default',
  success = 'success',
}

export enum RadioSize {
  small = 'small',
  default = 'default',
}

export interface RadioProps {
  label?: string | JSX.Element
  name: string
  value: string | number
  size?: RadioSize | keyof typeof RadioSize
  mode?: RadioMode | keyof typeof RadioMode
  labelPosition?: RadioLabelPosition | keyof typeof RadioLabelPosition
  isInvalid?: boolean
  disabled?: boolean
  checked?: boolean
  inputProps?: React.HTMLAttributes<HTMLInputElement>
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const {
    value,
    label,
    size,
    mode,
    name: nameProp,
    checked: checkedProp,
    disabled: disabledProp,
    labelPosition,
    isInvalid,
    inputProps,
    onChange: onChangeProp,
    ...rest
  } = props
  const radioGroup = useRadioGroup()
  let checked = checkedProp
  const name = nameProp
  let onChange = onChangeProp

  if (radioGroup) {
    if (typeof checked === 'undefined') {
      checked = radioGroup.value === value
    }

    if (radioGroup.onChange) {
      onChange = radioGroup.onChange
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  const renderRadio = () => (
    <StyledContainer small={size === RadioSize.small} success={mode === RadioMode.success} isInvalid={isInvalid}>
      <StyledInput
        ref={ref}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        disabled={disabledProp}
        aria-checked={checked}
        onChange={handleChange}
        {...inputProps}
      />
      <StyledRadio />
      {mode === RadioMode.success && (
        <StyledIconWrapper>
          <StyledSuccessIcon color={disabledProp ? XyzTheme.color.neutralGray5 : XyzTheme.color.signalGreen} />
        </StyledIconWrapper>
      )}
    </StyledContainer>
  )

  return (
    <StyledRoot {...rest}>
      {label ? (
        <StyledLabel labelPosition={labelPosition as RadioLabelPosition}>
          {labelPosition === RadioLabelPosition.left && label}
          {renderRadio()}
          {labelPosition === RadioLabelPosition.right && label}
        </StyledLabel>
      ) : (
        renderRadio()
      )}
    </StyledRoot>
  )
})

Radio.displayName = 'Radio'

Radio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(Object.values(RadioSize)),
  mode: PropTypes.oneOf(Object.values(RadioMode)),
  labelPosition: PropTypes.oneOf(Object.values(RadioLabelPosition)),
  isInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
}

Radio.defaultProps = {
  size: RadioSize.default,
  mode: RadioMode.default,
  labelPosition: RadioLabelPosition.left,
  isInvalid: false,
  name: 'radio',
}
