import React, { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Label, LabelSize } from '../../design-tokens/typography'
import { FocusInner } from '../ActionButton/ActionButton'
import { KeyboardKeys } from '../../utils/keyboardNavigation'

export interface LanguageBoxProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  text: string
  selected?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void
}

export type HeaderProps = Pick<LanguageBoxProps, 'selected' | 'disabled'>

const StyledButton = styled.button<Omit<LanguageBoxProps, 'text'>>`
  ${({
    theme: {
      xyz: { color, fontWeight, spacing, borderRadius },
    },
    selected,
  }) => css`
    border: none;
    background: none;
    width: ${spacing.space6}rem;
    height: ${spacing.space6}rem;
    margin-right: ${spacing.space1}rem;
    border-radius: ${borderRadius.xs}rem;
    background-clip: padding-box;
    font-weight: ${fontWeight.semiBold};
    color: ${selected ? color.neutralWhite : color.neutralNetworkGray};
    background-color: ${selected ? color.neutralNetworkGray : color.neutralWhite};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    &:hover {
      background-color: ${selected ? color.neutralNetworkGray : color.neutralOnHoverGray};
    }

    &:active {
      background-color: ${color.neutralOnPressGray};
    }

    &:focus {
      outline: none;
      box-shadow: 0px 0px 7px 0px ${color.signalBlue};
    }

    &:disabled {
      background-color: ${color.neutralGray5};
      color: ${color.neutralPassiveGray};
      box-shadow: 0px 0px 7px 0px ${color.signalBlue};
    }
  `}
`

const Header = styled(Label)<HeaderProps>(
  ({
    theme: {
      xyz: { color },
    },
    selected,
    disabled,
  }) => css`
    cursor: pointer;
    color: ${selected ? color.neutralWhite : color.neutralNetworkGray};
    flex-shrink: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5rem;
    ${disabled &&
    `
      color: ${color.neutralPassiveGray};
    `}
  `
)

export const LanguageBox: React.FC<LanguageBoxProps> = (props) => {
  const { text, selected, disabled, id, onClick, ...rest } = props

  const onKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === KeyboardKeys.Enter) {
      e.preventDefault()
      onClick(e)
    }
  }

  return (
    <StyledButton
      {...rest}
      role="option"
      selected={selected}
      disabled={disabled}
      id={id}
      onClick={onClick}
      onKeyPress={onKeyPress}
      aria-selected={selected}
      tabIndex={0}
    >
      <FocusInner tabIndex={-1}>
        <Header size={LabelSize.Three} selected={selected} disabled={disabled}>
          {text}
        </Header>
      </FocusInner>
    </StyledButton>
  )
}

LanguageBox.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
}

LanguageBox.defaultProps = {
  selected: false,
  disabled: false,
}
