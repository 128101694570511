import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import TableContext from '../../composite-components/Table/TableContext'
import Tablelvl2Context from '../../composite-components/Table/Tablelvl2Context'
import { TableSize } from '../../composite-components/Table/Table.types'

export interface TableCellProps extends HTMLAttributes<HTMLTableCellElement> {
  className?: string
}

const thPaddingVertical: Record<TableSize, number> = {
  [TableSize.lg]: 1.375,
  [TableSize.sm]: 1.625,
}

const tdPaddingVertical: Record<TableSize, number> = {
  [TableSize.lg]: 1.5,
  [TableSize.sm]: 0.625,
}

const paddingHorizontal: Record<TableSize, number> = {
  [TableSize.lg]: 1,
  [TableSize.sm]: 0.25,
}

const StyledTh = styled.th<{ size: keyof typeof TableSize }>(
  ({ theme, size }) => css`
    position: relative;
    padding: ${thPaddingVertical[size]}rem ${paddingHorizontal[size]}rem;
    color: ${theme.xyz.color.neutralNetworkGray};
    font-weight: ${theme.xyz.fontWeight.semiBold};
    font-size: ${size === TableSize.lg ? theme.xyz.fontSize.headline.six : theme.xyz.fontSize.headline.seven}rem;
    line-height: ${size === TableSize.lg ? theme.xyz.lineHeight.headline.six : theme.xyz.lineHeight.headline.seven};
  `
)

const StyledTd = styled.td<{ size: keyof typeof TableSize }>(
  ({ theme, size }) => css`
    position: relative;
    padding: ${tdPaddingVertical[size]}rem ${paddingHorizontal[size]}rem;
    color: ${theme.xyz.color.neutralNetworkGray};
    font-size: ${size === TableSize.lg ? theme.xyz.fontSize.body.three : theme.xyz.fontSize.body.four}rem;
    line-height: ${size === TableSize.lg ? theme.xyz.lineHeight.body.three : theme.xyz.lineHeight.body.four};
  `
)

export const TableCell: React.FC<TableCellProps> = ({ ...rest }) => {
  const table = React.useContext(TableContext)
  const tablelvl2 = React.useContext(Tablelvl2Context)
  const isHeadCell = tablelvl2 && tablelvl2.variant === 'head'
  const Component = isHeadCell ? StyledTh : StyledTd
  const size = table && table.size ? table.size : TableSize.lg

  return <Component size={size} {...rest} />
}
