import styled, { css } from 'styled-components'

import Title from '../../typography/Title'
import Text from '../../typography/Text'
import { ColorScheme } from './ProductCard'

export const StyledTitle = styled(Title)`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  margin-bottom: 0.25rem;
  order: 3;
`

export const StyledSubTitle = styled(Text)`
  order: 2;
`

export const StyledBody = styled(Text)`
  order: 4;
`

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  order: 1;
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
`

export const StyledDeliveryTime = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  text-align: right;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  order: 5;
`

export const StyledBoxTitle = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const StyledWrapper = styled.div<{ scheme: keyof typeof ColorScheme; selected: boolean; disabled: boolean }>(
  ({ theme, scheme, selected, disabled }) => css`
    border: 0.1875rem solid
      ${selected
        ? theme.color.signalBlue
        : scheme === ColorScheme.white
        ? theme.color.neutralWhite
        : theme.color.neutralGray2};
    border-bottom-color: ${selected ? theme.color.signalBlue : theme.color.neutralPassiveGray};
    border-radius: 0.25rem;
    padding: 1.5rem;
    background-color: ${scheme === ColorScheme.white ? theme.color.neutralWhite : theme.color.neutralGray2};
    max-width: 21rem;
    transition: all 200ms ease-in;
    cursor: ${disabled ? 'default' : 'pointer'};
    display: flex;
    flex-direction: column;
    outline: none;

    ${!disabled &&
    !selected &&
    css`
      &:focus,
      &:hover {
        background-color: ${scheme === ColorScheme.white ? theme.color.neutralGray2 : theme.color.neutralWhite};
        border-color: ${scheme === ColorScheme.white ? theme.color.neutralGray2 : theme.color.neutralWhite};
        border-bottom-color: ${theme.color.neutralPassiveGray};
      }

      &:active {
        background-color: ${theme.color.neutralOnPressGray};
        border-color: ${theme.color.neutralOnPressGray};
        border-bottom-color: ${theme.color.neutralPassiveGray};
      }
    `}

    ${disabled &&
    css`
      ${StyledTitle}, ${StyledSubTitle}, ${StyledBody}, ${StyledDeliveryTime}, ${StyledBoxTitle} {
        color: ${theme.color.neutralPassiveGray};
      }
    `}
  `
)

export const StyledMainBox = styled.div<{ selected: boolean }>(
  ({ theme, selected }) => css`
    width: 5.5rem;
    height: 4rem;
    background-color: ${selected ? theme.color.signalBlue : theme.color.neutralPassiveGray};
    position: relative;
    transition: all 200ms ease-in;
    padding: ${theme.spacing.xxs}rem;
    font-size: 0.625rem;
    color: ${selected ? theme.color.neutralWhite : theme.color.neutralNetworkGray};
    font-weight: ${theme.fontWeight.semiBold};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:after {
      content: '';
      position: absolute;
      right: -1rem;
      top: 0;
      width: 1rem;
      height: 4rem;
      background-color: ${selected ? theme.color.signalBlue : theme.color.neutralIconGray};
      opacity: ${selected ? 0.8 : 1};
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      width: 1rem;
      height: 1rem;
      transform: translateX(-50%);
      background-color: ${theme.color.neutralGray2};
    }
  `
)

export const StyledInnerLines = styled.div(
  ({ theme }) => css`
    width: 1.5rem;
    height: 0.875rem;
    background-color: ${theme.color.neutralWhite};
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0.25rem;
      top: 0.25rem;
      width: 1rem;
      height: 0.125rem;
      background-color: ${theme.color.neutralNetworkGray};
    }

    &:after {
      content: '';
      position: absolute;
      left: 0.25rem;
      bottom: 0.25rem;
      width: 0.75rem;
      height: 0.125rem;
      background-color: ${theme.color.neutralNetworkGray};
    }
  `
)

export const StyledBoxLines = styled.div<{ selected: boolean }>(
  ({ theme, selected }) => css`
    height: 0.25rem;
    width: 2rem;
    background-color: ${selected ? theme.color.signalBlue : theme.color.neutralIconGray};
    margin-bottom: 0.5rem;
    position: relative;
    margin-right: 0.25rem;
    transition: all 200ms ease-in;
    opacity: ${selected ? 0.8 : 1};

    &:before {
      content: '';
      position: absolute;
      top: -0.5rem;
      right: 0;
      height: 0.25rem;
      width: 1.5rem;
      background-color: ${selected ? theme.color.signalBlue : theme.color.neutralIconGray};
      transition: all 200ms ease-in;
    }

    &:after {
      content: '';
      position: absolute;
      top: -1rem;
      right: 0;
      height: 0.25rem;
      width: 1rem;
      background-color: ${selected ? theme.color.signalBlue : theme.color.neutralIconGray};
      transition: all 200ms ease-in;
    }
  `
)

export const StyledIconContainer = styled.div<{ disabled: boolean }>(
  ({ theme, disabled }) => css`
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    ${disabled &&
    css`
      ${StyledMainBox} {
        background-color: ${theme.color.neutralGray5};

        &:after {
          background-color: ${theme.color.neutralGray5};
          opacity: 0.8;
        }
      }

      ${StyledInnerLines}:before, ${StyledInnerLines}:after {
        background-color: ${theme.color.neutralPassiveGray};
      }

      ${StyledBoxLines}, ${StyledBoxLines}:after, ${StyledBoxLines}:before {
        background-color: ${theme.color.neutralGray5};
        opacity: 0.8;
      }
    `}
  `
)
