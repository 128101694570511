import { XyzTheme } from '@postidigital/posti-theme'
import styled, { css } from 'styled-components'
import { Headline } from '../../design-tokens/typography/Headline'
import { IconButton } from '../IconButton'
import { ContainerProps, NotificationType } from './Notification.types'
import { Body } from '../../design-tokens/typography/Body'

export const colors = {
  [NotificationType.alert]: {
    backgroundColor: XyzTheme.color.backgroundAlertRed,
    textColor: XyzTheme.color.brandPurple,
    closeButtonColor: XyzTheme.color.neutralBlack,
  },
  [NotificationType.informational]: {
    backgroundColor: XyzTheme.color.backgroundCTABlue,
    textColor: XyzTheme.color.brandNavy,
    closeButtonColor: XyzTheme.color.brandNavy,
  },
  [NotificationType.success]: {
    backgroundColor: XyzTheme.color.backgroundNotificationGreen,
    textColor: XyzTheme.color.brandPetrol,
    closeButtonColor: XyzTheme.color.brandPetrol,
  },
  [NotificationType.warning]: {
    backgroundColor: XyzTheme.color.backgroundWarningYellow,
    textColor: XyzTheme.color.neutralNetworkGray,
    closeButtonColor: XyzTheme.color.neutralNetworkGray,
  },
  [NotificationType.text]: {
    backgroundColor: XyzTheme.color.neutralOnHoverGray,
    textColor: XyzTheme.color.neutralNetworkGray,
    closeButtonColor: XyzTheme.color.neutralNetworkGray,
  },
}

export const Container = styled.div<ContainerProps>(
  ({
    type,
    theme: {
      xyz: { borderRadius },
    },
    width,
  }) => css`
    display: flex;
    position: relative;
    background-color: ${colors[type].backgroundColor};
    border-radius: ${borderRadius.xs}rem;
    ${!!width ? `width: ${width};` : ''}

    ${Body}, ${Headline} {
      color: ${colors[type].textColor};
    }
  `
)

export const ContentContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    margin: ${theme.xyz.spacing.space3}rem ${theme.xyz.spacing.space4}rem;
    align-items: center;
  `
)

export const IndicatorIconContainer = styled.div<{
  centerContent: boolean
  dismissible: boolean
}>(
  ({ theme, centerContent, dismissible }) => css`
    flex: 0 0 auto;
    margin-right: ${theme.xyz.spacing.space4}rem;
    ${!centerContent
      ? `
      margin-bottom: auto;
      margin-top: ${dismissible ? `${theme.xyz.spacing.space1}rem` : '0'};
    `
      : ''}
    line-height: 1;
  `
)

export const ButtonText = styled(Headline)`
  flex: 1 1 auto;
`

export const StyledCloseButton = styled(IconButton)<{ centerContent: boolean }>`
  flex: 0 0 auto;
  padding: 0;
  margin-left: ${({ theme }) => theme.xyz.spacing.space4}rem;
  margin-bottom: ${({ centerContent }) => (centerContent ? '0' : 'auto')};

  &:hover,
  &:focus {
    background: transparent;
  }
`
