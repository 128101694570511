import { ThemeInterface, XyzThemeInterface } from '@postidigital/posti-theme'

export type ThemeColor = keyof ThemeInterface['color']
export type TitleSize = keyof ThemeInterface['fontSize']['title']
export type TextSize = keyof ThemeInterface['fontSize']['text']

export type XyzThemeColor = keyof XyzThemeInterface['color']

/**
 * In e.g. Gatsby and other SSR rendering frameworks any code that expects window to be defined will break.
 * Use this function to access the window object.
 *
 * You can also optionally cast the Window type in order to extend it to a specific purpose or use a
 * fallback value to provide some window related functionality to the SSR context
 */
export const getWindow = <T = Window>(fallback: T = {} as T): T => {
  if (typeof window !== 'undefined') {
    return window as unknown as T
  }
  return fallback
}

export const randomId = (): string => Math.random().toString(36).substring(7)
