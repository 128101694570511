import React, { useRef, HTMLAttributes, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { nextItem, previousItem, ownerDocument, moveFocus, KeyboardKeys } from '../../utils/keyboardNavigation'
import useClickOutside from '../../utils/useClickOutside'

export enum MenuOverflowDirection {
  left = 'left',
  right = 'right',
}

export interface MenuProps extends HTMLAttributes<HTMLUListElement> {
  isOpen: boolean
  onClose: () => void
  transitionDuration?: number
  /**
   * Top offset for menu popup in CSS units px, em, rem, percents (example: 1px; 1rem; 10%)
   */
  offset?: string | number
  /*
   * List ids for elements that are excluded from triggering onClose event. The array is passed to the OutsideClick component.
   */
  exclusions?: string[]
  zIndex?: number
  /**
   * Allows the menu to be wider than its parent container
   */
  overflowMenuContainer?: boolean
  /**
   * If `overflowMenuContainer` is set to true, you can choose in which direction the menu container should overflow to: left or right.
   * It is set to overflow to the right by default
   */
  overflowMenuContainerDirection?: keyof typeof MenuOverflowDirection
  /**
   * Enable dropdown styles if set to true
   */
  isInDropdown?: boolean
  isOpenUpward?: boolean
  'aria-label': string
  maxMenuHeight?: string
}

enum MenuState {
  Opened,
  Closed,
  InTransition,
}

interface MenuListProps
  extends Pick<
    MenuProps,
    | 'transitionDuration'
    | 'offset'
    | 'zIndex'
    | 'overflowMenuContainer'
    | 'overflowMenuContainerDirection'
    | 'isInDropdown'
  > {
  menuState: MenuState
  isOpenUpward?: boolean
  maxMenuHeight?: string
}

const MenuList = styled.ul<MenuListProps>`
  ${({
    theme: {
      xyz: { color, fontWeight, spacing, borderRadius },
    },
    menuState,
    transitionDuration,
    offset,
    zIndex,
    overflowMenuContainerDirection,
    overflowMenuContainer,
    isInDropdown,
    isOpenUpward,
    maxMenuHeight,
  }) => css`
    position: absolute;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: ${zIndex || 1};
    transform-origin: 0 0;
    top: calc(100% + ${offset}rem);
    box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);
    font-weight: ${fontWeight.medium};
    color: ${color.neutralNetworkGray};
    background-color: ${color.neutralWhite};
    transition: all ${transitionDuration}ms ease-in, filter 0;

    &:focus {
      outline: none;
      filter: drop-shadow(0px 0px 3px ${color.signalBlue});
    }

    ${overflowMenuContainerDirection === MenuOverflowDirection.left &&
    css`
      right: 0;
    `}

    ${overflowMenuContainer &&
    css`
      width: auto;
      min-width: 100%;
    `}

  ${isInDropdown &&
    css`
      margin-top: ${spacing.space2}rem;
      max-height: ${maxMenuHeight ? maxMenuHeight : '60vh'};
      overflow-y: auto;
      border-radius: ${borderRadius.sm};
    `}

  ${isInDropdown &&
    isOpenUpward &&
    css`
      bottom: 100%;
      top: auto;
      left: 0;
      margin: 0 0 ${spacing.space2}rem;
    `}

  ${(menuState === MenuState.Closed || menuState === null) &&
    css`
      display: none;
      transform: scaleY(0);
    `}

  ${menuState === MenuState.InTransition &&
    css`
      transform: scaleY(0);
    `}

  ${menuState === MenuState.Opened &&
    css`
      transform: scaleY(1);
    `}
  `}
`

export const Menu = React.forwardRef<HTMLUListElement, MenuProps>((props, ref) => {
  const { children, isOpen, onClose, transitionDuration, isOpenUpward, exclusions, ...rest } = props
  const localRef = useRef<HTMLUListElement>(null)
  const optionsRef = (ref as React.RefObject<HTMLUListElement>) || localRef

  const [menuState, setMenuState] = React.useState(null)

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const key = e.key
    const list = optionsRef.current
    const currentFocus = ownerDocument(list).activeElement
    if (key === KeyboardKeys.ArrowDown || key === KeyboardKeys.Home) {
      e.preventDefault()
      moveFocus(list, currentFocus, true, nextItem)
    } else if (key === KeyboardKeys.ArrowUp || key === KeyboardKeys.End) {
      e.preventDefault()
      moveFocus(list, currentFocus, true, previousItem)
    } else if (key === KeyboardKeys.Tab || key === KeyboardKeys.Escape) {
      e.preventDefault()
      onClose()
    }
  }

  const handleOutsideClick = () => {
    onClose()
  }

  useEffect(() => {
    const newMenuState = isOpen ? MenuState.Opened : MenuState.InTransition

    if (menuState) {
      setMenuState(MenuState.InTransition)

      setTimeout(() => {
        setMenuState(newMenuState)
      }, transitionDuration)
    } else {
      setMenuState(newMenuState)
    }
  }, [isOpen, menuState, transitionDuration])

  useClickOutside(optionsRef, handleOutsideClick, isOpen, exclusions)

  return (
    <MenuList
      onKeyDown={handleKeyDown}
      role="listbox"
      ref={optionsRef}
      tabIndex={isOpen ? 0 : -1}
      menuState={menuState}
      transitionDuration={transitionDuration}
      aria-hidden={!isOpen}
      isOpenUpward={isOpenUpward}
      {...rest}
    >
      {children}
    </MenuList>
  )
})

Menu.displayName = 'Menu'

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  transitionDuration: PropTypes.number,
  offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  exclusions: PropTypes.arrayOf(PropTypes.string),
  zIndex: PropTypes.number,
  overflowMenuContainer: PropTypes.bool,
  overflowMenuContainerDirection: PropTypes.oneOf(Object.values(MenuOverflowDirection)),
  isInDropdown: PropTypes.bool,
  'aria-label': PropTypes.string.isRequired,
}

Menu.defaultProps = {
  transitionDuration: 200,
  offset: 0,
  exclusions: [],
  isOpen: false,
  zIndex: 1,
  overflowMenuContainer: false,
  overflowMenuContainerDirection: MenuOverflowDirection.right,
  isInDropdown: false,
  isOpenUpward: false,
}
