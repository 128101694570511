(function webpackUniversalModuleDefinition(root, factory) {
	//CommonJS2 Comment
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("styled-components"), require("react"), require("prop-types"), require("@postidigital/posti-theme"), require("react-dom"));
	//AMD Comment
	else if(typeof define === 'function' && define.amd)
		define("PostiDigital", ["styled", "React", "PropTypes", "PostiTheme", "ReactDOM"], factory);
	//CommonJS Comment
	else if(typeof exports === 'object')
		exports["PostiDigital"] = factory(require("styled-components"), require("react"), require("prop-types"), require("@postidigital/posti-theme"), require("react-dom"));
	//Root Comment
	else
		root["PostiDigital"] = root["PostiDigital"] || {}, root["PostiDigital"]["components"] = factory(root["styled"], root["React"], root["PropTypes"], root["PostiTheme"], root["ReactDOM"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__54602__, __WEBPACK_EXTERNAL_MODULE__84639__, __WEBPACK_EXTERNAL_MODULE__23343__, __WEBPACK_EXTERNAL_MODULE__78570__, __WEBPACK_EXTERNAL_MODULE__31346__) {
return 