import React, { CSSProperties, useState, useRef, HTMLAttributes, useEffect, useCallback, useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import { useSpring, animated as a, config } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import FocusLock from 'react-focus-lock'
import PropTypes from 'prop-types'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import debounce from 'lodash/debounce'
import {
  BottomSheetWrapper,
  BottomSheetContent,
  BackDrop,
  HeadlineAligner,
  StyledCloseButton,
} from './BottomSheet.styles'
import { ThemeColor } from '../../utils/helpers'
import OutsideClick from '../../basic-components/OutsideClick'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import { Headline, HeadlineSize } from '../../design-tokens/typography'
import { CloseIcon } from '../../design-tokens/icons'

enum Orientation {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
}

export interface BottomSheetProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * The background color of the Bottomsheet. Defaults to brandPink.
   * Using a preset will override this
   */
  backgroundColor?: ThemeColor | string
  /**
   * Define the CSS properties of backdrop
   */
  backDropStyles?: CSSProperties
  /**
   * Function to set the outer state to match inner state
   */
  onClose?: () => void
  /**
   * Customize the scrollbar color
   */
  scrollbarColor?: ThemeColor | string
  /**
   * Whether BottomSheet is draggable via mouse/touch.
   */
  isDraggable?: boolean
  /**
   * Customize the handle bar color
   */
  handlebarColor?: ThemeColor | string
  /**
   * Pre-configured default paddings are: top: 2rem, bottom: 1rem, left: 1.5rem, right: 1rem
   */
  useDefaultPadding?: boolean
  /**
   * Whether the close button is visible or not
   */
  showCloseButton?: boolean
  /**
   * If CloseButton is shown, this needs to be provided
   */
  closeButtonAriaLabel?: string
  /**
   * Customize close button's icon color
   */
  closeButtonIconColor?: string
  /**
   * Can be used to set title to the BottomSheet
   */
  bottomSheetTitle?: string | JSX.Element | React.ReactNode
  /**
   * Whether the BottomSheet can have full height of the screen or not.
   * fullHeight will not force the BottomSheet to utilize the full height of screen, but enables it only if the content is long and will overflow
   */
  fullHeight?: boolean
  /**
   * Defines the BottomSheet content height, 'auto' by default.
   */
  contentHeight?: string
}

// Time in milliseconds that it takes for the component to animate itself open
const ANIMATION_TIME = 300
// Determines the ammount of pixels that user has to drag in order to close the bottomsheet
const THRESHOLD = 100
// The amount to be reduced from the offsetHeight so BottomSheet will not fill to whole screen unless fullHeight is true or the screen is in landscape view
const DEFAULT_PIXEL_REDUCTION_PORTRAIT = 24

const BottomSheet: React.FC<BottomSheetProps> = ({
  backgroundColor,
  onClose,
  children,
  scrollbarColor,
  isDraggable,
  handlebarColor,
  useDefaultPadding,
  showCloseButton,
  closeButtonAriaLabel,
  closeButtonIconColor,
  bottomSheetTitle,
  fullHeight,
  contentHeight,
  backDropStyles,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(true)
  const [animationComplete, setAnimationComplete] = useState(false)
  const [focusBeforeBottomSheet, setFocusBeforeBottomSheet] = useState<HTMLElement | null>(null)
  const [maxHeight, setMaxHeight] = useState(0)
  const [minWidth, setMinwidth] = useState(0)

  const bottomSheetRef = useRef<HTMLDivElement>(null)
  // Determines the scrollheight if the bodycontent overflows and becomes scrollable
  const bodyRef = useRef<HTMLDivElement>(null)
  const backdropRef = useRef<HTMLDivElement>(null)
  const headlineRef = useRef<HTMLDivElement>()

  const close = useCallback(() => {
    setOpen(false)
    if (onClose) {
      setTimeout(() => {
        onClose()
      }, ANIMATION_TIME)
    }
  }, [onClose])

  // Initializes BottomSheet with body scroll lock and focus lock when the component is opened. Timeout is used to activate the focus lock only after the animation has been completed.
  // This hook also sets previously selected element where the focus will be returned when BottomSheet is closed, and sets keydown listener so the BottomSheet can be closed by ESC keypress.
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === KeyboardKeys.Escape) {
        close()
      }
    }

    const animationTimeout = setTimeout(() => {
      setAnimationComplete(true)
    }, ANIMATION_TIME)

    if (open) {
      setFocusBeforeBottomSheet((document.activeElement as HTMLElement) || null)
      window.document.addEventListener('keydown', onKeyDown)
      // Prevent scroll on touch screens while dragging
      disableBodyScroll(bodyRef.current)
    } else {
      enableBodyScroll(bodyRef.current)
    }
    return () => {
      clearAllBodyScrollLocks()
      window.document.removeEventListener('keydown', onKeyDown)
      clearTimeout(animationTimeout)
    }
  }, [open, onClose, close])

  const onBackgroundClick = (e: Event) => {
    e.stopPropagation()
    close()
  }

  const displayBottomSheetAnimation = useSpring({
    from: { transform: `translate3d(0,${open ? 100 : 0}%,0)` },
    to: { transform: `translate3d(0,${open ? 0 : 100}%,0)` },
    config: {
      ...config.slow,
      duration: ANIMATION_TIME,
    },
  })

  const [props, set] = useSpring(() => ({
    y: 0,
  }))

  const bind = useDrag(({ last, down, movement: [x, y], cancel }) => {
    if (y < 0 || bodyRef.current.scrollTop > 0) {
      cancel()
    } else if (last && y > THRESHOLD) {
      close()
    }
    set({
      y: down ? y : 0,
      immediate: down,
      config: {
        tension: 500,
        friction: 50,
      },
    })
  })

  const onFocusLockDeactivation = useCallback(() => {
    if (focusBeforeBottomSheet) {
      // as per https://github.com/theKashey/react-focus-lock#unmounting-and-focus-management
      setTimeout(() => focusBeforeBottomSheet.focus(), 0)
    }
  }, [focusBeforeBottomSheet])

  // Sets event listeners for screen size or orientation changes. Listeners call the updateSize function which sets proper boundaries for BottomSheet's width and height based on the changed screen size.
  useLayoutEffect(() => {
    const getOrientation = () => {
      const screenOrientation = getScreenOrientation() || []
      const isLandscape = screenOrientation.includes('landscape')
      const mediaIsLandscape = window.matchMedia && window.matchMedia('(orientation: landscape)').matches
      return isLandscape || mediaIsLandscape ? Orientation.LANDSCAPE : Orientation.PORTRAIT
    }

    const updateSize = debounce(function () {
      // Padding values set for BottomSheetWrapper. In default use case these values won't change, but they can be customized to change on different screen sizes.
      const paddingTop = parseInt(window.getComputedStyle(bottomSheetRef.current).getPropertyValue('padding-top'), 10)
      const paddingBottom = parseInt(
        window.getComputedStyle(bottomSheetRef.current).getPropertyValue('padding-bottom'),
        10
      )
      // Header height can change on orientation change, for instance if the Header text fits on one line of the screen in landscape mode, but requires two lines in portrait mode
      const headerHeight = !!bottomSheetTitle ? headlineRef.current?.offsetHeight : 0
      // Height of the backdrop
      const backdropHeight = backdropRef.current?.offsetHeight
      const orientation = getOrientation()

      setMaxHeight(
        backdropHeight -
          paddingTop -
          paddingBottom -
          headerHeight -
          (orientation === Orientation.LANDSCAPE || !!fullHeight ? 0 : DEFAULT_PIXEL_REDUCTION_PORTRAIT)
      )
      setMinwidth(backdropRef.current?.offsetWidth)
    }, 10)

    if (open) {
      window.addEventListener('resize', updateSize)
      window.addEventListener('orientationchange', updateSize)
      updateSize()
    }

    return () => {
      window.removeEventListener('resize', updateSize)
      window.removeEventListener('orientationchange', updateSize)
    }
  }, [bottomSheetTitle, fullHeight, open])

  const getScreenOrientation = () => {
    const screen = window.screen || ({} as any)
    return (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation
  }

  return (
    <>
      {ReactDOM.createPortal(
        <BackDrop open={open} ref={backdropRef} style={backDropStyles}>
          <OutsideClick onOutsideClick={(e) => onBackgroundClick(e)} style={{ maxHeight: '96%' }}>
            <a.div {...(isDraggable ? bind() : () => {})} style={{ ...props, ...displayBottomSheetAnimation }}>
              <FocusLock disabled={!animationComplete} onDeactivation={onFocusLockDeactivation}>
                <BottomSheetWrapper
                  aria-live="assertive"
                  aria-modal="true"
                  role="dialog"
                  aria-labelledby={bottomSheetTitle ? 'bottomsheet-title' : undefined}
                  backgroundColor={backgroundColor}
                  useDefaultPadding={useDefaultPadding}
                  handlebarColor={handlebarColor}
                  ref={bottomSheetRef}
                  minWidth={minWidth}
                  {...rest}
                >
                  {showCloseButton && (
                    <StyledCloseButton
                      aria-label={closeButtonAriaLabel}
                      onClick={close}
                      iconColor={closeButtonIconColor}
                      icon={CloseIcon}
                    />
                  )}
                  {bottomSheetTitle && (
                    <HeadlineAligner useDefaultPadding={useDefaultPadding} ref={headlineRef}>
                      <Headline as="h1" id="bottomsheet-title" size={HeadlineSize.Four}>
                        {bottomSheetTitle}
                      </Headline>
                    </HeadlineAligner>
                  )}
                  <BottomSheetContent
                    ref={bodyRef}
                    size={maxHeight}
                    scrollbarColor={scrollbarColor}
                    scrollbarBackgroundColor={backgroundColor}
                    useDefaultPadding={useDefaultPadding}
                    contentHeight={contentHeight}
                  >
                    {children}
                  </BottomSheetContent>
                </BottomSheetWrapper>
              </FocusLock>
            </a.div>
          </OutsideClick>
        </BackDrop>,
        document.body
      )}
    </>
  )
}

BottomSheet.defaultProps = {
  isDraggable: true,
  useDefaultPadding: true,
  showCloseButton: true,
  fullHeight: false,
}

BottomSheet.propTypes = {
  backgroundColor: PropTypes.string,
  onClose: PropTypes.func,
  scrollbarColor: PropTypes.string,
  isDraggable: PropTypes.bool,
  handlebarColor: PropTypes.string,
  useDefaultPadding: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  closeButtonAriaLabel: PropTypes.string,
  closeButtonIconColor: PropTypes.string,
  bottomSheetTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fullHeight: PropTypes.bool,
  backDropStyles: PropTypes.object,
}

export default BottomSheet
