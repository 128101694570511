import React, { useEffect } from 'react'
import PropTypes, { Validator } from 'prop-types'
import {
  useTable,
  useRowSelect,
  TableInstance,
  UseRowSelectInstanceProps,
  TableState as DefaultTableState,
  UseRowSelectState,
  useSortBy,
  HeaderGroup,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  UseRowSelectRowProps,
  UseSortByState,
  SortingRule,
  Column,
} from 'react-table'
import styled, { css } from 'styled-components'
import { ArrowLeftIcon } from '../../design-tokens/icons'
import { Checkbox } from '../../basic-components/Checkbox/Checkbox'
import { TableBody } from '../../basic-components/TableBody/TableBody'
import { TableCell } from '../../basic-components/TableCell/TableCell'
import { TableHead } from '../../basic-components/TableHead/TableHead'
import { TableRow } from '../../basic-components/TableRow/TableRow'
import { Pagination } from './Pagination'
import { Table, TableProps, TableSize } from '../Table'
import { XyzTheme } from '@postidigital/posti-theme'

type Data = Record<string, unknown>[]

export interface DataTableProps extends TableProps {
  columns: Column[]
  data: Data
  rowsPerPageText: string
  pageComparisonText: string
  pageText: string
  goPreviousPageAriaLabel: string
  goNextPageAriaLabel: string
  onRowSelect?: (ids: string[], data: Data[]) => void
  onPageChanged?: (pageState: { pageIndex: number; pageSize: number }) => void
  onSortChanged?: (sortState: SortingRule<Data>[]) => void
  manualSortBy?: boolean
  manualPagination?: boolean
  pageCount?: number
  withRowSelection?: boolean
  defaultPageSize?: number
  pageSizeOptions?: number[]
}

type TableState = DefaultTableState<Data> & UsePaginationState<Data> & UseRowSelectState<Data> & UseSortByState<Data>

type TableData = TableInstance<Data> &
  UseRowSelectInstanceProps<Data> &
  UsePaginationInstanceProps<Data> & { state: TableState }

const StyledCheckbox = styled(Checkbox)`
  margin-right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

const StyledArrowDownIcon = styled(ArrowLeftIcon)<{ isVisible: boolean; isUp: boolean }>`
  ${({ isVisible, isUp }) => css`
    display: inline-block;
    visibility: ${isVisible ? 'visible' : 'hidden'};
    margin-left: 1rem;
    transform: ${isUp ? 'rotate(90deg)' : 'rotate(-90deg)'};
  `}
`

const Header = ({ getToggleAllRowsSelectedProps }: UseRowSelectInstanceProps<Data>) => {
  const { title, ...props } = getToggleAllRowsSelectedProps()
  return (
    <StyledCheckbox
      {...props}
      onChange={props.onChange as any}
      value={title || 'Check all checkboxes'}
      aria-label={title}
    />
  )
}

const Cell = ({ row }: { row: UseRowSelectRowProps<Data> }) => {
  const { title, ...props } = row.getToggleRowSelectedProps()
  return <StyledCheckbox {...props} onChange={props.onChange as any} value="Toggle row checkbox" aria-label={title} />
}

const checkBoxColumn = {
  id: 'selection',
  Header: Header,
  Cell: Cell,
}

export const DataTable: React.FC<DataTableProps> = ({
  columns,
  data,
  onRowSelect,
  withRowSelection,
  defaultPageSize,
  pageSizeOptions,
  rowsPerPageText,
  pageComparisonText,
  pageText,
  goPreviousPageAriaLabel,
  goNextPageAriaLabel,
  onPageChanged,
  onSortChanged,
  manualSortBy,
  manualPagination,
  pageCount: externalPageCount,
  ...rest
}) => {
  const hasCheckboxesEnabled = withRowSelection && rest.size !== TableSize.sm
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    pageOptions,
    state: { pageIndex, pageSize, selectedRowIds, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: defaultPageSize } as Partial<TableState>,
      manualPagination: manualPagination,
      pageCount: manualPagination ? externalPageCount : undefined,
      autoResetPage: !manualPagination,
      manualSortBy: manualSortBy,
      autoResetSortBy: !manualSortBy,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (hasCheckboxesEnabled) {
        hooks.visibleColumns.push((columns) => [checkBoxColumn, ...columns])
      }
    }
  ) as TableData

  const paginationProps = {
    totalPages: pageOptions.length,
    pageComparisonText,
    pageText,
    rowsPerPageText,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    pageSize,
    setPageSize,
    pageIndex,
    pageSizeOptions,
    goPreviousPageAriaLabel,
    goNextPageAriaLabel,
  }

  useEffect(() => {
    if (hasCheckboxesEnabled && onRowSelect) {
      onRowSelect(
        Object.keys(selectedRowIds),
        selectedFlatRows.map((r) => r.original)
      )
    }
  }, [hasCheckboxesEnabled, selectedRowIds, selectedFlatRows, onRowSelect])

  useEffect(() => {
    onPageChanged?.({ pageIndex, pageSize })
  }, [pageIndex, pageSize, onPageChanged])
  useEffect(() => {
    onSortChanged?.(sortBy)
  }, [sortBy, onSortChanged])

  return (
    <>
      <Table {...getTableProps()} {...rest}>
        <TableHead>
          {headerGroups.map((headerGroup, index) => (
            <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup<Data>, index) => (
                <TableCell key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <StyledArrowDownIcon
                    isVisible={column.isSorted}
                    aria-hidden={!column.isSorted}
                    isUp={!column.isSortedDesc}
                    width={`${XyzTheme.iconSize.xs}rem`}
                    height={`${XyzTheme.iconSize.xs}rem`}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <TableRow
                {...row.getRowProps()}
                key={index}
                selected={selectedFlatRows.some((selectedRow) => selectedRow.id === row.id)}
              >
                {row.cells.map((cell, index) => (
                  <TableCell key={index} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Pagination {...paginationProps} />
    </>
  )
}

DataTable.displayName = 'DataTable'
Header.displayName = 'Header'
Cell.displayName = 'Cell'

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      sortType: PropTypes.func,
    })
  ).isRequired as Validator<Column[]>,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  onRowSelect: PropTypes.func,
  withRowSelection: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  rowsPerPageText: PropTypes.string.isRequired,
  pageComparisonText: PropTypes.string.isRequired,
  pageText: PropTypes.string.isRequired,
  goPreviousPageAriaLabel: PropTypes.string.isRequired,
  goNextPageAriaLabel: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(TableSize)),
  stripes: PropTypes.bool,
  manualPagination: PropTypes.bool,
  onPageChanged: PropTypes.func,
  manualSortBy: PropTypes.bool,
  onSortChanged: PropTypes.func,
  pageCount: PropTypes.number,
}

DataTable.defaultProps = {
  withRowSelection: true,
  defaultPageSize: 10,
  pageSizeOptions: [10, 50, 100],
  size: TableSize.lg,
}
