import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { XyzTheme } from '@postidigital/posti-theme'
import PropTypes, { Validator } from 'prop-types'

export enum BulletSize {
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

export interface BulletNavigationProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Total number of bullets
   */
  length: number
  /**
   * Index of currently active bullet
   */
  activeIndex: number
  /**
   * Radius of a bullet (sizes from xxs to xxl)
   */
  bulletSize?: keyof typeof BulletSize | undefined
  /**
   * Color in hex
   */
  bulletColor?: string
}

const BulletSizes: { [key in BulletSize]: number } = {
  [BulletSize.xxs]: 0.375,
  [BulletSize.xs]: 0.5,
  [BulletSize.sm]: 0.625,
  [BulletSize.md]: 0.75,
  [BulletSize.lg]: 0.875,
  [BulletSize.xl]: 1,
  [BulletSize.xxl]: 1.125,
}

const NavigationContainer = styled.div`
  display: flex;
`

const BulletItem = styled.div<{
  active: boolean
  bulletColor: string
  bulletSize: string
}>(
  ({ active, bulletColor, bulletSize, theme }) => css`
    margin: ${theme.xyz.spacing.space2}rem;
    width: ${BulletSizes[bulletSize]}rem;
    height: ${BulletSizes[bulletSize]}rem;
    background-color: ${theme.xyz.color[bulletColor] || bulletColor};
    opacity: ${active ? '1' : '0.4'};
    border-radius: 50%;
  `
)

export const BulletNavigation = React.forwardRef<HTMLDivElement, BulletNavigationProps>((props, ref) => {
  const { length, activeIndex, bulletColor, bulletSize, ...rest } = props
  const bullets = [...Array(length)]
    .fill('')
    .map((_, index) => (
      <BulletItem active={index == activeIndex} bulletColor={bulletColor} bulletSize={bulletSize} key={index} />
    ))
  return (
    <NavigationContainer {...rest} ref={ref}>
      {bullets}
    </NavigationContainer>
  )
})

BulletNavigation.displayName = 'BulletNavigation'

BulletNavigation.defaultProps = {
  bulletSize: BulletSize.sm,
  bulletColor: XyzTheme.color.neutralBlack,
}

BulletNavigation.propTypes = {
  length: PropTypes.number,
  activeIndex: PropTypes.number,
  bulletSize: PropTypes.oneOf(Object.keys(BulletSize)) as Validator<BulletSize>,
  bulletColor: PropTypes.string,
}
