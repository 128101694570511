import PropTypes, { Validator } from 'prop-types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { NavigationItemProps, NavigationItemType } from '../TopBar/TopBar.types'
import React from 'react'

export enum CloseButtonAria {
  fi = 'fi',
  sv = 'sv',
  en = 'en',
}

export const CloseButtonAriasLabels: { [key in CloseButtonAria]: string } = {
  [CloseButtonAria.fi]: 'Sulje',
  [CloseButtonAria.sv]: 'Stäng',
  [CloseButtonAria.en]: 'Close',
}

export interface MobileMenuProps {
  showMobileMenu?: boolean
  zIndex?: number
  titleText?: string
  navigationState: {
    /** Defined 1 variable for storing MobileMenu items, so there would be a relationship with the menu item and its sub menu items */
    topLevelMenuItems?: MobileMenuOption[]
    currentPage?: MobileMenuOption
    rootPage?: MobileMenuOption
    isBusiness?: boolean
    rootMenuItems?: NavigationItemProps[]
    currentPageTopbar?: NavigationItemProps
  }
  /** Variable for storing default MobileMenu items which are constantly available */
  rootLevelItems?: MobileMenuOption[]
  /** CloseButton component */
  closeButton: React.ReactNode
  /** Closes the MobileMenu component when user clicks outside of the menu area */
  onOutsideClick?: () => void
  onMenuItemClick?: (value: string) => void
  servicePointsItem?: NavigationItemProps

  /** Label for the Return button which will appear next to the chevron */
  returnButtonLabel?: string
  /** LanguageSelection component*/
  languageSelection: React.ReactNode
}

export type MobileMenuOption = {
  id: string
  label: string
  value: string
  icon?: React.ComponentType<DefaultIconProps>
  subtitle?: string
  redirectsToSubMenu?: boolean
  chevronAriaLabel?: string
  children?: MobileMenuOption[]
}

export const MobileMenuOptionType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  redirectsToSubMenu: PropTypes.bool,
  chevronAriaLabel: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.any),
  onMenuItemClick: PropTypes.func,
}) as Validator<MobileMenuOption>

export const MobileMenuPropsType = PropTypes.shape({
  currentPage: MobileMenuOptionType,
  currentPageTopbar: NavigationItemType,
  rootPage: MobileMenuOptionType,
  titleText: PropTypes.string,
  onOutsideClick: PropTypes.func,
  showMobileMenu: PropTypes.bool,
  zIndex: PropTypes.number,
  topLevelMenuItems: PropTypes.arrayOf(MobileMenuOptionType),
  rootLevelItems: PropTypes.arrayOf(MobileMenuOptionType),
  servicePointsItem: NavigationItemType,
  closeButton: PropTypes.node.isRequired,
  languageSelection: PropTypes.node,
})
