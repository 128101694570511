import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import { Headline, HeadlineSize } from '../../design-tokens/typography'
import styled, { css } from 'styled-components'
import { Toolbar } from '../../basic-components/Toolbar'
import { ToolButton } from '../../basic-components/ToolButton'
import { ChevronLeftIcon, ChevronRightIcon } from '../../design-tokens/icons'

interface PaginationProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Current amount of rows that can be displayed in one page
   */
  pageSize: number
  /**
   * Change current amount of rows that can be displayed in one page
   */
  setPageSize: (pageSize: number) => void
  /**
   * Number of rows options for the user to select
   */
  pageSizeOptions?: number[]
  rowsPerPageText: string
  pageText: string
  pageComparisonText: string
  goPreviousPageAriaLabel: string
  goNextPageAriaLabel: string
  canPreviousPage: boolean
  canNextPage: boolean
  /**
   * Current page number
   */
  pageIndex: number
  totalPages: number
  previousPage: () => void
  nextPage: () => void
}

const Container = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.xyz.spacing.space4}rem;
    display: flex;
    justify-content: space-between;
  `}
`

const StyledToolButton = styled(ToolButton)`
  ${({ theme }) => css`
    padding: 0;
    max-width: ${theme.xyz.spacing.space6}rem;
    width: ${theme.xyz.spacing.space6}rem;
    height: ${theme.xyz.spacing.space6}rem;

    & > span {
      padding: 0;
    }
  `}
`

const Section = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    @media screen and (max-width: ${theme.xyz.breakpoint.md}) {
      flex-direction: column;
    }
  `}
`

const StyledToolbar = styled(Toolbar)`
  ${({ theme }) => css`
    margin-left: ${theme.xyz.spacing.space3}rem;

    @media screen and (max-width: ${theme.xyz.breakpoint.md}) {
      margin-left: 0;
      margin-top: ${theme.xyz.spacing.space2}rem;
    }
  `}
`

export const Pagination: React.FC<PaginationProps> = ({
  pageSize,
  setPageSize,
  rowsPerPageText,
  pageSizeOptions,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageText,
  totalPages,
  previousPage,
  nextPage,
  pageComparisonText,
  goPreviousPageAriaLabel,
  goNextPageAriaLabel,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <Section>
        <Headline size={HeadlineSize.Eight} as="span">
          {rowsPerPageText}
        </Headline>
        <StyledToolbar>
          {pageSizeOptions.map((opt) => (
            <StyledToolButton key={opt} text={`${opt}`} onClick={() => setPageSize(opt)} selected={pageSize === opt} />
          ))}
        </StyledToolbar>
      </Section>
      <Section>
        <Headline size={HeadlineSize.Eight} as="span">
          {pageText} {pageIndex + 1} {pageComparisonText} {totalPages}
        </Headline>
        <StyledToolbar>
          <StyledToolButton
            onClick={previousPage}
            disabled={!canPreviousPage}
            icon={ChevronLeftIcon}
            aria-label={goPreviousPageAriaLabel}
          />
          <StyledToolButton
            onClick={nextPage}
            disabled={!canNextPage}
            icon={ChevronRightIcon}
            aria-label={goNextPageAriaLabel}
          />
        </StyledToolbar>
      </Section>
    </Container>
  )
}

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  rowsPerPageText: PropTypes.string.isRequired,
  pageText: PropTypes.string.isRequired,
  pageComparisonText: PropTypes.string.isRequired,
  goPreviousPageAriaLabel: PropTypes.string.isRequired,
  goNextPageAriaLabel: PropTypes.string.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
}

Pagination.defaultProps = {
  pageSizeOptions: [10, 50, 100],
}
