import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import { AlertIndicatorIcon, CheckmarkIndicatorIcon } from '../../design-tokens/icons'
import { useTheme } from '../../utils/useTheme'
import Text from '../../typography/Text'

export const MessageContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.xxs}rem;
  `
)

export const MessageLabel = styled(Text)(
  ({ theme }) => css`
    display: flex;
    font-weight: ${theme.fontWeight.normal};
    margin-left: ${theme.spacing.xxs}rem;
  `
)

interface Props extends HTMLAttributes<HTMLDivElement> {
  isInvalid: boolean
  text?: string
}

/**
 * @deprecated Use UiMessage instead
 */
export const FormHelperText: React.FC<Props> = ({ text, isInvalid, ...rest }) => {
  const {
    xyz: { color, iconSize },
  } = useTheme()
  const Icon = isInvalid ? AlertIndicatorIcon : CheckmarkIndicatorIcon
  const messageColor = isInvalid ? color.signalRed : color.signalGreen

  return (
    <MessageContainer {...rest}>
      <Icon width={`${iconSize.xs}em`} height={`${iconSize.xs}em`} />
      <MessageLabel size="xs" color={messageColor}>
        {text}
      </MessageLabel>
    </MessageContainer>
  )
}

export default FormHelperText
