import React, { useState, useRef, useEffect } from 'react'
import { useTheme } from '../../utils/useTheme'
import useFirstRender from '../../utils/useFirstRender'
import { MenuIcon, SearchIcon, PinIcon, ProfileIcon } from '../../design-tokens/icons'
import LanguageSelector from './LanguageSelector'
import TopBarSearch from './TopBarSearch'
import { Body, BodySize } from '../../design-tokens/typography'
import {
  TopBarContainer,
  TopBarContent,
  LeftItems,
  SearchButton,
  MenuButton,
  LocationButton,
  LoginButton,
  LogoContainer,
  TabletMenuList,
  DesktopMenuList,
  RootMenuItem,
  TopLevelMenuItem,
  RightItems,
  RootMenuRight,
  RootMenu,
  LoginStatus,
  FocusInner,
} from './TopBar.styles'
import { Mode, Size } from '../../basic-components/Button/Button'
import { handleNavItemClick, checkAriaCurrent } from '../Navigation/navigationItemUtils'
import { TopBarDefaultProps, TopBarProps, TopBarPropTypes } from './TopBar.types'

const DefaultLoginMenu: React.FC<{
  message: string
  isDark: boolean
  onClick: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
}> = ({ message, isDark, onClick }) => {
  return (
    <LoginButton aria-label={message} isDark={isDark} mode={Mode.secondary} size={Size.sm} onClick={onClick}>
      <ProfileIcon color="signalBlue" width="1.5em" />
      <span className="loginButtonText">{message}</span>
    </LoginButton>
  )
}

export const TopBar: React.FC<TopBarProps> = (props) => {
  const {
    navigationState,
    onMenuButtonClick,
    onSearchSubmit,
    onSearchButtonClick,
    onLoginButtonClick,
    onSearchQueryChange,
    searchMessage,
    loginMessage,
    onSearchFocus,
    onSearchBlur,
    onSearchClose,
    onSearchItemSelect,
    onLocaleSelect,
    servicePointsItem,
    logo: Logo,
    menuText,
    loginTemplate,
    languageSelectorLabel,
    languageSelectorMenuLabel,
    closeSearchLabel,
    hasSearch,
    hasLogin,
    hasLanguageSelector,
    ...rest
  } = props

  const [showSearch, toggleSearch] = useState(false)

  const {
    xyz: { color, iconSize },
  } = useTheme()

  const { isBusiness, topLevelMenuItems, rootMenuItems, activeTopLevelItemId, rootPage, currentPage } = navigationState

  const primaryColor = isBusiness ? color.neutralWhite : color.neutralNetworkGray

  const noDesktopSize = topLevelMenuItems ? topLevelMenuItems.length > 3 : false

  const buttonRef = useRef<HTMLButtonElement>(null)

  const isFirstRender = useFirstRender()

  useEffect(() => {
    if (!showSearch && !isFirstRender) {
      buttonRef.current?.focus()
    }
  }, [showSearch, isFirstRender])

  const handleOpenSearchButtonClick = () => {
    toggleSearch(true)
    onSearchButtonClick()
  }

  const handleSearchClose = () => {
    toggleSearch(false)
  }

  const topLeftItems = (topLevelMenuItems || []).map((item, index) => {
    return (
      <TopLevelMenuItem
        onClick={(e) => handleNavItemClick(item, e)}
        isDark={isBusiness}
        key={`${item.title}-${index}`}
        selected={activeTopLevelItemId && item.id === activeTopLevelItemId}
        isActive={false}
      >
        <a href={item.href} aria-current={checkAriaCurrent(item, currentPage)} tabIndex={0}>
          <FocusInner tabIndex={-1}>{item.title}</FocusInner>
        </a>
      </TopLevelMenuItem>
    )
  })

  return (
    <TopBarContainer isDark={isBusiness} {...rest}>
      <TopBarContent hasTopLevelMenu={!!topLevelMenuItems} hasSearch={showSearch}>
        <LeftItems>
          <MenuButton
            aria-label={menuText}
            isDark={isBusiness}
            mode={Mode.secondary}
            onClick={onMenuButtonClick}
            icon={MenuIcon}
            iconColor={primaryColor}
            hasBackground={false}
            size={Size.sm}
          />
          <LogoContainer
            hideInMobile={showSearch}
            href={rootPage && rootPage.href}
            aria-label={rootPage && rootPage.title}
            isDark={isBusiness}
          >
            <Logo textColor={primaryColor} />
          </LogoContainer>
          {topLevelMenuItems && !noDesktopSize && !showSearch && <DesktopMenuList>{topLeftItems}</DesktopMenuList>}
        </LeftItems>
        {hasSearch && showSearch && (
          <TopBarSearch
            id="topbar-search-id"
            message={searchMessage}
            closeSearchLabel={closeSearchLabel}
            onChange={onSearchQueryChange}
            onSearchSubmit={onSearchSubmit}
            onBlur={onSearchBlur}
            onClose={handleSearchClose}
            onFocus={onSearchFocus}
            isDark={isBusiness}
            onItemSelect={onSearchItemSelect}
          />
        )}
        {!showSearch && (
          <RightItems>
            <RootMenu rootMenuItems={rootMenuItems}>
              {rootMenuItems &&
                rootMenuItems.map((item, index) => {
                  return (
                    <RootMenuItem isDark={isBusiness} isActive={item.active} key={`${item.title}-${index}`}>
                      <a
                        href={item.href}
                        onClick={(e) => handleNavItemClick(item, e)}
                        aria-current={checkAriaCurrent(item, currentPage)}
                        tabIndex={0}
                      >
                        <FocusInner tabIndex={-1}>{item.title}</FocusInner>
                      </a>
                    </RootMenuItem>
                  )
                })}
            </RootMenu>
            <RootMenuRight>
              {hasSearch && (
                <SearchButton
                  aria-label={searchMessage}
                  isDark={isBusiness}
                  mode={Mode.secondary}
                  size={Size.sm}
                  onClick={handleOpenSearchButtonClick}
                  ref={buttonRef}
                >
                  <SearchIcon width={`${iconSize.s}rem`} height={`${iconSize.s}rem`} color={primaryColor} />
                  <Body size={BodySize.Five} color={primaryColor} as="div" className="searchText">
                    {searchMessage}
                  </Body>
                </SearchButton>
              )}
              {servicePointsItem && (
                <RootMenuItem isDark={isBusiness} isActive={false}>
                  <LocationButton isDark={isBusiness} href={servicePointsItem.href} tabIndex={0}>
                    <FocusInner tabIndex={-1}>
                      <PinIcon width={`${iconSize.s}rem`} height={`${iconSize.s}rem`} />
                      {servicePointsItem.title}
                    </FocusInner>
                  </LocationButton>
                </RootMenuItem>
              )}
              {hasLanguageSelector && (
                <RootMenuItem isDark={isBusiness} isActive={false}>
                  <LanguageSelector
                    onLocaleSelect={onLocaleSelect}
                    navigationState={navigationState}
                    iconColor={primaryColor}
                    ariaLabel={languageSelectorLabel}
                    menuAriaLabel={languageSelectorMenuLabel}
                  />
                </RootMenuItem>
              )}
              {hasLogin && (
                <LoginStatus>
                  {loginTemplate ? (
                    loginTemplate
                  ) : (
                    <DefaultLoginMenu message={loginMessage} isDark={isBusiness} onClick={onLoginButtonClick} />
                  )}
                </LoginStatus>
              )}
            </RootMenuRight>
          </RightItems>
        )}
      </TopBarContent>
      {topLevelMenuItems && !showSearch && <TabletMenuList>{topLeftItems}</TabletMenuList>}
    </TopBarContainer>
  )
}

TopBar.defaultProps = TopBarDefaultProps

TopBar.propTypes = TopBarPropTypes
