import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

export interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  borderTop?: boolean
  inline?: boolean
}

const FooterContainer = styled.div<FooterProps>`
  ${({ theme, inline, borderTop }) => css`
    display: ${inline ? 'inline-flex' : 'initial'};
    bottom: 0;
    margin-top: auto;
    width: 100%;
    padding: ${theme.spacing.sm}rem ${theme.spacing.sm}rem ${theme.spacing.sm}rem 0;
    border-top: ${borderTop && `1px solid ${theme.color.neutralGray5}`};
    word-wrap: break-word;
    flex-wrap: wrap;
  `}
`

/**
 *
 * @deprecated Custom implementation should be created instead
 */
const CardFooter: React.FC<FooterProps> = ({ children, ...rest }) => {
  return <FooterContainer {...rest}>{children}</FooterContainer>
}

CardFooter.propTypes = {
  borderTop: PropTypes.bool,
  inline: PropTypes.bool,
}

CardFooter.defaultProps = {
  borderTop: false,
  inline: false,
}

export default CardFooter
