import styled, { css } from 'styled-components'
import { Body } from '../../design-tokens/typography/Body/Body'
import {
  TerribleFeedbackIcon,
  BadFeedbackIcon,
  GoodFeedbackIcon,
  OkFeedbackIcon,
  GreatFeedbackIcon,
} from '../../design-tokens/icons/feedback'
import {
  TerribleFeedbackNoStrokePath,
  GreatFeedbackNoStrokePath,
} from '../../design-tokens/icons/feedback/Feedback.styles'
import { Path, Rect } from '../../design-tokens/icons/icons.styled'

export const StyledGroupWrapper = styled.div`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    display: flex;
    align-items: center;

    & > button {
      margin-right: ${spacing.space2}rem;
      &:last-child {
        margin-right: 0;
      }
    }
  `}
`

export const StyledTerribleFeedbackIcon = styled(TerribleFeedbackIcon)``
export const StyledBadFeedbackIcon = styled(BadFeedbackIcon)``
export const StyledOkFeedbackIcon = styled(OkFeedbackIcon)``
export const StyledGoodFeedbackIcon = styled(GoodFeedbackIcon)``
export const StyledGreatFeedbackIcon = styled(GreatFeedbackIcon)``

export const StyledFeedbackButton = styled.button(
  ({
    theme: {
      xyz: { spacing, color, borderRadius },
    },
    disabled,
  }) => css`
    display: flex;
    align-items: center;
    background-color: transparent;
    flex-direction: column;
    border: none;
    padding: 0 ${spacing.space1}rem 0.125rem;
    border-radius: ${borderRadius.xs}rem;
    border: 2px solid transparent;

    &:focus {
      outline: none;
      border: 2px solid ${color.signalBlue};
      box-shadow: 0 0 0.4375rem ${color.signalBlue};

      &:not(:focus-visible) {
        box-shadow: none;
        border: 2px solid transparent;
      }
    }

    ${StyledTerribleFeedbackIcon},
    ${StyledBadFeedbackIcon},
    ${StyledOkFeedbackIcon},
    ${StyledGoodFeedbackIcon},
    ${StyledGreatFeedbackIcon} {
      path,
      rect {
        transition: all 200ms ease-in 0s;
      }
    }

    ${!disabled &&
    css`
      &:hover,
      &:focus {
        ${StyledTerribleFeedbackIcon} {
          ${Path}:first-child {
            fill: ${color.brandPeach};
            stroke: none;
          }
        }
        ${StyledBadFeedbackIcon} {
          ${Path}:first-child {
            fill: ${color.brandPink};
            stroke: none;
          }
        }
        ${StyledOkFeedbackIcon} {
          ${Path}:first-child {
            fill: ${color.brandMutedYellow};
            stroke: none;
          }
        }
        ${StyledGoodFeedbackIcon}, ${StyledGreatFeedbackIcon} {
          ${Path}:first-child {
            fill: ${color.brandBrightYellow};
            stroke: none;
          }
        }
      }

      &:hover,
      &:active,
      &:focus {
        ${StyledTerribleFeedbackIcon}, ${StyledBadFeedbackIcon} {
          ${Path} {
            fill: ${color.brandRed};
            stroke: ${color.brandRed};
          }
        }
        ${StyledOkFeedbackIcon}, ${StyledGoodFeedbackIcon}, ${StyledGreatFeedbackIcon} {
          ${Path}, ${Rect} {
            fill: ${color.brandPurple};
            stroke: ${color.brandPurple};
          }
        }
        ${StyledTerribleFeedbackIcon}, ${StyledBadFeedbackIcon} {
          ${TerribleFeedbackNoStrokePath} {
            fill: ${color.brandRed};
            stroke: none;
          }
        }

        ${StyledOkFeedbackIcon}, ${StyledGoodFeedbackIcon}, ${StyledGreatFeedbackIcon} {
          ${GreatFeedbackNoStrokePath} {
            fill: ${color.brandPurple};
            stroke: none;
          }
        }
      }

      &:active {
        ${StyledTerribleFeedbackIcon} {
          ${Path}:first-child {
            fill: #e2ac9f;
          }
        }
        ${StyledBadFeedbackIcon} {
          ${Path}:first-child {
            fill: #ff9f9f;
          }
        }
        ${StyledOkFeedbackIcon} {
          ${Path}:first-child {
            fill: #ffbb38;
          }
        }
        ${StyledGoodFeedbackIcon} {
          ${Path}:first-child {
            fill: #ebb018;
          }
        }
        ${StyledGreatFeedbackIcon} {
          ${Path}:first-child {
            fill: #e5a502;
          }
        }
      }
    `}
  `
)

export const StyledIcon = styled(TerribleFeedbackIcon)<{ disabled: boolean }>`
  width: 3rem;
  height: auto;
`

export const StyledBody = styled(Body)`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    margin: ${spacing.space1}rem 0 0;
  `}
`
