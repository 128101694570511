import React from 'react'
import PropTypes from 'prop-types'
import { StyledProgressIndicator } from './ProgressIndicator.style'
import { Placement } from '../../basic-components/Tooltip/Tooltip'

export interface ProgressIndicatorProps {
  vertical?: boolean
  large?: boolean
  activeStep?: number
  tooltipPlacement?: keyof typeof Placement
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  vertical,
  large,
  activeStep,
  tooltipPlacement,
  children,
}) => {
  return (
    <StyledProgressIndicator
      vertical={vertical}
      large={large}
      tooltipPlacement={tooltipPlacement}
      activeStep={activeStep}
      data-design-system-id="progress-indicator"
    >
      {children}
    </StyledProgressIndicator>
  )
}

ProgressIndicator.propTypes = {
  vertical: PropTypes.bool,
  activeStep: PropTypes.number,
}

ProgressIndicator.defaultProps = {
  vertical: false,
  large: false,
  activeStep: 0,
}
