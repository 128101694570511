import styled, { css } from 'styled-components'
import { Body } from '../../design-tokens/typography'
import { TimePickerGroupTableItemProps } from './TImePickerGroup.types'

// Min height of the slot accordingly to accessibility guidelines 44px / 2.75rem @font-size:16px
const accessibleMinHeight = 2.75

export const Container = styled.div(
  ({ theme }) => css`
    height: 100%;
    width: 100%;
    display: flex;
    overflow-x: auto;
    @media (max-width: ${theme.xyz.breakpoint.md}) {
      justify-content: left;
    }
  `
)

export const TableCol = styled.div`
  display: flex;
  flex-direction: column;

  // fine tune padding for button box shadow
  padding-bottom: 22px;
  &:first-child {
    padding-left: 20px;
  }
  &:last-child {
    padding-right: 20px;
  }
`
export const DefaultTableItem = styled.div<TimePickerGroupTableItemProps>`
  display: flex;
  flex-direction: column;
  height: ${({ hourSpan }) => accessibleMinHeight * hourSpan}rem;
  padding: ${({ theme }) => theme.xyz.spacing.space1}rem;
  min-height: ${accessibleMinHeight}rem;
  min-width: ${({ theme }) => theme.xyz.spacing.space9}rem;
  text-align: center;
`
export const TableHeader = styled(Body)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.xyz.spacing.space3}em;
  white-space: nowrap;
  padding-left: ${({ theme }) => theme.xyz.spacing.space2}rem;
  padding-right: ${({ theme }) => theme.xyz.spacing.space2}rem;
`
export const TableItemEmpty = styled.div`
  border-right: ${({ theme }) => theme.xyz.color.neutralGray5} solid 1px;
  height: 100%;
  margin: 4px 12px;
`
