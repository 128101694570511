import React, { ButtonHTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import styled, { css, useTheme } from 'styled-components'
import { CloseIcon } from '../../design-tokens/icons'

export interface FilterButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean
  onClick: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
  /**
   * Shows close icon when filter button is selected.
   * This should be enabled for when de-selecting a filter button removes the button, not just deselects it.
   */
  withCloseIcon?: boolean
}

export const StyledButton = styled.button<FilterButtonProps>(
  ({ theme, selected }) => css`
    font-size: ${theme.xyz.fontSize.label.five}rem;
    font-weight: ${theme.xyz.fontWeight.medium};
    line-height: ${theme.xyz.lineHeight.label.five};
    color: ${selected ? theme.xyz.color.neutralWhite : theme.xyz.color.neutralNetworkGray};
    background-color: ${selected ? theme.xyz.color.brandPetrol : theme.xyz.color.neutralGray2};
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${theme.xyz.spacing.space3}rem;
    border-radius: 1.25rem;
    cursor: pointer;
    outline: none;
    border: 2px solid transparent;
    padding: 0;

    &:hover {
      background-color: ${selected ? theme.xyz.color.brandPetrol : theme.xyz.color.neutralOnHoverGray};
    }

    &:active {
      background-color: ${selected ? theme.xyz.color.brandPetrol : theme.xyz.color.neutralOnPressGray};
    }

    &:focus {
      outline: 0;
      border: 2px solid ${theme.xyz.color.brandPetrol};
      box-shadow: 0px 0px 3px ${theme.xyz.color.signalBlue};
    }

    &:not(:last-of-type) {
      margin-right: ${theme.xyz.spacing.space2}rem;
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      color: ${theme.xyz.color.neutralPassiveGray};
      background-color: ${theme.xyz.color.neutralGray2};
    }
  `
)

const StyledCloseIcon = styled(CloseIcon)`
  ${({ theme }) => css`
    margin-left: ${theme.xyz.spacing.space2}rem;
    flex-shrink: 0;
  `}
`

const FocusInner = styled.span`
  ${({ theme }) => css`
    padding: 0.4375rem ${theme.xyz.spacing.space4}rem;
  `}
`

const FilterButton = React.forwardRef<HTMLButtonElement, FilterButtonProps>(
  ({ selected, children, onClick, disabled, withCloseIcon, ...rest }, ref) => {
    const theme = useTheme()

    return (
      <StyledButton
        tabIndex={0}
        selected={selected}
        role="option"
        aria-selected={selected}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
        {...rest}
      >
        <FocusInner tabIndex={-1}>
          {children}
          {withCloseIcon && selected && (
            <StyledCloseIcon width="1.14em" height="1.14em" aria-hidden={true} color={theme.xyz.color.neutralWhite} />
          )}
        </FocusInner>
      </StyledButton>
    )
  }
)

FilterButton.displayName = 'FilterButton'

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  withCloseIcon: PropTypes.bool,
}

FilterButton.defaultProps = {
  selected: false,
  disabled: false,
  withCloseIcon: false,
}

export { FilterButton }
