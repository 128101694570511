import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'

export enum CardSize {
  md = 'md',
  lg = 'lg',
}

export interface Props {
  lightBackground?: boolean
  cardSize?: keyof typeof CardSize
}

const CardContainer = styled.div<Props>`
  ${({ theme, lightBackground, cardSize }) => css`
    border-radius: 0.25rem;
    margin: 0;
    box-shadow: 0 8px 22px rgba(27, 31, 35, 0.07);
    background: ${lightBackground ? theme.color.neutralGray2 : theme.color.neutralWhite};
    width: 19.5rem;
    padding-left: ${theme.spacing.sm}rem;
    min-height: ${cardSize === CardSize.lg ? '19.5rem' : '9rem'};
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    overflow: hidden;
  `}
`

/**
 *
 * @deprecated Custom implementation should be created instead
 */
const Card: React.FC<Props> = ({ children, ...rest }) => {
  return <CardContainer {...rest}>{children}</CardContainer>
}

Card.propTypes = {
  lightBackground: PropTypes.bool,
  cardSize: PropTypes.string as Validator<CardSize>,
}

Card.defaultProps = {
  lightBackground: false,
  cardSize: CardSize.lg,
}

export default Card
