/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import FocusLock, { AutoFocusInside } from 'react-focus-lock'

import { AllProps, OwnState } from './Modal.types'
import {
  ButtonWrapper,
  Close,
  Header,
  MainContent,
  ModalContent,
  ModalWrapper,
  Root,
  StyledButton,
  StyledTitle,
  StyledSecondaryButton,
} from './Modal.style'
import { CloseIcon } from '../../design-tokens/icons'
import { Mode } from '../../basic-components/Button/Button'

/**
 * @deprecated please use Dialog component instead
 */
export class Modal extends React.PureComponent<AllProps, OwnState> {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onPrimaryButtonClick: PropTypes.func,
    primaryButtonStyles: PropTypes.object,
    secondaryButtonOnClick: PropTypes.func,
    secondaryButtonStyles: PropTypes.object,
    buttonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    secondaryButtonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    backgroundColor: PropTypes.string,
    noWrapper: PropTypes.bool,
    top: PropTypes.string,
    closeText: PropTypes.string,
    disableButtons: PropTypes.bool,
    showCloseIcon: PropTypes.bool,
  }

  static defaultProps = {
    showCloseIcon: true,
  }

  static readonly root = React.createRef<HTMLDivElement>()
  private readonly wrapper = React.createRef<HTMLDivElement>()
  private readonly close = React.createRef<HTMLButtonElement>()

  constructor(props: any) {
    super(props)

    this.onBackgroundClick = this.onBackgroundClick.bind(this)
    this.state = {
      scrollY: 0,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState({ scrollY: window.scrollY })
      // Prevents page content scrolling when modal is on top
      window.document.body.style.top = '0'
      window.document.body.style.overflow = 'hidden'
      window.document.body.style.position = 'fixed'
    }

    if (Modal.root.current) {
      Modal.root.current.classList.add('open')
      Modal.root.current.addEventListener('click', this.onBackgroundClick)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.document.body.style.overflow = ''
      window.document.body.style.position = ''
      window.document.body.style.top = ''
      window.scrollTo(0, this.state.scrollY)
    }

    if (Modal.root.current) {
      Modal.root.current.classList.remove('open')
      Modal.root.current.removeEventListener('click', this.onBackgroundClick)
    }
  }

  onBackgroundClick(e: MouseEvent) {
    if (this.wrapper.current && !this.wrapper.current.contains(e.target as Element)) {
      this.props.onClose()
    }
  }

  render() {
    const {
      children,
      headerContent,
      buttonContent,
      onPrimaryButtonClick,
      primaryButtonStyles,
      secondaryButtonContent,
      secondaryButtonOnClick,
      secondaryButtonStyles,
      onClose,
      className,
      backgroundColor,
      noWrapper,
      top,
      closeText,
      disableButtons,
      showCloseIcon,
    } = this.props

    const modalContents = (
      <FocusLock>
        <ModalWrapper
          className={className}
          backgroundColor={backgroundColor}
          ref={this.wrapper as any}
          noWrapper={noWrapper}
          top={top}
        >
          <ModalContent aria-live="assertive" role="dialog" aria-labelledby="modal-title">
            <AutoFocusInside>
              <Header>
                <StyledTitle as="h1" size="md" id="modal-title">
                  {headerContent}
                </StyledTitle>
              </Header>
              <MainContent>{children}</MainContent>
              {(buttonContent || secondaryButtonContent) && (
                <ButtonWrapper>
                  {secondaryButtonContent && (
                    <StyledSecondaryButton
                      mode={Mode.secondary}
                      style={secondaryButtonStyles}
                      data-testid="modal__secondary-action"
                      onClick={() => secondaryButtonOnClick && secondaryButtonOnClick()}
                      disabled={disableButtons}
                    >
                      {secondaryButtonContent}
                    </StyledSecondaryButton>
                  )}
                  {buttonContent && (
                    <StyledButton
                      data-testid="modal__primary-action"
                      style={primaryButtonStyles}
                      onClick={() => onPrimaryButtonClick && onPrimaryButtonClick()}
                      disabled={disableButtons}
                    >
                      {buttonContent}
                    </StyledButton>
                  )}
                </ButtonWrapper>
              )}
              {showCloseIcon && (
                <Close ref={this.close as any} role="button" tabIndex={0} aria-label={closeText} onClick={onClose}>
                  <CloseIcon width="1.5em" height="1.5em" />
                </Close>
              )}
            </AutoFocusInside>
          </ModalContent>
        </ModalWrapper>
      </FocusLock>
    )

    return Modal.root.current ? ReactDOM.createPortal(modalContents, Modal.root.current) : null
  }
}

/**
 * @deprecated please use Dialog components instead
 */
export const ModalRoot = () => <Root ref={Modal.root as any} />
