import React, { useState, useEffect, RefObject, useCallback } from 'react'

/**
 * Hook that handles outside click or touch events of a specified element and calls onClickOutside function if click
 * event is outside of the element.
 * @param ref - Ref to the element.
 * @param onClickOutside - Function which gets executed if click or touch event has been outside of the element.
 * @param isActive - Controls whether event-handlers should be added or removed
 * @param exclusions - Id array of elements which are outside of the element but won't trigger the handler function.
 */
const useClickOutside = (
  ref: RefObject<any>,
  onClickOutside: (e: Event) => any,
  isActive: boolean,
  exclusions: string[]
): boolean | void => {
  const onClickOutsideChange = !onClickOutside

  const elementsIsInList = (ids: string[], element: Element) => {
    if (!element) {
      return false
    }
    for (let current = element; current; current = current.parentElement) {
      if (current?.id && ids.includes(current.id)) {
        return true
      }
    }
    return false
  }

  const handler = useCallback(
    (event: Event) => {
      if (!(event?.target instanceof Element) || !ref?.current) {
        return
      }
      if (!ref?.current.contains(event?.target) && !elementsIsInList(exclusions, event?.target as Element)) {
        onClickOutside(event)
      }
    },
    [exclusions, onClickOutside, ref]
  )

  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', handler)
      document.addEventListener('touchstart', handler)
    }

    return () => {
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [handler, onClickOutsideChange, isActive, exclusions])
}

export default useClickOutside
