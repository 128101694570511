import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'

import Tablelvl2Context from '../../composite-components/Table/Tablelvl2Context'

type TableHeadProps = HTMLAttributes<HTMLTableSectionElement>

const StyledTableHead = styled.thead`
  border-bottom: 2px solid ${({ theme }) => theme.xyz.color.neutralGray5};
`

const tablelvl2 = {
  variant: 'head',
}

export const TableHead: React.FC<TableHeadProps> = (props) => {
  return (
    <Tablelvl2Context.Provider value={tablelvl2}>
      <StyledTableHead {...props} />
    </Tablelvl2Context.Provider>
  )
}
