import styled, { css } from 'styled-components'

import { mediaQuery } from '../../utils/mediaQuery'
import { WrapperProps } from './Modal.types'
import Button from '../../basic-components/Button'
import Title from '../../typography/Title'

export const Close = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 1px;
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 1.125rem;
  right: 1.125rem;
  z-index: 10;
`

export const Root = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  overflow-y: auto;
  background-color: rgba(59, 74, 87, 0.3);

  /* PATCH: iOS v12 Safari bug */
  -webkit-overflow-scrolling: touch;
  & * {
    -webkit-overflow-scrolling: touch;
  }

  ${mediaQuery.sm`
    padding: 2rem;
  `}

  &.open {
    display: block;
  }
`

export const ModalWrapper = styled.div<WrapperProps>(
  ({ theme, top }) => css`
    max-height: calc(100vh);
    display: flex;
    background-color: ${theme.color.neutralWhite};
    max-width: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);

    ${mediaQuery.sm`
    max-width: 37rem;
    min-height: auto;
    min-height: initial;
    ${top ? `top: ${top};` : `top: 50%; transform: translate(-50%, -50%);`}
    border-radius: 0.625rem;
  `}
  `
)

export const ModalContent = styled.div(
  ({ theme }) => css`
    width: 100%;
    padding: ${theme.spacing.lg}rem;
    position: relative;
    background-color: ${theme.color.neutralWhite};

    ${mediaQuery.sm`
    border: 0 solid transparent;
    border-radius: 0.625rem;
  `}

    & > div:last-child {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
    }
  `
)

export const MainContent = styled.div`
  margin-bottom: 2rem;
`

export const Header = styled.div(
  ({ theme }) => css`
    position: sticky;
    padding-bottom: ${theme.spacing.sm}em;
  `
)

export const StyledButton = styled(Button)`
  width: 100%;

  ${mediaQuery.md`
    max-width: 32rem;
  `}
`

export const StyledSecondaryButton = styled(Button)`
  width: 100%;

  ${mediaQuery.md`
    max-width: 32rem;
  `}
`

export const ButtonWrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spacing.xxs}rem;
  padding-top: ${({ theme }) => theme.spacing.xxs}rem;
  > * {
    margin: 0.5rem 0;
  }

  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }

  ${mediaQuery.md`
    flex-direction: row;

    > * {
      margin: 0 0.5rem;
    }

    > *:first-child {
      margin-left: 0;
    }
    > *:last-child {
      margin-right: 0;
    }
  `}
`

export const StyledTitle = styled(Title)`
  word-break: break-word;
`
