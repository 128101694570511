import styled, { css } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'
import { Theme } from '@postidigital/posti-theme'
import { ThemeColor, TitleSize } from '../../utils/helpers'

export type As = keyof Pick<JSX.IntrinsicElements, 'h1' | 'h2' | 'h3' | 'h4' | 'div'>

export interface TitleProps {
  as: As
  size: TitleSize
  color?: ThemeColor | string
  semibold?: boolean
}

/**
 * For extending props without needing to export an interface; makes usage of this component easier where needed
 * @deprecated please use Headline or Label component instead.
 */
const Title = styled.div<TitleProps>(
  ({ theme, size, color, semibold }) => css`
    font-weight: ${semibold ? theme.fontWeight.semiBold : theme.fontWeight.bold};

    ${size === 'xxl' &&
    `
      font-family: ${theme.fontFamily.secondary};
      letter-spacing: -0.017rem;
      font-weight: ${semibold ? theme.fontWeight.semiBold : theme.fontWeight.medium};
    `}

    ${size === 'xl' &&
    `
      font-family: ${theme.fontFamily.secondary};
      letter-spacing: -0.01rem;
      font-weight: ${semibold ? theme.fontWeight.semiBold : theme.fontWeight.medium};
    `}

    color: ${theme.color[color] || color};
    font-size: ${theme.fontSize.title[size]}rem;
    line-height: ${theme.lineHeight.title[size]}rem;
  `
)

Title.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'div']).isRequired as Validator<As>,
  size: PropTypes.oneOf(Object.keys(Theme.fontSize.title)) as Validator<TitleSize>,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(Object.keys(Theme.color))]) as Validator<ThemeColor>,
  semibold: PropTypes.bool,
}

Title.defaultProps = {
  color: 'neutralNetworkGray',
  semibold: false,
}

export default Title
