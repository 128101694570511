import React, { ButtonHTMLAttributes } from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { ChatBubbleIcon } from '../../design-tokens/icons/misc'
import styled, { css } from 'styled-components'
import Tooltip from '../Tooltip'
import { Placement } from '../Tooltip/Tooltip'
import { FocusInner } from '../ActionButton/ActionButton'
import PropTypes from 'prop-types'

export interface ChatButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  'aria-label': string
  disabled?: boolean
  title: string
  onClick: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
}

const StyledButton = styled.button<Omit<ChatButtonProps, 'title'>>`
  ${({ theme, disabled }) => css`
    cursor: pointer;
    align-items: center;
    border-radius: 50%;
    width: ${theme.xyz.spacing.space10}rem;
    height: ${theme.xyz.spacing.space10}rem;
    border: none;
    transition: all 100ms ease-in;
    padding: 0;
    box-shadow: 0px 8px 22px rgba(38, 44, 48, 0.3);
    background-color: ${disabled ? theme.xyz.color.neutralGray5 : theme.xyz.color.brandPurple};

    &:focus {
      outline: 0;
      filter: drop-shadow(0px 0px 3px ${theme.xyz.color.signalBlue});
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      background-color: ${theme.xyz.color.neutralGray5};
      box-shadow: 0px 8px 22px rgba(38, 44, 48, 0.3);
    }
  `}
`

export const ChatButton: React.FC<ChatButtonProps> = ({ disabled, title, ...rest }) => {
  return (
    <Tooltip id="chatbutton" title={title} placement={Placement.top}>
      <StyledButton {...rest} disabled={disabled} aria-disabled={disabled} tabIndex={0}>
        <FocusInner tabIndex={-1}>
          <ChatBubbleIcon disabled={disabled} aria-hidden={true} />
        </FocusInner>
      </StyledButton>
    </Tooltip>
  )
}

ChatButton.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
