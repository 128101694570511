import styled, { css } from 'styled-components'
import ListItem from '../ListItem'

export const Container = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
`

interface OptionsContainerProps {
  maxHeight: string
}

export const OptionsContainer = styled.ul<OptionsContainerProps>(
  ({ theme, maxHeight }) => css`
    background-color: ${theme.color.neutralWhite};
    max-height: ${maxHeight};
    position: absolute;
    width: 100%;
    box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07);
    border-radius: 0.25rem;
    overflow-y: auto;
    z-index: 4000;
    margin: 0;
    padding: 0;

    &:focus {
      outline: 0;
    }
  `
)

interface OptionProps {
  isSelected: boolean
}

const StyledListItem = styled(ListItem)``

export const Option = styled.li<OptionProps>(
  ({ theme, isSelected }) => css`
    background-color: ${isSelected ? theme.color.neutralGray5 : 'transparent'};
    list-style: none;
    margin: 0;
    padding: 0;
    cursor: pointer;

    ${StyledListItem} {
      padding-left: 4rem;
    }

    &:focus,
    &:hover,
    &[data-focus='true'] {
      background-color: ${theme.color.neutralGray2};
      cursor: pointer;
      outline: 0;

      ${isSelected &&
      `
        background-color: ${theme.color.neutralGray5};
        filter: brightness(92%);
      `}
    }
  `
)
