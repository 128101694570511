import React, { ComponentProps } from 'react'
import styled, { css } from 'styled-components'

import Button from '../../basic-components/Button'
import { TimePickerProps } from './TimePicker.types'

const StyledButton = styled(Button)<ComponentProps<typeof Button> & Pick<TimePickerProps, 'error'>>(
  ({
    theme: {
      xyz: { color, borderRadius },
    },
    mode,
    error,
  }) => css`
    width: 100%;
    justify-content: center;
    flex-grow: 1;
    padding: 0;
    border-radius: ${borderRadius.xs}rem;
    box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);

    border: ${error ? `2px solid ${color.signalRed}` : 'none'};
    color: ${error ? color.signalRed : undefined};

    & > span {
      padding: 0;
      height: 100%;
    }

    &:disabled {
      color: ${color.neutralPassiveGray};
      background: ${color.neutralGray5};
      box-shadow: none;
    }
    &:disabled:before {
      background: transparent;
    }
    &:hover:not(:disabled) {
      background-color: ${mode === 'primary' ? color.signalHoverBlue : color.neutralGray2};
    }
    &:focus:not(:disabled) {
      border: none;
      box-shadow: ${error ? `0px 0px 7px ${color.signalRed};` : undefined};
    }
    &::before {
      border-radius: ${borderRadius.xs}rem;
    }
    // disable growing effect
    &:hover:not(:disabled):before {
      display: none;
    }
  `
)

const UnstyledTimePicker: React.FC<TimePickerProps> = (props) => {
  const { from, to, fromDate, toDate, onSelect, selected, disabled, error, className } = props

  const onClick = () => {
    // Toggle selection
    if (selected || fromDate === undefined || toDate === undefined) {
      onSelect?.(null)
    } else {
      onSelect?.({
        from: fromDate,
        to: toDate,
      })
    }
  }

  const enableSelectedState = selected && Boolean(error) === false

  return (
    <StyledButton
      mode={enableSelectedState ? 'primary' : 'secondary'}
      size="sm"
      onClick={onClick}
      disabled={disabled}
      error={error}
      className={className}
    >
      {from}-{to}
    </StyledButton>
  )
}

export const TimePicker = styled(UnstyledTimePicker)``
