import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import styled, { css } from 'styled-components'

import Title, { As } from '../../typography/Title'
import { useTheme } from '../../utils/useTheme'
import { CheckmarkCircledIcon } from '../../design-tokens/icons'
import { KeyboardKeys } from '../../utils/keyboardNavigation'

export interface AllProps {
  as?: As
  selected: boolean
  disabled?: boolean
  onClick: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
  showSelectedIcon?: boolean
}

const SelectionCheckIcon = styled(CheckmarkCircledIcon)<{ selected: boolean }>`
  position: absolute;
  right: ${({ theme }) => theme.spacing.xs}rem;
  top: 50%;
  transform: translateY(-50%);
  transition: all 200ms ease-in;
  opacity: ${({ selected }) => (selected ? 1 : 0)};
`

const StyledInner = styled(Title)(
  ({ theme }) => css`
    padding: ${theme.spacing.sm}rem 5.5rem ${theme.spacing.sm}rem ${theme.spacing.xs}rem;
    position: relative;
    outline: none;
    cursor: pointer;

    svg:first-child {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 1.125rem;
    }
  `
)

const StyledTitle = styled.div(
  ({ theme }) => css`
    outline: none;
    width: 100%;
    border: 0;
    text-align: left;

    &:focus > ${StyledInner} {
      background-color: ${theme.color.neutralOnPressGray};
    }
  `
)

/**
 *
 * @deprecated Expander component should be used instead
 */
const SelectionTitle: React.FC<AllProps> = ({
  children,
  as,
  selected,
  disabled,
  onClick,
  showSelectedIcon,
  ...rest
}) => {
  const theme = useTheme()

  const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key !== KeyboardKeys.Enter && e.key !== KeyboardKeys.Space) {
      return null
    }

    e.preventDefault()
    onClick(e)
  }

  return (
    <StyledTitle
      role="button"
      onClick={onClick}
      onKeyPress={handleKeyPress}
      tabIndex={disabled ? -1 : 0}
      aria-disabled={disabled}
      aria-pressed={selected}
      {...rest}
    >
      <StyledInner as="div" size="sm" semibold tabIndex={-1}>
        {children}
        {showSelectedIcon && (
          <SelectionCheckIcon selected={selected} width="1.25em" height="1.25em" color={theme.color.signalBlue} />
        )}
      </StyledInner>
    </StyledTitle>
  )
}

SelectionTitle.propTypes = {
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  as: PropTypes.string as Validator<As>,
  showSelectedIcon: PropTypes.bool,
}

SelectionTitle.defaultProps = {
  selected: false,
  disabled: false,
  as: 'div',
  showSelectedIcon: true,
}

export default SelectionTitle
