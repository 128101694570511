import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import {
  ComplexNotificationContainer,
  ComplexNotificationIllustrationWrapper,
  ComplexNotificationTextWrapper,
  ComplexNotificationTitle,
  ComplexNotificationWrapper,
} from './ComplexNotification.style'
import { ComplexNotificationIllustrationProps, ComplexNotificationProps } from './ComplexNotification.types'
import { NotificationType } from './Notification.types'
import { Body } from '../../design-tokens/typography/Body'
import { Headline } from '../../design-tokens/typography/Headline'

export const ComplexNotification: React.FC<ComplexNotificationProps> = ({
  type,
  width,
  notificationTitle,
  notificationBody,
  illustration,
  ...rest
}) => {
  const ImageComponent = !!illustration?.imageHref ? (
    <img src={illustration?.imageHref} alt="" />
  ) : (
    illustration?.imageJSX
  )

  return (
    <ComplexNotificationContainer type={type} width={width} {...rest}>
      <ComplexNotificationWrapper>
        <ComplexNotificationTextWrapper>
          <ComplexNotificationTitle size="Seven" addMarginBottom={!!notificationTitle && !!notificationBody}>
            {notificationTitle}
          </ComplexNotificationTitle>
          {!!notificationTitle ? (
            <Body size="Five" as="div">
              {notificationBody}
            </Body>
          ) : (
            <Headline size="Eight" as="div">
              {notificationBody}
            </Headline>
          )}
        </ComplexNotificationTextWrapper>
        {!!illustration && (
          <ComplexNotificationIllustrationWrapper {...illustration}>
            {ImageComponent}
          </ComplexNotificationIllustrationWrapper>
        )}
      </ComplexNotificationWrapper>
    </ComplexNotificationContainer>
  )
}

const ComplexNotificationIllustrationPropType = PropTypes.shape({
  imageHref: PropTypes.string,
  imageJSX: PropTypes.element,
  withVerticalIndent: PropTypes.bool,
  withHorizontalIndent: PropTypes.bool,
  cutOnNarrowScreen: PropTypes.bool,
}) as Validator<ComplexNotificationIllustrationProps>

ComplexNotification.propTypes = {
  type: PropTypes.oneOf(Object.values(NotificationType)) as Validator<NotificationType>,
  width: PropTypes.string,
  notificationTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  notificationBody: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  illustration: ComplexNotificationIllustrationPropType,
}

ComplexNotification.defaultProps = {
  type: NotificationType.informational,
}
