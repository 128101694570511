import React, { HTMLAttributes } from 'react'
import PropTypes, { Validator } from 'prop-types'
import styled, { css } from 'styled-components'

import Title from '../../typography/Title'
import Text from '../../typography/Text'
import { CheckmarkCircledIcon } from '../../design-tokens/icons'
import { useTheme } from '../../utils/useTheme'

export enum Status {
  completed = 'completed',
  active = 'active',
  incomplete = 'incomplete',
}

export interface AllProps extends HTMLAttributes<HTMLDivElement> {
  status: keyof typeof Status
  title: string
  body: string | JSX.Element | Element
  tabIndex?: number
  role?: string
  statusPrefix?: string
  ariaLabel?: string
}

const StyledContainer = styled.div<{ status: keyof typeof Status }>(
  ({ theme, status, tabIndex }) => css`
    padding: ${theme.spacing.xs}rem 1.5rem ${theme.spacing.xs}rem 1.25rem;
    position: relative;
    color: ${theme.color.neutralBlack};
    max-width: 22rem;
    overflow: hidden;
    position: relative;
    outline: none;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0.1875rem;
      background-color: ${status === Status.completed
        ? theme.color.signalGreen
        : status === Status.active
        ? theme.color.neutralPassiveGray
        : theme.color.neutralGray5};
      transition: all 200ms ease-in;
    }

    & + & {
      margin-top: 0.125rem;
    }

    ${tabIndex >= 0 &&
    css`
      cursor: pointer;

      &:focus,
      &:hover {
        background-color: ${theme.color.neutralOnPressGray};
      }
    `}
  `
)

const StyledTitle = styled(Title)`
  margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
`

const StyledIcon = styled(CheckmarkCircledIcon)(
  ({ theme }) => css`
    position: absolute;
    right: ${theme.spacing.sm}rem;
    top: ${theme.spacing.xs}rem;
  `
)

const FormStep: React.FC<AllProps> = (props) => {
  const { title, body, status, tabIndex, statusPrefix, role, ariaLabel, ...rest } = props
  const theme = useTheme()

  return (
    <StyledContainer
      status={status}
      aria-label={ariaLabel || `${statusPrefix ? `${statusPrefix}: ` : ''}${title}, ${body}`}
      role={role}
      tabIndex={tabIndex}
      {...rest}
    >
      <StyledTitle as="div" size="xs" semibold>
        {title}
      </StyledTitle>
      <Text size="sm">{body}</Text>
      <StyledIcon
        width="1.25em"
        height="1.25em"
        aria-hidden={true}
        color={
          status === Status.completed
            ? theme.color.signalGreen
            : status === Status.active
            ? theme.color.neutralIconGray
            : theme.color.neutralGray5
        }
      />
    </StyledContainer>
  )
}

FormStep.propTypes = {
  status: PropTypes.oneOf(Object.keys(Status)).isRequired as Validator<Status>,
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  tabIndex: PropTypes.number,
  role: PropTypes.string,
  statusPrefix: PropTypes.string,
  ariaLabel: PropTypes.string,
}

FormStep.defaultProps = {
  status: Status.incomplete,
  tabIndex: -1,
  // Role attribute is defined as "text" in order to VoiceOver to read all content.
  // It's valid, but is something that makes VoiceOver to work as expected
  role: 'text',
}

export default FormStep
