import styled, { css } from 'styled-components'
import { InboxIcon } from '../../design-tokens/icons'
import { Headline, Label } from '../../design-tokens/typography'
import { IconButton } from '../IconButton'
import { getColor } from './utils'
import { ContentProps, MenuItemProps } from './MenuItem.types'

export const StyledMenuItem = styled.li<
  Pick<MenuItemProps, 'isSubItem' | 'redirectsToSubMenu' | 'hasSubItems'> & ContentProps
>(
  ({ theme, selected, disabled, isSubItem, redirectsToSubMenu, hasSubItems }) => css`
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 2.5rem;
    cursor: pointer;
    width: 100%;
    color: ${selected ? theme.xyz.color.neutralWhite : theme.xyz.color.neutralNetworkGray};
    background-color: ${selected ? theme.xyz.color.brandBrightYellow : theme.xyz.color.neutralWhite};

    & > span {
      justify-content: inherit;
      padding-left: ${theme.xyz.spacing.space3}rem;
      padding-right: ${theme.xyz.spacing.space3}rem;
      padding-top: ${isSubItem ? '0.75rem' : '1rem'};
      padding-bottom: ${isSubItem ? '0.75rem' : '1rem'};
      ${(redirectsToSubMenu || hasSubItems) && `padding-right: ${theme.xyz.spacing.space3}rem;`}
      ${isSubItem && `padding-left: ${theme.xyz.spacing.space5}rem;`}
    }

    &:hover {
      background-color: ${selected ? theme.xyz.color.brandBrightYellow : theme.xyz.color.neutralOnHoverGray};
    }

    &:active {
      background-color: ${selected ? theme.xyz.color.brandBrightYellow : theme.xyz.color.neutralOnPressGray};
    }

    &:focus {
      outline: 0;
      box-shadow: 0px 0px 7px ${theme.xyz.color.signalBlue};
    }

    ${disabled &&
    css`
      cursor: default;
      pointer-events: none;
      background-color: ${theme.xyz.color.neutralGray5};
    `}
  `
)

export const StyledLabel = styled(Headline)<ContentProps>(
  ({ theme, disabled, selected, hasIcon }) => css`
    overflow: hidden;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${getColor({ selected, disabled })};
    ${hasIcon && `margin-left: ${theme.xyz.spacing.space3}rem;`}
  `
)

export const MenuItemWrapper = styled.div<{ hasIcon: boolean; hasButton: boolean }>(
  ({ hasIcon, hasButton }) => css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - ${hasButton ? '2.125rem' : '0rem'} - ${hasIcon ? '1.5rem' : '0rem'});
  `
)
export const StyledSubtitle = styled(Label)<ContentProps>(
  ({ theme, disabled, selected, hasIcon }) => css`
    display: block;
    padding: 0;
    margin-top: 0.125rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    ${hasIcon && `margin-left: ${theme.xyz.spacing.space3}rem;`}

    color: ${getColor({ selected, disabled })};
  `
)

export const StyledButton = styled(IconButton)<Pick<MenuItemProps, 'open' | 'hasSubItems' | 'onButtonClick'>>(
  ({ open, hasSubItems }) => css`
    position: absolute;
    right: 1rem;
    ${hasSubItems && `transform: rotate(${open ? 180 : 0}deg);`}
    margin-left: auto;
  `
)

export const StyledIcon = styled(InboxIcon)(
  ({ theme }) => css`
    width: ${theme.xyz.spacing.space4}rem;
    height: ${theme.xyz.spacing.space4}rem;
    flex-shrink: 0;
  `
)
