import React, { HTMLAttributes, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { NavDropdown } from '../../basic-components/NavDropdown'
import { NavButton } from '../../basic-components/NavButton'
import { LanguageIcon } from '../../design-tokens/icons'
import { MenuOverflowDirection } from '../../basic-components/Menu/Menu'
import { NavigationStateType } from './TopBar.types'

// TODO: New style should be added to NavDropdown and NavButton
const StyledWrapper = styled.div<{ isDark: boolean }>(
  ({
    theme: {
      xyz: { color, fontSize, fontWeight, spacing, borderRadius },
    },
    isDark,
  }) => css`
    button {
      background-color: transparent;
      border: 1px solid transparent;

      & > svg {
        height: auto !important;
      }

      & > span {
        color: ${isDark ? color.neutralWhite : color.neutralNetworkGray};
        font-size: ${fontSize.body.five}rem;
        font-weight: ${fontWeight.medium};
        padding: ${spacing.space1}rem ${spacing.space2}rem;
        height: auto;
      }

      &:hover,
      &:active {
        background-color: ${isDark ? color.neutralBlack : color.neutralOnHoverGray};
      }

      &:focus {
        background-color: ${isDark ? color.neutralNetworkGray : 'transparent'};
        border: 1px solid ${isDark ? color.brandBrightYellow : color.signalBlue};
        box-shadow: 0px 0px 7px ${isDark ? color.brandBrightYellow : color.signalBlue};
      }

      &:hover,
      &:focus,
      &:active {
        span {
          color: ${isDark ? color.brandBrightYellow : color.signalBlue};
        }
        svg {
          & > path {
            fill: ${isDark ? color.brandBrightYellow : color.signalBlue};
          }
        }
      }
    }

    ul {
      border: 1px solid transparent;
      &:focus {
        border: 1px solid ${isDark ? color.brandBrightYellow : color.signalBlue};
        box-shadow: 0px 0px 7px ${isDark ? color.brandBrightYellow : color.signalBlue};
      }
      button {
        &:focus {
          box-shadow: none;
        }
      }
    }

    & li:first-of-type button {
      border-radius: ${borderRadius.sm}rem ${borderRadius.sm}rem 0 0;
    }

    & li:last-of-type button {
      border-radius: 0 0 ${borderRadius.sm}rem ${borderRadius.sm}rem;
    }
  `
)

const StyledNavDropdown = styled(NavDropdown)(
  ({
    theme: {
      xyz: { borderRadius },
    },
  }) => css`
    border-radius: ${borderRadius.lg}rem;
  `
)

const NavDropdownItem = styled(NavButton).attrs({ mode: 'secondary', withArrow: false })`
  ${({
    theme: {
      xyz: { spacing, color },
    },
  }) => css`
    width: 100%;

    & > span {
      padding: ${spacing.space3}rem;
      span {
        color: ${color.neutralNetworkGray};
      }
    }
  `}
`

const StyledLanguageIcon = styled(LanguageIcon).attrs({ width: '1.5rem', height: '1.5rem' })`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    flex-shrink: 0;
    margin-right: ${spacing.space1}rem;
  `}
`

export const toLocalizedTitle = (locale: string): string => {
  switch (locale.toUpperCase()) {
    case 'FI':
      return 'Suomi'
    case 'EN':
      return 'English'
    case 'SV':
      return 'Svenska'
    default:
      break
  }
  return locale.toUpperCase()
}

export interface LanguageSelectorProps extends HTMLAttributes<HTMLElement> {
  navigationState: {
    isBusiness?: boolean
    locales?: string[]
    currentLocale?: string
  }
  onLocaleSelect: (id: string) => void
  iconColor?: string
  ariaLabel?: string
  menuAriaLabel?: string
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  navigationState,
  onLocaleSelect,
  iconColor,
  ariaLabel,
  menuAriaLabel,
}) => {
  const { locales = [], currentLocale = 'fi', isBusiness = false } = navigationState

  const [isOpen, setIsOpen] = useState(false)

  const selected = currentLocale.toUpperCase()

  return (
    <StyledWrapper isDark={isBusiness}>
      <StyledNavDropdown
        id="topbar-locale-dropdown"
        ButtonIcon={() => <StyledLanguageIcon color={iconColor} />}
        aria-label={ariaLabel}
        menuAriaLabel={menuAriaLabel}
        role="listbox"
        isOpen={isOpen}
        onToggle={() => setIsOpen(!isOpen)}
        onSelect={(value) => onLocaleSelect(value.id)}
        title={toLocalizedTitle(selected)}
        overflowMenuContainerDirection={MenuOverflowDirection.left}
        withArrow={false}
        options={locales.map((locale) => ({ id: locale.toUpperCase(), title: toLocalizedTitle(locale) }))}
        NavDropdownItem={NavDropdownItem}
      />
    </StyledWrapper>
  )
}

LanguageSelector.defaultProps = {
  iconColor: 'neutralNetworkGray',
  ariaLabel: 'Language selection',
  menuAriaLabel: 'Language selection menu',
}

LanguageSelector.propTypes = {
  navigationState: NavigationStateType,
  onLocaleSelect: PropTypes.func.isRequired,
  iconColor: PropTypes.string,
  ariaLabel: PropTypes.string,
  menuAriaLabel: PropTypes.string,
}

export default LanguageSelector
