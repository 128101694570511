import styled, { css } from 'styled-components'
import { IndicatorStepProps } from './IndicatorStep'

export const StyledIndicatorStep = styled.button<Omit<IndicatorStepProps, 'tooltipTitle' | 'tooltipId' | 'placement'>>(
  ({
    theme: {
      xyz: { color },
    },
    large,
    completed,
    activated,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    border: ${activated || completed ? `3px solid ${color.signalBlue}` : `3px solid ${color.neutralPassiveGray}`};
    background: ${color.neutralPassiveGray};
    box-sizing: border-box;
    padding: 0;
    ${completed ? `background: ${color.signalBlue};` : ''}
    ${activated ? `background: ${color.neutralWhite};` : ''}

    ${large &&
    css`
      width: 1.5rem;
      height: 1.5rem;

      svg {
        width: 1.125rem;
        height: 1.125rem;
      }
    `}

    &:focus {
      outline: none;
      ${(activated || completed) &&
      css`
        box-shadow: 0 0 0.4375rem ${color.signalBlue};

        &:not(:focus-visible) {
          box-shadow: none;
        }
      `}
    }
  `
)

export const StyledLabel = styled.span`
  display: block;
`

export const StyledContainer = styled.div<{ completed: boolean; large: boolean; vertical: boolean }>(
  ({
    theme: {
      xyz: { color, spacing },
    },
    large,
    vertical,
    completed,
  }) => css`
    position: relative;
    min-width: ${large ? '10rem' : '6.5rem'};
    min-height: ${vertical ? `${large ? '6.5rem' : '3.5rem'}` : 'auto'};

    ${vertical &&
    css`
      display: flex;
    `}

    ${large &&
    css`
      &:last-child {
        min-height: auto;
      }
    `}


    &:not(:last-child):after {
      content: '';
      position: absolute;
      border-radius: 9999px;
      border: ${completed ? `1px solid ${color.signalBlue}` : `1px solid ${color.neutralPassiveGray}`};
      background-color: ${completed ? color.signalBlue : color.neutralPassiveGray};

      ${!vertical
        ? css`
            height: 1px;
            width: ${large ? `calc(100% - 1.5rem - 0.25rem)` : `calc(100% - 1rem - 0.25rem)`};
            left: ${large ? `1.625rem` : `1.125rem`};
            top: ${large ? `calc((1.5rem - 3px) / 2)` : `calc((1rem - 3px) / 2)`};
            min-width: 1rem;
            max-width: ${large ? '10rem' : '6.5rem'};
          `
        : css`
            height: ${large ? `calc(100% - 1.5rem - 0.25rem)` : `calc(100% - 1rem - 0.25rem)`};
            width: 1px;
            left: ${large ? `calc((1.5rem - 3px) / 2)` : `calc((1rem - 3px) / 2)`};
            top: ${large ? `1.625rem` : `1.125rem`};
            min-height: 1rem;
            max-height: 6.5rem;
          `}
    }

    &:last-child,
    &:last-child:after {
      min-height: auto;
    }

    ${StyledLabel} {
      margin: ${vertical ? `0 0 0 ${spacing.space3}rem` : `${spacing.space2}rem ${spacing.space2}rem 0 0`};
      max-width: ${vertical ? `15rem` : `${large ? `10rem` : `6.5rem`}`};
      ${!vertical &&
      css`
        display: flex;
        align-items: center;
      `}

      p {
        margin: 0;
      }

      label {
        display: block;
        line-height: ${vertical ? `${large ? '1.5rem' : '1rem'}` : 'auto'};
      }
    }
  `
)
