import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'

import { CheckmarkIcon } from '../../design-tokens/icons'
import { XyzTheme } from '@postidigital/posti-theme'

import { useTheme } from '../../utils/useTheme'
import { StyledBox, StyledCheck, StyledInput, StyledLabel, StyledRoot } from './Checkbox.style'

export enum CheckboxLabelPosition {
  left = 'left',
  right = 'right',
}

export interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
  value: string
  checked?: boolean
  disabled?: boolean
  large?: boolean
  label?: React.ReactNode
  labelPosition?: CheckboxLabelPosition | keyof typeof CheckboxLabelPosition
  defaultColor?: string
  hoverColor?: string
  selectedColor?: string
}

export interface Ref extends CheckboxProps {
  ref: (instance: HTMLInputElement) => void | React.RefObject<HTMLInputElement>
}

export const Checkbox = React.forwardRef<CheckboxProps, Ref & JSX.IntrinsicElements['input']>((props, ref) => {
  const { label, labelPosition, onChange, id, disabled, tabIndex, ...rest } = props
  const theme = useTheme()

  const renderCheckbox = () => (
    <StyledBox {...rest} disabled={disabled} tabIndex={null}>
      <StyledInput
        id={id}
        ref={ref}
        type="checkbox"
        {...rest}
        disabled={disabled}
        tabIndex={disabled ? -1 : tabIndex}
        onChange={onChange}
        aria-disabled={disabled}
      />
      <StyledCheck {...rest} tabIndex={-1} disabled={disabled}>
        {rest.checked && <CheckmarkIcon color={theme.xyz.color.neutralWhite} />}
      </StyledCheck>
    </StyledBox>
  )

  return (
    <StyledRoot>
      {label ? (
        <StyledLabel labelPosition={labelPosition}>
          {labelPosition === CheckboxLabelPosition.left && label}
          {renderCheckbox()}
          {labelPosition === CheckboxLabelPosition.right && label}
        </StyledLabel>
      ) : (
        renderCheckbox()
      )}
    </StyledRoot>
  )
})

Checkbox.displayName = 'Checkbox'

Checkbox.propTypes = {
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  label: PropTypes.node,
  labelPosition: PropTypes.oneOf(Object.values(CheckboxLabelPosition)),
  defaultColor: PropTypes.string,
  hoverColor: PropTypes.string,
  selectedColor: PropTypes.string,
  tabIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

Checkbox.defaultProps = {
  value: '',
  checked: false,
  disabled: false,
  large: false,
  labelPosition: CheckboxLabelPosition.left,
  defaultColor: XyzTheme.color.neutralIconGray,
  hoverColor: XyzTheme.color.neutralNetworkGray,
  selectedColor: XyzTheme.color.signalBlue,
  tabIndex: 0,
}
