import styled, { css } from 'styled-components'
import Button from '../../basic-components/Button'

export const MobileMenuContainer = styled.div<{ zIndex: number }>(
  ({ theme, zIndex }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    max-width: 23.5rem;
    background: ${theme.color.neutralWhite};
    z-index: ${zIndex || 3};
    box-shadow: rgba(27, 31, 35, 0.07) 0px 8px 22px;
    display: flex;
    flex-direction: column;
  `
)

export const MobileMenuHeader = styled.div<{ notRootPage: boolean }>(
  ({ theme: { color }, notRootPage }) => css`
    background: ${notRootPage ? color.brandBrightYellow : color.neutralGray2};
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    & > div {
      word-break: break-word;
      hyphens: auto;
    }
  `
)

export const MobileMenuHeaderButtons = styled.div<{ notRootPage: boolean }>(
  ({ notRootPage }) => css`
    width: 100%;
    display: flex;
    justify-content: ${notRootPage ? 'space-between' : 'flex-end'};
    align-items: center;
    margin-bottom: 1.625rem;
    min-height: 2.375rem;

    > button:first-child {
      left: -0.4375rem;
      position: relative;
    }

    > button:last-child {
      right: -0.4375rem;
      position: relative;
    }
  `
)

export const MobileMenuContent = styled.div`
  padding: 0.5rem;
  margin-top: 1rem;
`

export const LocaleMenu = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
`

export const LocaleMenuItem = styled(Button).attrs({ size: 'sm', mode: 'secondary' })<{ selected: boolean }>(
  ({
    theme: {
      xyz: { color, borderRadius },
    },
    selected,
  }) => css`
    margin: 0.2rem;
    color: ${selected ? color.neutralWhite : color.neutralNetworkGray};
    background-color: ${selected ? color.neutralNetworkGray : color.neutralWhite};
    border-radius: ${borderRadius.xs}rem;
    padding: 0;

    & > span {
      padding: 0.85rem;
    }

    &::before {
      border-radius: ${borderRadius.xs}rem;
    }

    &:active,
    &:focus,
    &:hover {
      color: ${color.neutralNetworkGray};

      &::before {
        background-color: ${color.neutralGray2};
        filter: brightness(92%);
      }
    }
  `
)

export const MobileMenuItem = styled.a(
  ({
    theme: {
      xyz: { color, fontWeight },
      fontSize,
      lineHeight,
    },
  }) => css`
    margin: 0;
    padding: 0;
    font-size: ${fontSize.text.xs}rem;
    font-weight: ${fontWeight.semiBold};
    color: ${color.neutralIconGray};
    &:hover,
    &:active {
      text-decoration: none;
      color: ${color.neutralBlack};
    }
    & div {
      font-size: ${fontSize.text.sm}rem;
      line-height: ${lineHeight.title.xl};
    }
  `
)

export const TopLevelMobileMenuItem = styled(MobileMenuItem)(
  ({
    theme: {
      color,
      fontSize,
      lineHeight,
      xyz: { borderRadius },
    },
  }) => css`
    color: ${color.neutralNetworkGray};
    padding: 0.7rem 0;
    &:hover,
    &:active {
      & > div {
        background: ${color.brandMutedYellow};
        border-radius: ${borderRadius.lg}rem;
      }
    }
    & > div {
      padding-left: 0.3rem;
      & > div > div {
        justify-content: space-between;
        & > div {
          position: relative;
        }
      }
    }
    & div {
      font-size: ${fontSize.text.md}rem;
      line-height: ${lineHeight.title.xs};
      white-space: normal;
    }
  `
)

export const MobileMenuBreak = styled.hr(
  ({ theme }) => css`
    border: 0.5px solid ${theme.color.neutralGray5};
  `
)
