import React, { useState, useRef } from 'react'
import PropTypes, { Validator } from 'prop-types'

import { useTheme } from '../../utils/useTheme'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import { DropdownProps } from './Dropdown.types'
import {
  StyledDropdown,
  StyledButton,
  Label,
  Title,
  StyledChevron,
  StyledDropdownContainer,
  StyledIcon,
  ContentWrapper,
} from './Dropdown.style'
import { Menu } from '../Menu'
import { randomId } from '../../utils/helpers'
import { UIMessage } from '../UIMessage'
import OutsideClick from '../OutsideClick'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'

const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    isOpen,
    title,
    label,
    id,
    onToggle,
    onSelect,
    disabled,
    children,
    isValid,
    message,
    zIndex,
    lightBackground,
    menuId,
    multiselect,
    menuAriaLabel,
    overflowMenuContainer,
    overflowMenuContainerDirection,
    icon: Icon,
    iconColor,
    isOpenUpward,
    maxMenuHeight,
    ...rest
  } = props
  const theme = useTheme()
  const [fallbackId] = useState(randomId())

  const menuRef = useRef<HTMLUListElement>()
  const buttonRef = useRef<HTMLButtonElement>()

  const isLabelShrinked = isOpen || Boolean(title)
  const isLabelVisible = !title

  const handleOpen = () => {
    if (!isOpen) {
      onToggle()
      const firstChild = menuRef.current.children[0] as HTMLElement

      if (firstChild && typeof firstChild.focus === 'function') {
        firstChild.focus()
      }
    }
  }

  const handleClose = () => {
    if (isOpen) {
      onToggle()

      if (buttonRef && buttonRef.current) {
        buttonRef.current.focus()
      }
    }
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === KeyboardKeys.Enter || e.key === KeyboardKeys.Space) {
      e.preventDefault()
      handleOpen()
    }
  }

  const handleSelect = (value: string) => {
    if (!multiselect) {
      handleClose()
    }

    onSelect(value)
  }

  return (
    <OutsideClick onOutsideClick={handleClose}>
      <StyledDropdown>
        <StyledDropdownContainer>
          <StyledButton
            type="button"
            id={id || fallbackId}
            ref={buttonRef}
            aria-haspopup="listbox"
            aria-expanded={isOpen}
            isOpen={isOpen}
            onClick={isOpen ? handleClose : handleOpen}
            onKeyPress={onKeyPress}
            disabled={disabled}
            lightBackground={lightBackground}
            isValid={isValid}
            {...rest}
          >
            {!!Icon && (
              <StyledIcon
                as={Icon}
                color={disabled ? theme.xyz.color.neutralPassiveGray : iconColor}
                aria-hidden={true}
              />
            )}
            <ContentWrapper hasIcon={!!Icon} isShrinked={isLabelShrinked}>
              <Label isShrinked={isLabelShrinked} htmlFor={id || fallbackId} disabled={disabled} hasIcon={!!Icon}>
                {label}
              </Label>
              {title && <Title disabled={disabled}>{title}</Title>}
            </ContentWrapper>
            <StyledChevron
              isOpen={isOpen}
              width="100%"
              height="100%"
              color={disabled ? theme.color.neutralPassiveGray : theme.color.neutralNetworkGray}
            />
          </StyledButton>
          <Menu
            id={menuId}
            isOpen={isOpen}
            onClose={handleClose}
            exclusions={[id || fallbackId]}
            aria-multiselectable={multiselect}
            zIndex={zIndex}
            aria-label={menuAriaLabel}
            ref={menuRef}
            overflowMenuContainer={overflowMenuContainer}
            overflowMenuContainerDirection={overflowMenuContainerDirection}
            isInDropdown
            isOpenUpward={isOpenUpward}
            maxMenuHeight={maxMenuHeight}
          >
            {React.Children.map(children, (child) =>
              React.cloneElement(child as React.ReactElement<any>, { onSelect: handleSelect })
            )}
          </Menu>
        </StyledDropdownContainer>
        {message && <UIMessage success={isValid} message={message} />}
      </StyledDropdown>
    </OutsideClick>
  )
}

Dropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.string,
  id: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  message: PropTypes.string,
  zIndex: PropTypes.number,
  lightBackground: PropTypes.bool,
  menuId: PropTypes.string,
  multiselect: PropTypes.bool,
  menuAriaLabel: PropTypes.string.isRequired,
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  iconColor: PropTypes.string,
  isOpenUpward: PropTypes.bool,
}

Dropdown.defaultProps = {
  title: '',
  disabled: false,
  isOpen: false,
  zIndex: 400,
  lightBackground: true,
  multiselect: false,
  isValid: true,
  isOpenUpward: false,
}

export { Dropdown }
