import styled, { css } from 'styled-components'

export const StyledTabs = styled.div<{ vertical: boolean }>(
  ({ theme, vertical }) => css`
    display: flex;
    position: relative;
    margin-bottom: ${theme.xyz.spacing.space4}rem;
    flex-direction: ${vertical ? 'column' : 'row'};
  `
)

export const StyledTabList = styled.div`
  position: relative;
`

export const StyledTabIndicator = styled.div<{ vertical: boolean }>`
  display: inline-block;
  position: absolute;
  height: 0.1875rem;
  border-radius: 2px 2px 0px 0px;
  bottom: -0.0625rem;
  width: 100%;
  background-color: ${({ theme }) => theme.xyz.color.neutralNetworkGray};
  transition: top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  ${({ vertical }) =>
    vertical &&
    css`
      height: 100%;
      width: 0.1875rem;
      right: 0;
    `};
`
