import styled, { css } from 'styled-components'

import { CheckmarkCircledIcon } from '../../design-tokens/icons'
import { RadioLabelPosition } from './Radio'

export const StyledRadio = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.1875rem solid ${theme.xyz.color.neutralIconGray};
    border-radius: 50%;
    background-color: ${theme.xyz.color.neutralWhite};
    opacity: 1;
    transition: all 100ms ease-in;
  `
)

export const StyledSuccessIcon = styled(CheckmarkCircledIcon)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: calc(100% + 0.7rem);
  height: calc(100% + 0.7rem);
`

export const StyledInput = styled.input(
  ({
    theme: {
      xyz: { zIndex },
    },
  }) => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: ${zIndex.zIndex10};
    opacity: 0;
  `
)

export const StyledIconWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 100ms ease-in;
    background-color: ${theme.xyz.color.neutralWhite};
    border-radius: 50%;
  `
)

export const StyledContainer = styled.div<{ small: boolean; success: boolean; isInvalid: boolean }>(
  ({ theme, small, success, isInvalid }) => css`
    position: relative;
    width: ${small ? 1.5 : 2}rem;
    height: ${small ? 1.5 : 2}rem;
    display: inline-block;
    vertical-align: middle;
    user-select: none;

    ${StyledInput}:not(:disabled) {
      cursor: pointer;
    }

    /* Focus styles */

    ${StyledInput}:not([disabled]):checked:focus ~ ${StyledRadio} {
      border-color: ${theme.xyz.color.signalBlue};
    }

    ${StyledInput}:not([disabled]):focus ~ ${StyledRadio},
    ${StyledInput}:not([disabled]):focus ~ ${StyledIconWrapper} {
      box-shadow: 0 0 0.4375rem ${theme.xyz.color.signalBlue};
    }

    ${StyledInput}:focus:not(:focus-visible) ~ ${StyledRadio}, 
    ${StyledInput}:focus:not(:focus-visible) ~ ${StyledIconWrapper} {
      outline: none;
      box-shadow: none;
    }

    /* Active styles */
    ${!success &&
    `
      ${StyledInput}:checked ~ ${StyledRadio} {
        border: ${small ? 0.5 : 0.625}rem solid ${theme.xyz.color.signalBlue};
      }
    `}

    /* Hover styles */
    ${StyledInput}:not([disabled]):hover ~ ${StyledRadio} {
      border-color: ${theme.xyz.color.neutralNetworkGray};
    }

    ${StyledInput}:not([disabled]):checked:hover ~ ${StyledRadio} {
      border-color: ${theme.xyz.color.signalBlue};
    }

    ${success &&
    !isInvalid &&
    `
      ${StyledInput}:checked ~ ${StyledRadio} {
        opacity: 0;
      }
  
      ${StyledInput}:checked ~ ${StyledIconWrapper} {
        opacity: 1;
      }
    `}

    /* Disabled styles */
    ${StyledInput}:disabled ~ ${StyledRadio},
    ${StyledInput}:disabled ~ ${StyledSuccessIcon} {
      border-color: ${theme.xyz.color.neutralGray5};
    }

    ${isInvalid &&
    `
      ${StyledInput} ~ ${StyledRadio},
      ${StyledInput}:checked ~ ${StyledRadio} {
        border: 0.1875rem solid ${theme.xyz.color.signalRed};
      }
    `}
  `
)

export const StyledLabel = styled.label<{ labelPosition: RadioLabelPosition }>(
  ({ theme, labelPosition }) => css`
    ${labelPosition === RadioLabelPosition.left &&
    `
    ${StyledContainer} {
      margin-left: 3.875rem;
    }
  `}

    ${labelPosition === RadioLabelPosition.right &&
    `
    ${StyledContainer} {
      margin-right: ${theme.xyz.spacing.space3}rem;
    }
  `}
  `
)

export const StyledRoot = styled.div`
  display: inline-block;
`
