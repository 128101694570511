import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { useTheme } from '../../utils/useTheme'
import { ChevronDownIcon } from '../../design-tokens/icons'
import { Menu } from '../Menu/Menu'
import { randomId } from '../../utils/helpers'
import { ToolButton, ToolButtonProps, getToolButtonIconColor } from '../ToolButton/ToolButton'

export interface ToolDropdownProps extends Omit<ToolButtonProps, 'onClick' | 'text'> {
  text: string
  onMenuItemSelect: (value: any) => void
  isMenuOpen: boolean
  onToggleMenu: () => void
  onClick?: (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void
  menuZIndex?: number
  menuMultiselect?: boolean
  menuAriaLabel: string
  isOpenUpward?: boolean
}

const Container = styled.div`
  position: relative;
  display: inline-block;
`

const StyledMenu = styled(Menu)(
  ({ theme }) => css`
    max-height: 60vh;
    overflow-y: auto;
    width: auto;
    span {
      margin-right: ${theme.xyz.spacing.space3}rem;
    }
  `
)

const StyledToolButton = styled(ToolButton)`
  & > span {
    padding: 0.75rem 0.5rem;
  }
`

const StyledChevron = styled(ChevronDownIcon)<{ isMenuOpen: boolean }>(
  ({ theme, isMenuOpen }) => css`
    margin-left: ${theme.xyz.spacing.space3}rem;
    pointer-events: none;
    transform: rotate(${isMenuOpen ? '-180deg' : '0deg'});
    transition: all 100ms ease;
  `
)

const ToolDropdown: React.FC<ToolDropdownProps> = ({
  selected,
  disabled,
  id,
  children,
  menuZIndex,
  menuMultiselect,
  onClick: onCustomClick,
  onMenuItemSelect,
  isMenuOpen,
  onToggleMenu,
  menuAriaLabel,
  isOpenUpward,
  ...rest
}) => {
  const theme = useTheme()
  const [fallbackId] = useState(randomId())
  const menuRef = useRef<HTMLUListElement>()
  const buttonRef = useRef<HTMLButtonElement>()
  const iconProps = {
    'aria-hidden': true,
    width: `${theme.xyz.iconSize.s}em`,
    height: `${theme.xyz.iconSize.s}em`,
    color: getToolButtonIconColor({ selected, disabled }, theme),
  }

  const handleMenuOpen = () => {
    if (!isMenuOpen) {
      onToggleMenu()

      const firstChild = menuRef.current.children[0] as HTMLElement
      if (firstChild && typeof firstChild.focus === 'function') {
        firstChild.focus()
      }
    }
  }

  const handleMenuClose = () => {
    if (isMenuOpen) {
      onToggleMenu()

      if (buttonRef && buttonRef.current) {
        buttonRef.current.focus()
      }
    }
  }

  const onClick = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    if (isMenuOpen) {
      handleMenuClose()
    } else {
      handleMenuOpen()
    }
    if (onCustomClick) {
      onCustomClick(e)
    }
  }

  const handleMenuSelect = (value: string) => {
    if (!menuMultiselect) {
      handleMenuClose()
    }

    onMenuItemSelect(value)
  }

  return (
    <Container>
      <StyledToolButton
        {...rest}
        disabled={disabled}
        selected={selected}
        id={id || fallbackId}
        ref={buttonRef}
        aria-haspopup="listbox"
        aria-expanded={isMenuOpen}
        onClick={onClick}
      >
        <StyledChevron isMenuOpen={isMenuOpen} {...iconProps} />
      </StyledToolButton>
      <StyledMenu
        isOpen={isMenuOpen}
        onClose={handleMenuClose}
        exclusions={[id || fallbackId]}
        aria-multiselectable={menuMultiselect}
        zIndex={menuZIndex}
        aria-label={menuAriaLabel}
        ref={menuRef}
        isInDropdown
        isOpenUpward={isOpenUpward}
      >
        {React.Children.map(children, (child) =>
          React.cloneElement(child as React.ReactElement<any>, { onSelect: handleMenuSelect })
        )}
      </StyledMenu>
    </Container>
  )
}

ToolDropdown.propTypes = {
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  menuZIndex: PropTypes.number,
  menuMultiselect: PropTypes.bool,
  onMenuItemSelect: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  onToggleMenu: PropTypes.func.isRequired,
  menuAriaLabel: PropTypes.string.isRequired,
  isOpenUpward: PropTypes.bool,
}

ToolDropdown.defaultProps = {
  selected: false,
  disabled: false,
  menuMultiselect: false,
  isOpenUpward: false,
}

export { ToolDropdown }
