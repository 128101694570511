import React, { useRef } from 'react'
import PropTypes, { Validator } from 'prop-types'
import { NavDropdownProps, NavDropdownOption } from './NavDropdown.types'
import { StyledDropdown } from './NavDropdown.style'
import { Menu } from '../Menu'
import { PostiTheme } from '@postidigital/posti-theme'
import { NavButton, NavButtonMode } from '../NavButton'
import {
  handleAction,
  handleDropdownClose,
  getOptionElements,
  handleDropdownOpen,
  handleItemAction,
  focusOnButton,
} from './utils/dropdownKeyboardHandling'
import NavKeyboardUtils from '../../utils/navKeyboardNavigation'
import { MenuOverflowDirection } from '../Menu/Menu'

const NavDropdown: React.FC<NavDropdownProps> = ({
  isOpen,
  mode = NavButtonMode.secondary,
  title,
  id,
  onToggle,
  onSelect,
  children,
  arrowColor = PostiTheme.color.neutralNetworkGray,
  buttonSelectedColor = PostiTheme.color.neutralNetworkGray,
  transitionDuration = 100,
  overflowMenuContainerDirection,
  selected,
  ButtonIcon,
  menuId,
  withArrow,
  NavDropdownItem,
  options,
  menuAriaLabel,
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const handleClose = () => {
    if (isOpen) {
      onToggle()
    }
  }

  const onItemClickHandler =
    (option: NavDropdownOption, customClickFn?: React.MouseEventHandler): React.MouseEventHandler =>
    (e) => {
      handleClose()
      focusOnButton(containerRef.current, id)

      if (onSelect) {
        onSelect(option)
      }
      if (customClickFn) {
        customClickFn(e)
      }
    }

  const onItemKeyboardHandler =
    (customKeyboardFn?: React.KeyboardEventHandler) => (e: React.KeyboardEvent<HTMLElement>) => {
      e.preventDefault()
      if (customKeyboardFn) {
        customKeyboardFn(e)
      }

      handleItemAction(e)

      if (containerRef && containerRef.current) {
        handleDropdownClose(e, containerRef.current, handleClose, id)

        const optionsList = Array.from(getOptionElements(containerRef.current, id))
        NavKeyboardUtils.handleHomeEnd(e, optionsList)
        NavKeyboardUtils.handleCharacterSearch(e, optionsList)
        NavKeyboardUtils.handleVerticalArrows(e, optionsList)
        e.stopPropagation()
      }
    }

  const onButtonClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    onToggle()

    const { onClick } = rest
    if (onClick) {
      onClick(e)
    }
  }

  const onButtonKeyboardHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    handleAction(e)
    handleDropdownOpen(e, containerRef && containerRef.current, id)
    const { onKeyDown } = rest
    if (onKeyDown) {
      onKeyDown(e)
    }
  }

  return (
    <StyledDropdown ref={containerRef}>
      <NavButton
        isOpen={isOpen}
        mode={mode}
        title={title}
        selectedColor={buttonSelectedColor}
        selected={selected}
        arrowColor={arrowColor}
        Icon={ButtonIcon}
        withArrow={withArrow}
        role="menuitem"
        aria-haspopup
        aria-expanded={isOpen}
        {...rest}
        id={id}
        onKeyDown={onButtonKeyboardHandler}
        onClick={onButtonClickHandler}
      />
      <Menu
        id={menuId}
        isOpen={isOpen}
        onClose={handleClose}
        exclusions={[id]}
        transitionDuration={transitionDuration}
        overflowMenuContainer={true}
        overflowMenuContainerDirection={overflowMenuContainerDirection}
        role="menu"
        aria-label={menuAriaLabel}
        isInDropdown
      >
        {options.map((option) => {
          const { id, title, props: optProps = {} } = option
          return (
            <li role="none" key={id}>
              <NavDropdownItem
                role="menuitem"
                aria-label={title}
                id={id}
                tabIndex={isOpen ? 0 : -1}
                title={title}
                {...optProps}
                onClick={onItemClickHandler(option, optProps.onClick)}
                onKeyDown={onItemKeyboardHandler(optProps.onKeyDown)}
              />
            </li>
          )
        })}
      </Menu>
    </StyledDropdown>
  )
}

NavDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(Object.values(NavButtonMode)),
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  arrowColor: PropTypes.string,
  buttonSelectedColor: PropTypes.string,
  transitionDuration: PropTypes.number,
  overflowMenuContainerDirection: PropTypes.oneOf(Object.values(MenuOverflowDirection)),
  selected: PropTypes.bool,
  menuId: PropTypes.string,
  withArrow: PropTypes.bool,
  NavDropdownItem: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
      to: PropTypes.string,
      props: PropTypes.object,
    }).isRequired as Validator<NavDropdownOption>
  ).isRequired,
  menuAriaLabel: PropTypes.string.isRequired,
}

NavDropdown.defaultProps = {
  mode: NavButtonMode.secondary,
  isOpen: false,
  arrowColor: PostiTheme.color.neutralNetworkGray,
  buttonSelectedColor: PostiTheme.color.neutralNetworkGray,
  overflowMenuContainerDirection: MenuOverflowDirection.right,
  transitionDuration: 100,
  selected: false,
  withArrow: true,
}

export { NavDropdown }
