import { SideMenuProps } from './SideMenu.types'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

/**
 * @deprecated Component doesn't bring any value and isn't used anywhere
 */

export const SideMenu = styled.ul<SideMenuProps>(
  ({
    theme: {
      xyz: { spacing, color },
    },
    width,
  }) => css`
    padding: ${spacing.space3}rem;
    max-width: 18.75rem;
    width: ${width ?? '100%'};
    background-color: ${color.backgroundExceptionalGray};

    & > li {
      margin: ${spacing.space1}rem;
    }
  `
)

SideMenu.propTypes = {
  width: PropTypes.string,
}
