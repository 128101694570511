import React, { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'

import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { XyzTheme } from '@postidigital/posti-theme'

export interface ActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: React.ComponentType<DefaultIconProps>
  iconColor?: string
  backgroundColor?: string
  hoverBackgroundColor?: string
  pressBackgroundColor?: string
  'aria-label': string
}

const StyledButton = styled.button<Omit<ActionButtonProps, 'icon'>>`
  ${({ theme, backgroundColor, hoverBackgroundColor, pressBackgroundColor }) => css`
    cursor: pointer;
    border-radius: 50%;
    border: none;
    transition: all 100ms ease-in;
    box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);
    background-color: ${theme.xyz.color[backgroundColor] || backgroundColor};
    padding: 0;

    &:hover {
      background-color: ${theme.xyz.color[hoverBackgroundColor] || hoverBackgroundColor};
    }

    &:active {
      background-color: ${theme.xyz.color[pressBackgroundColor] || pressBackgroundColor};
    }

    &:focus {
      outline: 0;
      filter: drop-shadow(0px 0px 3px ${theme.xyz.color.signalBlue});
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      color: ${theme.xyz.color.neutralPassiveGray};
      background-color: ${theme.xyz.color.neutralGray5};
    }
  `}
`

export const FocusInner = styled.span`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: ${theme.xyz.spacing.space3}rem;
  `}
`

export const ActionButton: React.FC<ActionButtonProps> = ({ icon: Icon, iconColor, disabled, ...rest }) => {
  return (
    <StyledButton {...rest} disabled={disabled} aria-disabled={disabled} tabIndex={0}>
      <FocusInner tabIndex={-1}>
        <Icon color={disabled ? XyzTheme.color.neutralPassiveGray : iconColor} />
      </FocusInner>
    </StyledButton>
  )
}

ActionButton.propTypes = {
  icon: PropTypes.elementType.isRequired as Validator<React.ComponentType<DefaultIconProps>>,
  iconColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  pressBackgroundColor: PropTypes.string,
  'aria-label': PropTypes.string.isRequired,
}

ActionButton.defaultProps = {
  iconColor: XyzTheme.color.signalBlue,
  backgroundColor: XyzTheme.color.neutralWhite,
  hoverBackgroundColor: XyzTheme.color.neutralOnHoverGray,
  pressBackgroundColor: XyzTheme.color.neutralOnPressGray,
}
