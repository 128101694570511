import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '../../utils/useTheme'
import { StyledIndicatorStep, StyledContainer, StyledLabel } from './IndicatorStep.styles'
import { CheckmarkIcon } from '../../design-tokens/icons'
import Tooltip from '../Tooltip'
import { Placement } from '../Tooltip/Tooltip'

export interface IndicatorStepProps {
  completed?: boolean
  activated?: boolean
  optional?: boolean
  vertical?: boolean
  large?: boolean
  tooltipTitle?: React.ReactNode
  tooltipId?: string
  tooltipPlacement?: keyof typeof Placement
}

export const IndicatorStep: React.FC<IndicatorStepProps> = ({
  completed,
  activated,
  large,
  vertical,
  tooltipTitle,
  tooltipId,
  tooltipPlacement,
  children,
}) => {
  const theme = useTheme()

  const indicatorStep = (
    <StyledIndicatorStep completed={completed} activated={activated} large={large} vertical={vertical}>
      {!!completed && <CheckmarkIcon width="0.625rem" height="0.625rem" color={theme.xyz.color.neutralWhite} />}
    </StyledIndicatorStep>
  )

  return (
    <StyledContainer completed={completed} large={large} vertical={vertical} data-design-system-id="indicator-step">
      {tooltipTitle ? (
        <Tooltip id={tooltipId} title={tooltipTitle} placement={tooltipPlacement}>
          {indicatorStep}
        </Tooltip>
      ) : (
        indicatorStep
      )}
      {!!children && <StyledLabel>{children}</StyledLabel>}
    </StyledContainer>
  )
}

IndicatorStep.propTypes = {
  completed: PropTypes.bool,
  activated: PropTypes.bool,
  vertical: PropTypes.bool,
  optional: PropTypes.bool,
}

IndicatorStep.defaultProps = {
  completed: false,
  activated: false,
  optional: false,
  vertical: false,
}
