import React from 'react'
import { ChevronDownIcon, ChevronRightIcon } from '../../design-tokens/icons'
import PropTypes, { Validator } from 'prop-types'
import { XyzTheme } from '@postidigital/posti-theme'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { useTheme } from '../../utils/useTheme'

import { MenuItemProps } from './MenuItem.types'
import { HeadlineSize, LabelSize } from '../../design-tokens/typography'
import {
  MenuItemWrapper,
  StyledButton,
  StyledIcon,
  StyledLabel,
  StyledMenuItem,
  StyledSubtitle,
} from './MenuItem.style'
import { getColor } from './utils'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import { FocusInner } from '../ActionButton'

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  const {
    icon: Icon,
    redirectsToSubMenu,
    label,
    disabled,
    selected,
    onSelect,
    onButtonClick,
    role,
    id,
    open,
    hasSubItems,
    subtitle,
    isSubItem,
    value,
    chevronAriaLabel,
    ...rest
  } = props
  const theme = useTheme()
  const hasIcon = !!Icon
  const hasButton = redirectsToSubMenu || hasSubItems
  const iconProps = {
    width: `${theme.xyz.iconSize.s}em`,
    height: `${theme.xyz.iconSize.s}em`,
    color: getColor({ selected, disabled }),
  }

  const handleClick = () => {
    onSelect(value)
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === KeyboardKeys.Enter || e.key === KeyboardKeys.Space) {
      e.preventDefault()

      onSelect(value)
    }
  }

  const handleButtonKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === KeyboardKeys.Enter || e.key === KeyboardKeys.Space) {
      e.preventDefault()
      e.stopPropagation()
      onButtonClick(value)
    }
  }

  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onButtonClick(value)
  }

  const menuItemWithButton = hasButton && !isSubItem

  const LabelComponent = (
    <StyledLabel hasIcon={hasIcon} size={HeadlineSize.Seven} disabled={disabled} selected={selected}>
      {label}
    </StyledLabel>
  )

  return (
    <StyledMenuItem
      {...rest}
      hasSubItems={hasSubItems}
      role={role}
      tabIndex={0}
      selected={selected}
      aria-disabled={disabled}
      disabled={disabled}
      hasIcon={hasIcon}
      onKeyDown={handleKeyPress}
      redirectsToSubMenu={redirectsToSubMenu}
      id={id}
      onClick={handleClick}
      isSubItem={isSubItem}
    >
      <FocusInner tabIndex={-1}>
        {hasIcon && <StyledIcon {...iconProps} as={Icon} aria-hidden={true} />}
        <MenuItemWrapper hasIcon={hasIcon} hasButton={hasButton}>
          {!!subtitle ? (
            <>
              {LabelComponent}
              <StyledSubtitle size={LabelSize.Six} hasIcon={hasIcon} disabled={disabled} selected={selected}>
                {subtitle}
              </StyledSubtitle>
            </>
          ) : (
            LabelComponent
          )}
        </MenuItemWrapper>
        {menuItemWithButton && (
          <StyledButton
            aria-disabled={disabled}
            hasSubItems={hasSubItems}
            open={open}
            onKeyDown={handleButtonKeyPress}
            onClick={handleButtonClick}
            aria-label={chevronAriaLabel}
            icon={hasSubItems ? ChevronDownIcon : ChevronRightIcon}
            iconColor={
              disabled
                ? XyzTheme.color.neutralPassiveGray
                : selected
                ? XyzTheme.color.neutralNetworkGray
                : XyzTheme.color.neutralIconGray
            }
          />
        )}
      </FocusInner>
    </StyledMenuItem>
  )
}

MenuItem.propTypes = {
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  hasSubItems: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  chevronAriaLabel: PropTypes.string,
  redirectsToSubMenu: PropTypes.bool,
  open: PropTypes.bool,
  subtitle: PropTypes.string,
  isSubItem: PropTypes.bool,
  role: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClick: PropTypes.func,
}

MenuItem.defaultProps = {
  open: false,
  selected: false,
  disabled: false,
  role: 'menuitem',
  isSubItem: false,
}
