import styled, { css } from 'styled-components'

import { NavButtonProps } from './NavButton.types'
import { ChevronDownIcon } from '../../design-tokens/icons'
import { PostiTheme } from '@postidigital/posti-theme'

const colors = {
  primary: {
    color: PostiTheme.color.neutralWhite,
    colorHover: PostiTheme.color.neutralWhite,
    background: PostiTheme.color.signalBlue,
    backgroundHover: '#007ee6',
  },
  secondary: {
    color: PostiTheme.color.neutralNetworkGray,
    colorHover: PostiTheme.color.signalBlue,
    background: PostiTheme.color.neutralWhite,
    backgroundHover: PostiTheme.color.neutralGray2,
  },
}

export const StyledButton = styled.button<Pick<NavButtonProps, 'isOpen' | 'mode' | 'selectedColor'>>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 0;
  text-align: center;
  user-select: none;
  margin: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  text-decoration: none !important;
  cursor: pointer;
  height: 100%;
  flex-wrap: nowrap;

  /* customize */
  ${({ theme, isOpen, mode, selectedColor }) => css`
    padding: 0;
    font-family: ${theme.fontFamily.primary};
    color: ${colors[mode].color};
    background-color: ${isOpen ? colors[mode].backgroundHover : colors[mode].background};

    &:hover,
    &:focus,
    &:active {
      outline: 0;
      background-color: ${colors[mode].backgroundHover};
      color: ${colors[mode].colorHover};
    }

    &:focus {
      box-shadow: inset 0 0 0 2px ${theme.color.signalBlue};
    }

    &:disabled {
      cursor: default;
    }

    ${mode === 'secondary' &&
    css`
      &.selected {
        background-color: ${colors[mode].backgroundHover};
        border-top: 0.1875em solid transparent;
        border-bottom: 0.1875em solid ${selectedColor};
      }
    `}
  `}
`

export const StyledChevron = styled(ChevronDownIcon)(
  ({ theme }) => css`
    width: ${theme.size.icon.s}em;
    height: ${theme.size.icon.s}em;
    margin-left: 0.5em;
    margin-right: -0.25em;
    flex-shrink: 0;
  `
)

export const Title = styled.span(
  ({ theme }) => css`
    display: block;
    padding: 0;
    margin: 0;
    overflow: hidden;
    white-space: pre-wrap;
    pointer-events: none;

    font-weight: ${theme.fontWeight.semiBold};
    font-size: ${theme.fontSize.text.md}em;
  `
)

export const FocusInner = styled.span`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.375em 0.75em;
`
