import React, { HTMLAttributes, useEffect } from 'react'
import PropTypes from 'prop-types'

import OutsideClick from '../OutsideClick'
import { nextItem, previousItem, ownerDocument, moveFocus, KeyboardKeys } from '../../utils/keyboardNavigation'
import { StyledButton, StyledContainer, StyledFilterList, StyledGroupLabel } from './FilterSelection.style'
import { Headline, HeadlineSize } from '../../design-tokens/typography'
import { Mode, Size } from '../Button/Button'

export interface FilterSelectionProps extends HTMLAttributes<HTMLDivElement> {
  id: string
  isOpen: boolean
  onOutsideClick: (e: Event) => void
  onClose: React.MouseEventHandler
  closeText: string
}

const FilterSelectionHeading: React.FC = ({ children, ...rest }) => (
  <StyledGroupLabel {...rest}>
    <Headline as="span" size={HeadlineSize.Seven}>
      {children}
    </Headline>
  </StyledGroupLabel>
)

const FilterSelection: React.FC<FilterSelectionProps> = ({
  isOpen,
  onOutsideClick,
  children,
  closeText,
  onClose,
  ...rest
}) => {
  const optionsRef = React.useRef<HTMLDivElement>()

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!isOpen) {
      return
    }

    const key = e.key
    const list = optionsRef.current
    const currentFocus = ownerDocument(list).activeElement

    if (key === KeyboardKeys.ArrowDown || key === KeyboardKeys.Home) {
      e.preventDefault()
      moveFocus(list, currentFocus, true, nextItem)
    } else if (key === KeyboardKeys.ArrowUp || key === KeyboardKeys.End) {
      e.preventDefault()
      moveFocus(list, currentFocus, true, previousItem)
    } else if (key === KeyboardKeys.Escape) {
      e.preventDefault()
    }
  }

  const outsideClick = (e: Event) => {
    if (!isOpen) {
      return
    }

    onOutsideClick(e)
  }

  useEffect(() => {
    // focus on first filter button on open
    if (isOpen) {
      const list = optionsRef.current
      moveFocus(list, list.children[0], true, nextItem)
    }
  }, [isOpen])

  return (
    <StyledContainer>
      {isOpen && (
        <OutsideClick onOutsideClick={outsideClick}>
          <StyledFilterList
            aria-multiselectable={true}
            role={'listbox'}
            onKeyDown={handleKeyDown}
            ref={optionsRef}
            {...rest}
          >
            {children}
            <StyledButton mode={Mode.secondary} size={Size.sm} onClick={onClose}>
              {closeText}
            </StyledButton>
          </StyledFilterList>
        </OutsideClick>
      )}
    </StyledContainer>
  )
}

FilterSelection.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
  closeText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export { FilterSelection, FilterSelectionHeading }
