import styled from 'styled-components'

export const StyledDropdown = styled.div`
  display: inline-block;
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;
`
