import styled, { css } from 'styled-components'
import { Headline } from '../../design-tokens/typography'
import { LocationButton, RootMenuItem } from '../TopBar/TopBar.styles'
import { IconButton } from '../../basic-components/IconButton'

export const Container = styled.div<{ zIndex: number }>(
  ({
    theme: {
      xyz: { breakpoint, color },
    },
    zIndex,
  }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background: ${color.neutralWhite};
    z-index: ${zIndex || 3};
    box-shadow: rgba(27, 31, 35, 0.07) 0px 8px 22px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${breakpoint.sm}) {
      max-width: 30rem;
    }
  `
)

export const Header = styled.div`
  & > div {
    word-break: break-word;
    hyphens: auto;
    justify-content: flex-end;
  }
`

export const HeaderButtons = styled.div<{ isDark?: boolean }>(
  ({
    isDark,
    theme: {
      xyz: { spacing, color },
    },
  }) => css`
    width: 100%;
    min-height: ${spacing.space6}rem;
    padding: ${spacing.space3}rem;
    border-bottom: 1px solid ${color.neutralOnPressGray};
    background-color: ${isDark ? color.neutralNetworkGray : 'transparent'};
  `
)

export const HeaderTitle = styled.div<{ notRootPage?: boolean }>(
  ({
    theme: {
      xyz: { color, spacing },
    },
  }) => css`
    padding: ${spacing.space4}rem ${spacing.space5}rem;
    background-color: ${color.neutralGray2};
  `
)

export const Content = styled.ul(
  ({
    theme: {
      xyz: { spacing, color, fontWeight },
    },
  }) => css`
    padding: ${spacing.space3}rem;
    width: 100%;
    background-color: ${color.neutralWhite};

    & > li {
      margin: ${spacing.space1}rem;
      margin-bottom: ${spacing.space1}rem;
    }

    ${LocationButton} {
      display: block;
      color: ${color.neutralNetworkGray};
      margin: ${spacing.space5}rem ${spacing.space1}rem ${spacing.space1}rem;
      font-weight: ${fontWeight.semiBold};
      border-radius: 2.5rem;

      span {
        padding: ${spacing.space3}rem;
      }

      svg {
        margin-right: ${spacing.space3}rem;
        path {
          fill: ${color.neutralNetworkGray};
        }
      }

      &:active {
        background-color: ${color.neutralOnPressGray};
      }

      &:hover {
        text-decoration: none;
        background-color: ${color.neutralOnHoverGray};
      }

      &:focus {
        outline: none;
        box-shadow: 0px 0px 7px ${color.signalBlue};
      }
    }
  `
)

export const Break = styled.hr(
  ({
    theme: {
      xyz: { color },
    },
  }) => css`
    border: none;
    border-bottom: 1px solid ${color.neutralOnPressGray};
  `
)

export const StyledHeadline = styled(Headline)`
  ${({ theme }) => css`
    color: ${theme.xyz.color.neutralBlack};
    word-break: break-word;
  `}
`

export const PersonalIconButton = styled(IconButton)`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    margin-bottom: ${spacing.space3}rem;
    margin-left: -${spacing.space1}rem;

    svg {
      margin-right: ${spacing.space1}rem;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border-color: transparent;
    }
  `}
`

export const RootMenu = styled.div`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    @media screen and (min-width: 400px) {
      display: flex;
      align-items: center;
      margin-top: ${spacing.space4}rem;
    }
  `}
`

export const StyledRootMenuItem = styled(RootMenuItem)`
  ${({
    theme: {
      xyz: { spacing, color },
    },
    isActive,
    isDark,
  }) => css`
    display: flex;
    margin: 0 0 ${spacing.space2}rem;

    & > a {
      display: block;
      ${isActive &&
      css`
        background-color: ${isDark ? color.brandBrightYellow : color.neutralNetworkGray};
        color: ${isDark ? color.neutralNetworkGray : color.neutralWhite};

        &:hover {
          color: ${isDark ? color.neutralBlack : color.neutralWhite};
        }

        &:active {
          color: ${isDark ? color.neutralBlack : color.neutralWhite};
        }

        &:focus {
          color: ${isDark ? color.neutralNetworkGray : color.neutralWhite};
        }
      `}

      span {
        padding: ${spacing.space1}rem ${spacing.space3}rem;
      }
    }

    @media screen and (min-width: 400px) {
      margin: 0 ${spacing.space2}rem 0 0;
    }

    &:last-child {
      margin: 0;
    }
  `}
`

export const StyledWrapperLanguageSelection = styled.div`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    margin: ${spacing.space1}rem;
  `}
`

export const CloseButtonPositioning = styled.div<{ isDark?: boolean }>`
  ${({
    isDark,
    theme: {
      xyz: { color },
    },
  }) => css`
    margin-left: auto;
    width: 2.125rem;

    button {
      &:hover,
      &:focus,
      &:active {
        background-color: ${isDark ? color.neutralIconGray : color.neutralOnPressGray};
        border-color: transparent;
      }

      &:focus {
        box-shadow: 0px 0px 7px ${isDark ? color.brandBrightYellow : color.signalBlue};
        border: 1px solid ${isDark ? color.brandBrightYellow : 'transparent'};
        background-color: ${isDark ? 'transparent' : color.neutralOnPressGray};
      }
    }
  `}
`
