import React from 'react'
import { Path, SVG } from '../icons/icons.styled'
import { DefaultIconProps } from '../icons/icons.types'

export interface DesignSystemLogoProps extends DefaultIconProps {
  primaryColor: string
  secondaryColor: string
}

const DesignSystemLogo: React.FC<DesignSystemLogoProps> = ({
  primaryColor,
  secondaryColor,
  width = '126',
  height = '54',
  viewBox = '0 0 126 54',
  ...props
}) => (
  <SVG width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path fillRule="evenodd" clipRule="evenodd" d="M14 0H0V14V40H14V14L40 14V0H14Z" fill={primaryColor} />
    <Path fillRule="evenodd" clipRule="evenodd" d="M40 54H14V40L40 40L40 14H54L54 40V54H40Z" fill={secondaryColor} />
    <Path
      d="M70.6385 2C71.8722 2 72.7834 2.34768 73.372 3.04303C73.9719 3.73839 74.2718 4.64463 74.2718 5.76176C74.2718 6.93588 73.9719 7.88202 73.372 8.60018C72.7834 9.30693 71.8722 9.66031 70.6385 9.66031H67.3447V13.3708H64.3226V2H70.6385ZM69.6368 7.33486C70.1574 7.33486 70.5366 7.19807 70.7743 6.92448C71.0233 6.6395 71.1478 6.25763 71.1478 5.77886C71.1478 4.79852 70.6555 4.30835 69.6707 4.30835H67.3447V7.33486H69.6368Z"
      fill={primaryColor}
    />
    <Path
      d="M80.0135 13.6444C78.8703 13.6444 77.9365 13.4335 77.2121 13.0117C76.4877 12.5899 75.9614 12.0371 75.6331 11.3531C75.3162 10.6691 75.1577 9.91679 75.1577 9.09605C75.1577 8.2753 75.3162 7.52295 75.6331 6.83899C75.9614 6.15503 76.4877 5.60217 77.2121 5.1804C77.9365 4.75862 78.8703 4.54774 80.0135 4.54774C81.1567 4.54774 82.0905 4.75862 82.8149 5.1804C83.5393 5.60217 84.0656 6.15503 84.3939 6.83899C84.7221 7.52295 84.8863 8.2753 84.8863 9.09605C84.8863 9.91679 84.7221 10.6691 84.3939 11.3531C84.0656 12.0371 83.5393 12.5899 82.8149 13.0117C82.0905 13.4335 81.1567 13.6444 80.0135 13.6444ZM78.078 9.09605C78.078 9.93959 78.2251 10.5267 78.5194 10.8572C78.8137 11.1878 79.3117 11.3531 80.0135 11.3531C80.7153 11.3531 81.2133 11.1878 81.5076 10.8572C81.8019 10.5267 81.949 9.93959 81.949 9.09605C81.949 8.2525 81.8019 7.66544 81.5076 7.33486C81.2133 7.00428 80.7153 6.83899 80.0135 6.83899C79.3117 6.83899 78.8137 7.00428 78.5194 7.33486C78.2251 7.66544 78.078 8.2525 78.078 9.09605Z"
      fill={primaryColor}
    />
    <Path
      d="M88.3968 10.6178C88.4307 10.9826 88.5948 11.2847 88.8891 11.5241C89.1947 11.7635 89.6079 11.8832 90.1285 11.8832C91.0114 11.8832 91.4528 11.6153 91.4528 11.0795C91.4528 10.8287 91.3736 10.6292 91.2151 10.4811C91.068 10.3329 90.7681 10.2303 90.3153 10.1733L88.5156 9.96809C87.6893 9.87689 87.0611 9.61471 86.631 9.18154C86.2009 8.73697 85.9859 8.14421 85.9859 7.40325C85.9859 6.43432 86.3481 5.71616 87.0725 5.24879C87.8082 4.78142 88.7816 4.54774 89.9927 4.54774C91.2265 4.54774 92.2055 4.77572 92.9299 5.23169C93.6543 5.68766 94.0562 6.39442 94.1354 7.35196H91.4868C91.3962 6.6452 90.9039 6.29182 90.0097 6.29182C89.5456 6.29182 89.2004 6.37162 88.974 6.53121C88.759 6.6794 88.6514 6.87889 88.6514 7.12967C88.6514 7.57424 88.9853 7.83073 89.6532 7.89912L91.4019 8.07011C92.398 8.1727 93.1167 8.44059 93.5581 8.87376C94.0109 9.29553 94.2373 9.91679 94.2373 10.7375C94.2373 11.6267 93.9147 12.3334 93.2695 12.8578C92.6243 13.3822 91.5604 13.6444 90.0776 13.6444C88.6288 13.6444 87.5535 13.3594 86.8517 12.7894C86.15 12.2194 85.7821 11.4956 85.7482 10.6178H88.3968Z"
      fill={primaryColor}
    />
    <Path
      d="M99.47 13.3708C98.2136 13.3708 97.3307 13.0972 96.8214 12.55C96.312 12.0029 96.0574 11.1536 96.0574 10.0023V7.02708H94.5633V5.65916L96.2951 4.88971L97.5005 2.75235H98.9776V4.82132H101.287V7.02708H98.9776V11.0453H101.015V13.3708H99.47Z"
      fill={primaryColor}
    />
    <Path d="M105.575 4.82132V13.3708H102.655V4.82132H105.575ZM105.626 2V3.77829H102.604V2H105.626Z" fill="#262C30" />
    <Path
      d="M70.0273 20.1047C71.2044 20.1047 72.1892 20.3669 72.9815 20.8913C73.7851 21.4042 74.385 22.0882 74.7812 22.9431C75.1773 23.7981 75.3754 24.7385 75.3754 25.7645C75.3754 26.7904 75.1773 27.7365 74.7812 28.6029C74.385 29.4692 73.7851 30.1646 72.9815 30.6889C72.1778 31.2133 71.1931 31.4755 70.0273 31.4755H64.3226V20.1047H70.0273ZM69.3481 29.0987C70.3555 29.0987 71.0912 28.8252 71.5553 28.278C72.0194 27.7194 72.2514 26.8816 72.2514 25.7645C72.2514 24.6473 72.0194 23.8209 71.5553 23.2851C71.0912 22.7494 70.3555 22.4815 69.3481 22.4815H67.3107V29.0987H69.3481Z"
      fill={primaryColor}
    />
    <Path
      d="M81.0723 29.7827C81.4911 29.7827 81.8363 29.7029 82.1079 29.5433C82.3796 29.3837 82.5777 29.1671 82.7022 28.8936H85.5375C85.3677 29.7257 84.9263 30.4097 84.2132 30.9454C83.5001 31.4812 82.4305 31.7491 81.0043 31.7491C79.3858 31.7491 78.1973 31.3159 77.4389 30.4496C76.6919 29.5832 76.3184 28.4946 76.3184 27.1837C76.3184 26.3287 76.4881 25.5593 76.8277 24.8753C77.1673 24.1914 77.6879 23.6499 78.3897 23.2509C79.0915 22.8519 79.963 22.6525 81.0043 22.6525C82.6116 22.6525 83.7718 23.0856 84.4849 23.952C85.2093 24.8069 85.5715 25.9013 85.5715 27.235C85.5715 27.5199 85.5545 27.7593 85.5206 27.9531H79.1537C79.2329 28.6143 79.431 29.0873 79.748 29.3723C80.0649 29.6459 80.5063 29.7827 81.0723 29.7827ZM81.0383 24.5846C80.495 24.5846 80.0705 24.7157 79.7649 24.9779C79.4706 25.2287 79.2782 25.6505 79.1877 26.2432H82.804C82.7474 25.6961 82.5777 25.2857 82.2947 25.0121C82.0117 24.7271 81.5929 24.5846 81.0383 24.5846Z"
      fill={primaryColor}
    />
    <Path
      d="M89.0766 28.7226C89.1105 29.0873 89.2746 29.3894 89.5689 29.6288C89.8745 29.8682 90.2877 29.9879 90.8083 29.9879C91.6912 29.9879 92.1326 29.72 92.1326 29.1842C92.1326 28.9335 92.0534 28.734 91.8949 28.5858C91.7478 28.4376 91.4478 28.335 90.9951 28.278L89.1954 28.0728C88.3691 27.9816 87.7409 27.7194 87.3108 27.2863C86.8807 26.8417 86.6656 26.2489 86.6656 25.508C86.6656 24.539 87.0279 23.8209 87.7523 23.3535C88.488 22.8861 89.4614 22.6525 90.6725 22.6525C91.9063 22.6525 92.8853 22.8804 93.6097 23.3364C94.3341 23.7924 94.736 24.4991 94.8152 25.4567H92.1666C92.076 24.7499 91.5837 24.3965 90.6895 24.3965C90.2254 24.3965 89.8802 24.4763 89.6538 24.6359C89.4388 24.7841 89.3312 24.9836 89.3312 25.2344C89.3312 25.679 89.6651 25.9354 90.3329 26.0038L92.0817 26.1748C93.0777 26.2774 93.7965 26.5453 94.2379 26.9785C94.6907 27.4003 94.9171 28.0215 94.9171 28.8423C94.9171 29.7314 94.5945 30.4382 93.9493 30.9625C93.3041 31.4869 92.2402 31.7491 90.7574 31.7491C89.3086 31.7491 88.2333 31.4641 87.5315 30.8941C86.8298 30.3242 86.4619 29.6003 86.428 28.7226H89.0766Z"
      fill={primaryColor}
    />
    <Path
      d="M99.1421 22.926V31.4755H96.2219V22.926H99.1421ZM99.193 20.1047V21.883H96.1709V20.1047H99.193Z"
      fill={primaryColor}
    />
    <Path
      d="M110.273 22.926V30.9796C110.273 32.3931 109.888 33.4305 109.119 34.0916C108.349 34.7528 107.081 35.0834 105.315 35.0834C103.855 35.0834 102.74 34.7813 101.971 34.1771C101.212 33.573 100.799 32.7807 100.731 31.8004H103.567C103.68 32.6439 104.314 33.0657 105.468 33.0657C106.113 33.0657 106.589 32.9346 106.894 32.6724C107.2 32.4216 107.353 32.0113 107.353 31.4413V29.9195C106.742 30.7858 105.774 31.219 104.45 31.219C103.125 31.219 102.135 30.8143 101.478 30.005C100.833 29.1956 100.511 28.1754 100.511 26.9443C100.511 25.6904 100.833 24.6644 101.478 23.8665C102.135 23.0685 103.125 22.6696 104.45 22.6696C105.774 22.6696 106.742 23.097 107.353 23.952V22.926H110.273ZM105.451 29.0645C106.142 29.0645 106.628 28.8993 106.911 28.5687C107.206 28.2381 107.353 27.7137 107.353 26.9956C107.353 26.2888 107.206 25.7702 106.911 25.4396C106.628 25.109 106.142 24.9437 105.451 24.9437C104.727 24.9437 104.206 25.1033 103.889 25.4225C103.584 25.7303 103.431 26.2546 103.431 26.9956C103.431 27.7365 103.584 28.2666 103.889 28.5858C104.206 28.905 104.727 29.0645 105.451 29.0645Z"
      fill={primaryColor}
    />
    <Path
      d="M117.933 22.6525C119.019 22.6525 119.823 22.9716 120.344 23.61C120.864 24.237 121.125 25.1261 121.125 26.2774V31.4755H118.205V26.7733C118.205 25.6106 117.712 25.0292 116.727 25.0292C115.494 25.0292 114.877 25.8386 114.877 27.4573V31.4755H111.957V22.926H114.877V24.5162C115.171 23.9235 115.573 23.4675 116.082 23.1483C116.592 22.8177 117.208 22.6525 117.933 22.6525Z"
      fill={primaryColor}
    />
    <Path
      d="M66.9033 45.8869C66.9485 46.4112 67.1523 46.8444 67.5145 47.1864C67.888 47.5169 68.4426 47.6822 69.1784 47.6822C70.5027 47.6822 71.1648 47.2491 71.1648 46.3827C71.1648 46.0179 71.0573 45.733 70.8422 45.5278C70.6272 45.3226 70.3046 45.1915 69.8745 45.1345L67.4126 44.8609C66.3826 44.7355 65.5903 44.3879 65.0357 43.8179C64.481 43.2479 64.2037 42.4842 64.2037 41.5266C64.2037 40.4209 64.6169 39.5432 65.4431 38.8934C66.2694 38.2436 67.4749 37.9188 69.0595 37.9188C70.7007 37.9188 71.9288 38.2607 72.7438 38.9447C73.5587 39.6287 74.0058 40.5178 74.0851 41.6121H71.1308C71.0743 41.1448 70.8705 40.78 70.5196 40.5178C70.1687 40.2442 69.682 40.1074 69.0595 40.1074C68.4822 40.1074 68.0295 40.2157 67.7012 40.4323C67.3843 40.6489 67.2259 40.9453 67.2259 41.3214C67.2259 41.9712 67.6277 42.3417 68.4313 42.4329L70.5875 42.6552C71.81 42.7692 72.7155 43.1168 73.3041 43.6982C73.8926 44.2796 74.1869 45.0889 74.1869 46.1262C74.1869 47.3118 73.7794 48.2351 72.9645 48.8963C72.1609 49.546 70.8818 49.8709 69.1274 49.8709C67.407 49.8709 66.1393 49.4947 65.3243 48.7424C64.5093 47.99 64.0679 47.0382 64 45.8869H66.9033Z"
      fill={primaryColor}
    />
    <Path
      d="M84.4148 41.0308L81.7153 49.5973C81.4663 50.3953 81.1833 51.0336 80.8664 51.5124C80.5495 52.0026 80.1194 52.373 79.5761 52.6238C79.0441 52.8746 78.348 53 77.4877 53H75.0429V50.6745H78.6932L79.1346 49.3408H77.284L74.5335 41.0308H77.5047L79.1516 45.9894L79.644 47.5796L80.0854 45.9894L81.6474 41.0308H84.4148Z"
      fill={primaryColor}
    />
    <Path
      d="M87.1698 46.8273C87.2038 47.1921 87.3679 47.4942 87.6622 47.7335C87.9678 47.9729 88.3809 48.0926 88.9016 48.0926C89.7845 48.0926 90.2259 47.8247 90.2259 47.289C90.2259 47.0382 90.1467 46.8387 89.9882 46.6905C89.8411 46.5423 89.5411 46.4397 89.0884 46.3827L87.2887 46.1775C86.4624 46.0863 85.8342 45.8242 85.4041 45.391C84.974 44.9464 84.7589 44.3536 84.7589 43.6127C84.7589 42.6438 85.1211 41.9256 85.8455 41.4582C86.5812 40.9909 87.5547 40.7572 88.7658 40.7572C89.9995 40.7572 90.9786 40.9852 91.703 41.4411C92.4274 41.8971 92.8292 42.6039 92.9085 43.5614H90.2599C90.1693 42.8546 89.6769 42.5013 88.7828 42.5013C88.3187 42.5013 87.9735 42.5811 87.7471 42.7407C87.532 42.8888 87.4245 43.0883 87.4245 43.3391C87.4245 43.7837 87.7584 44.0402 88.4262 44.1086L90.175 44.2796C91.171 44.3821 91.8898 44.65 92.3312 45.0832C92.7839 45.505 93.0103 46.1262 93.0103 46.947C93.0103 47.8361 92.6877 48.5429 92.0426 49.0672C91.3974 49.5916 90.3334 49.8538 88.8507 49.8538C87.4019 49.8538 86.3266 49.5688 85.6248 48.9989C84.923 48.4289 84.5552 47.705 84.5212 46.8273H87.1698Z"
      fill={primaryColor}
    />
    <Path
      d="M98.2431 49.5802C96.9867 49.5802 96.1038 49.3066 95.5945 48.7595C95.0851 48.2123 94.8304 47.3631 94.8304 46.2117V43.2365H93.3364V41.8686L95.0681 41.0992L96.2736 38.9618H97.7507V41.0308H100.06V43.2365H97.7507V47.2548H99.7881V49.5802H98.2431Z"
      fill={primaryColor}
    />
    <Path
      d="M105.429 47.8874C105.847 47.8874 106.193 47.8076 106.464 47.648C106.736 47.4884 106.934 47.2719 107.059 46.9983H109.894C109.724 47.8304 109.283 48.5144 108.57 49.0501C107.857 49.5859 106.787 49.8538 105.361 49.8538C103.742 49.8538 102.554 49.4206 101.795 48.5543C101.048 47.6879 100.675 46.5993 100.675 45.2884C100.675 44.4334 100.845 43.664 101.184 42.98C101.524 42.2961 102.044 41.7546 102.746 41.3556C103.448 40.9567 104.319 40.7572 105.361 40.7572C106.968 40.7572 108.128 41.1904 108.841 42.0567C109.566 42.9116 109.928 44.006 109.928 45.3397C109.928 45.6247 109.911 45.8641 109.877 46.0578H103.51C103.589 46.719 103.787 47.1921 104.104 47.4771C104.421 47.7506 104.863 47.8874 105.429 47.8874ZM105.395 42.6894C104.851 42.6894 104.427 42.8204 104.121 43.0826C103.827 43.3334 103.635 43.7552 103.544 44.3479H107.16C107.104 43.8008 106.934 43.3904 106.651 43.1168C106.368 42.8319 105.949 42.6894 105.395 42.6894Z"
      fill={primaryColor}
    />
    <Path
      d="M122.757 40.7572C123.844 40.7572 124.653 41.0764 125.185 41.7147C125.728 42.3417 126 43.2308 126 44.3821V49.5802H123.08V44.8267C123.08 43.6982 122.616 43.1339 121.688 43.1339C121.11 43.1339 120.686 43.3334 120.414 43.7324C120.143 44.12 120.007 44.7298 120.007 45.562V49.5802H117.137V44.8267C117.137 43.6982 116.673 43.1339 115.745 43.1339C115.168 43.1339 114.743 43.3334 114.472 43.7324C114.2 44.12 114.064 44.7298 114.064 45.562V49.5802H111.144V41.0308H114.064V42.4842C114.642 41.3328 115.604 40.7572 116.951 40.7572C117.664 40.7572 118.252 40.9225 118.716 41.2531C119.192 41.5722 119.537 42.0396 119.752 42.6552C120.329 41.3898 121.331 40.7572 122.757 40.7572Z"
      fill={primaryColor}
    />
  </SVG>
)

export { DesignSystemLogo }
