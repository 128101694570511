import styled, { css } from 'styled-components'
import { ProgressIndicatorProps } from './ProgressIndicator'

export const StyledProgressIndicator = styled.div<ProgressIndicatorProps>(
  ({ vertical }) => css`
    display: flex;

    ${vertical &&
    css`
      flex-direction: column;
    `}
  `
)
