import React, { useState } from 'react'
import { useTheme } from '../../utils/useTheme'
import PropTypes from 'prop-types'
import { MenuItem } from '../../basic-components/MenuItem'
import { ArrowLeftIcon, PinIcon } from '../../design-tokens/icons'
import { HeadlineSize } from '../../design-tokens/typography'
import {
  Break,
  Container,
  Content,
  Header,
  HeaderButtons,
  HeaderTitle,
  StyledHeadline,
  RootMenu,
  StyledRootMenuItem,
  PersonalIconButton,
  CloseButtonPositioning,
  StyledWrapperLanguageSelection,
} from './MobileMenu.style'
import { MobileMenuOption, MobileMenuProps, MobileMenuPropsType } from './MobileMenu.types'
import OutsideClick from '../../basic-components/OutsideClick'
import { LocationButton, FocusInner } from '../TopBar/TopBar.styles'
import { handleNavItemClick, checkAriaCurrent } from '../Navigation/navigationItemUtils'

export const MobileMenu: React.FC<MobileMenuProps> = (props) => {
  const {
    navigationState,
    rootLevelItems,
    onOutsideClick,
    showMobileMenu,
    closeButton,
    zIndex,
    languageSelection,
    onMenuItemClick,
    servicePointsItem,
    returnButtonLabel,
    ...rest
  } = props

  const {
    xyz: { color, iconSize },
  } = useTheme()

  const { topLevelMenuItems, currentPage, rootPage, isBusiness, rootMenuItems, currentPageTopbar } = navigationState

  const [selectedOption, setSelectedOption] = useState('')
  const [notRootPage, setNotRootPage] = useState(false)
  const [currentMenuPage, setCurrentMenuPage] = useState(currentPage || rootPage || null)

  const outsideClick = () => {
    if (!showMobileMenu) {
      return
    }
    onOutsideClick()
  }

  const onMenuItemSelect = (option: MobileMenuOption) => {
    setSelectedOption(option.value)
    onMenuItemClick(option.value)
  }

  const handleMenuArrowClick = (item: MobileMenuOption) => {
    setCurrentMenuPage(item)
    setNotRootPage(!notRootPage)
  }

  const handleBackButtonClick = () => {
    setNotRootPage(!notRootPage)
    setSelectedOption('')
  }

  return (
    <>
      {showMobileMenu && (
        <OutsideClick onOutsideClick={outsideClick}>
          <Container {...rest} zIndex={zIndex}>
            <Header>
              <HeaderButtons isDark={isBusiness}>
                <CloseButtonPositioning isDark={isBusiness}>{closeButton}</CloseButtonPositioning>
                {rootMenuItems && (
                  <RootMenu>
                    {rootMenuItems.map((item, index) => {
                      return (
                        <StyledRootMenuItem isDark={isBusiness} isActive={item.active} key={`${item.title}-${index}`}>
                          <a
                            href={item.href}
                            onClick={(e) => handleNavItemClick(item, e)}
                            aria-current={checkAriaCurrent(item, currentPageTopbar)}
                            tabIndex={0}
                          >
                            <FocusInner tabIndex={-1}>{item.title}</FocusInner>
                          </a>
                        </StyledRootMenuItem>
                      )
                    })}
                  </RootMenu>
                )}
              </HeaderButtons>

              {notRootPage && (
                <HeaderTitle>
                  <PersonalIconButton
                    onClick={handleBackButtonClick}
                    aria-label="Go back"
                    icon={ArrowLeftIcon}
                    iconColor={color.neutralNetworkGray}
                    label={returnButtonLabel}
                  />
                  <StyledHeadline size={HeadlineSize.Five}>{currentMenuPage.label}</StyledHeadline>
                </HeaderTitle>
              )}
            </Header>
            <Content role="menu">
              {!notRootPage
                ? topLevelMenuItems.map((opt) => {
                    return (
                      <MenuItem
                        role="menuitem"
                        key={opt.id}
                        value={opt.value}
                        label={opt.label}
                        onSelect={() => onMenuItemSelect(opt)}
                        onButtonClick={() => handleMenuArrowClick(opt)}
                        icon={opt.icon}
                        redirectsToSubMenu={opt.redirectsToSubMenu}
                        selected={selectedOption === opt.value}
                        chevronAriaLabel={opt.chevronAriaLabel}
                      />
                    )
                  })
                : currentMenuPage.children?.length > 0
                ? topLevelMenuItems
                    .filter((opt) => opt.value === currentMenuPage.value)
                    .map((validOption) =>
                      validOption.children.map((child) => {
                        return (
                          <MenuItem
                            role="menuitem"
                            key={child.id}
                            value={child.value}
                            label={child.label}
                            onSelect={() => onMenuItemSelect(child)}
                            icon={child.icon}
                            selected={selectedOption === child.value}
                          />
                        )
                      })
                    )
                : null}

              {props.rootLevelItems && (
                <>
                  <Break />
                  {rootLevelItems.map((opt) => {
                    return (
                      <MenuItem
                        role="menuitem"
                        key={opt.id}
                        value={opt.value}
                        selected={selectedOption === opt.value}
                        icon={opt.icon}
                        label={opt.label}
                        onSelect={() => onMenuItemSelect(opt)}
                      />
                    )
                  })}
                </>
              )}

              <>
                <Break />
                {props.servicePointsItem && (
                  <LocationButton isDark={isBusiness} href={servicePointsItem.href} tabIndex={0}>
                    <FocusInner tabIndex={-1}>
                      <PinIcon width={`${iconSize.s}rem`} height={`${iconSize.s}rem`} />
                      {servicePointsItem.title}
                    </FocusInner>
                  </LocationButton>
                )}
                <StyledWrapperLanguageSelection>{props.languageSelection}</StyledWrapperLanguageSelection>
              </>
            </Content>
          </Container>
        </OutsideClick>
      )}
    </>
  )
}

MobileMenu.defaultProps = {
  showMobileMenu: true,
  zIndex: 3,
  navigationState: {
    topLevelMenuItems: [],
  },
  returnButtonLabel: 'Back',
}

MobileMenu.propTypes = {
  navigationState: MobileMenuPropsType.isRequired,
  showMobileMenu: PropTypes.bool,
  zIndex: PropTypes.number,
  returnButtonLabel: PropTypes.string,
}
