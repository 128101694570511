/* eslint-disable import/named */
import {
  css,
  CSSObject,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  Interpolation,
  InterpolationFunction,
  SimpleInterpolation,
  ThemedStyledProps,
} from 'styled-components'
import { Theme as theme, ThemeInterface } from '@postidigital/posti-theme'

export type ThemeBreakpoint = keyof ThemeInterface['breakpoint']

interface TagFunction<T extends Record<string, any> = DefaultTheme> {
  (first: TemplateStringsArray | CSSObject, ...interpolations: SimpleInterpolation[]): FlattenSimpleInterpolation
  <P extends Record<string, any>>(
    first: TemplateStringsArray | CSSObject | InterpolationFunction<ThemedStyledProps<P, T>>,
    ...interpolations: Array<Interpolation<ThemedStyledProps<P, T>>>
  ): FlattenInterpolation<ThemedStyledProps<P, T>>
}

const makeMediaQuery: (breakpoint: ThemeBreakpoint) => TagFunction =
  (breakpoint) =>
  (strings, ...interpolations) => {
    return css`
      @media (min-width: ${theme.breakpoint[breakpoint]}) {
        ${css(strings, ...interpolations)};
      }
    `
  }

type Media = { [key in keyof typeof theme.breakpoint]: TagFunction }

export const mediaQuery = (Object.keys(theme.breakpoint) as ReadonlyArray<ThemeBreakpoint>).reduce(
  (obj, label) => ({ ...obj, [label]: makeMediaQuery(label) }),
  {} as Media
)

export default mediaQuery
