import React, { useState, useEffect } from 'react'
import SearchBar, { Size } from '../SeachBar'
import PropTypes, { Validator } from 'prop-types'
import { Container, OptionsContainer, Option } from './SearchBarWithAutofill.styles'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import OutsideClick from '../../basic-components/OutsideClick'
import { randomId } from '../../utils/helpers'
import FormHelperText from '../FormHelperText'

export interface SearcBarOptionType {
  id: string
  elem: JSX.Element | string | number
}

export interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  options: SearcBarOptionType[]
  inputValue: string | number
  selectedOptionId: string | number
  maxHeight?: string
  size?: keyof typeof Size | undefined
  onChangeDebounceTime?: number
  isInvalid?: boolean
  message?: string
  inputRef?: React.RefObject<HTMLInputElement>
  hasSubmitButton?: boolean
  onEnter?: (searchStr: string) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onOptionSelect: (id: string) => void
  onChangeWithDebounce?: (searchStr: string) => void
  onSubmitButtonClick?: (searchStr: string) => void
}

/**
 * @deprecated please use Search with suggestions component instead
 */
const SearchBarWithAutofill: React.FC<Props> = ({
  options,
  onOptionSelect,
  inputValue,
  selectedOptionId,
  onEnter,
  onFocus,
  maxHeight,
  size,
  onChangeDebounceTime,
  hasSubmitButton,
  onSubmitButtonClick,
  ...inputElementProps
}) => {
  const optionsRef: React.RefObject<any> = React.createRef()
  const { placeholder, onChangeWithDebounce, isInvalid, message, id, inputRef, ...rest } = inputElementProps
  const [isOpen, setOpen] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [activeOption, setActiveOption] = useState(-1)
  const [optionListId] = useState(randomId())

  useEffect(() => {
    if (selectedOptionId && options.length) {
      const activeOptionIndex = options.findIndex((opt) => selectedOptionId === opt.id)

      if (activeOptionIndex > -1) {
        setActiveOption(activeOptionIndex)
      }
    }
  }, [options, selectedOptionId])

  const onChangeHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onChange } = inputElementProps

    if (isFocused && !isOpen) {
      setOpen(true)
    }

    if (onChange) {
      onChange(e)
    }
  }

  const onFocusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    setOpen(true)
    setIsFocused(true)

    if (onFocus) {
      onFocus(e)
    }
  }

  const handleOptionClick = (id: string) => {
    onClose()
    onOptionSelect(id)
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>, id: string) => {
    if (e.key === KeyboardKeys.Enter || e.key === KeyboardKeys.Space) {
      e.preventDefault()
      onClose()
      handleOptionClick(id)
    }
  }

  const setFocusAttribute = (elem: HTMLElement) => {
    elem.setAttribute('data-focus', 'true')
    elem.setAttribute('aria-selected', 'true')
  }

  const removeFocusAttribute = (elem: HTMLElement) => {
    elem.removeAttribute('data-focus')
    elem.removeAttribute('aria-selected')
  }

  const moveDownwards = (list: HTMLElement, children: HTMLElement[]) => {
    const childCount = children.length - 1
    const nextChild = activeOption < childCount ? activeOption + 1 : activeOption
    const offsetY = children[nextChild].offsetTop
    const height = children[nextChild].offsetHeight
    const listHeight = list.offsetHeight
    const listOffsetY = list.scrollTop

    setFocusAttribute(children[nextChild])

    if (offsetY + height > listOffsetY + listHeight) {
      list.scrollTop = offsetY + height - listHeight
    }

    if (nextChild !== activeOption) {
      if (activeOption !== -1) {
        removeFocusAttribute(children[activeOption])
      }

      setActiveOption(nextChild)
    }
  }

  const moveUpwards = (list: HTMLElement, children: HTMLElement[]) => {
    const prevChild = activeOption - 1 >= -1 ? activeOption - 1 : activeOption
    const listOffsetY = list.scrollTop

    if (prevChild !== -1) {
      const offsetY = children[prevChild].offsetTop

      setFocusAttribute(children[prevChild])

      if (offsetY < listOffsetY) {
        list.scrollTop = offsetY
      }
    }

    if (activeOption !== -1) {
      removeFocusAttribute(children[activeOption])
    }

    if (prevChild !== activeOption) {
      setActiveOption(prevChild)
    }
  }

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const key = e.key as KeyboardKeys

    if (key === KeyboardKeys.Tab) {
      onClose()
    } else if (
      [
        KeyboardKeys.ArrowDown,
        KeyboardKeys.Home,
        KeyboardKeys.ArrowUp,
        KeyboardKeys.End,
        KeyboardKeys.Enter,
        KeyboardKeys.Escape,
      ].includes(key) &&
      (key !== KeyboardKeys.Enter || options[activeOption])
    ) {
      if (optionsRef && optionsRef.current) {
        e.preventDefault()
        e.persist()
        const list = optionsRef.current
        const children = list.childNodes

        if (key === KeyboardKeys.ArrowDown || key === KeyboardKeys.Home) {
          moveDownwards(list, children)
        } else if (key === KeyboardKeys.ArrowUp || key === KeyboardKeys.End) {
          moveUpwards(list, children)
        } else if (key === KeyboardKeys.Escape) {
          onClose()
        } else if (key === KeyboardKeys.Enter) {
          onKeyPress(e, options[activeOption].id)
        }
      }
    }
  }

  const onClose = () => {
    setActiveOption(-1)
    setOpen(false)
  }

  const onClick = () => {
    setOpen(true)
  }

  const onBlurHandler = () => {
    setIsFocused(false)
  }

  const onChangeWithDebounceHandler = (value: string) => {
    if (isFocused && !isOpen) {
      setOpen(true)
    }

    if (onChangeWithDebounce) {
      onChangeWithDebounce(value)
    }
  }

  return (
    <OutsideClick onOutsideClick={onClose}>
      <Container>
        <SearchBar
          inputRef={inputRef}
          placeholder={placeholder}
          id={id}
          value={inputValue}
          hasSubmitButton={hasSubmitButton}
          onSubmitButtonClick={onSubmitButtonClick}
          minCharAmount={0}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onClick={onClick}
          aria-autocomplete="list"
          onKeyDown={handleInputKeyDown}
          size={size}
          onChangeDebounceTime={onChangeDebounceTime}
          onChangeWithDebounce={onChangeWithDebounceHandler}
          onEnter={onEnter}
          autoComplete="off"
          spellCheck={false}
          aria-invalid={false}
          aria-activedescendant={optionListId}
          aria-owns={optionListId}
          aria-expanded={isOpen}
          role="combobox"
          {...rest}
        />
        {isOpen && options && options.length > 0 && (
          <OptionsContainer id={optionListId} role="listbox" maxHeight={maxHeight} tabIndex={-1} ref={optionsRef}>
            {options.map((item, i) => {
              return (
                <Option
                  isSelected={item.id === selectedOptionId}
                  onClick={() => handleOptionClick(item.id)}
                  key={i}
                  role="option"
                  tabIndex={-1}
                >
                  {item.elem}
                </Option>
              )
            })}
          </OptionsContainer>
        )}
        {message && <FormHelperText isInvalid={isInvalid} text={message} />}
      </Container>
    </OutsideClick>
  )
}

SearchBarWithAutofill.propTypes = {
  inputRef: PropTypes.shape({ current: PropTypes.any }) as Validator<React.RefObject<HTMLInputElement>>,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      elem: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    }) as Validator<SearcBarOptionType>
  ).isRequired,
  onOptionSelect: PropTypes.func.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectedOptionId: PropTypes.string.isRequired,
  onEnter: PropTypes.func,
  maxHeight: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(Size)) as Validator<Size>,
  onChangeDebounceTime: PropTypes.number,
  onChangeWithDebounce: PropTypes.func,
  isInvalid: PropTypes.bool,
  message: PropTypes.string,
  hasSubmitButton: PropTypes.bool,
  onSubmitButtonClick: PropTypes.func,
}

SearchBarWithAutofill.defaultProps = {
  maxHeight: '40vh',
  size: Size.lg,
  inputValue: '',
  hasSubmitButton: false,
}

export default SearchBarWithAutofill
