import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'

import Title, { As } from '../../typography/Title'
import { TitleSize, ThemeColor } from '../../utils/helpers'

interface TitleProps {
  text: string
  as: As
  size: TitleSize
  color?: ThemeColor | string
}

export interface ContentProps extends HTMLAttributes<HTMLDivElement> {
  contentTitle?: TitleProps
}

const StyledTitle = styled(Title)`
  margin-top: 0;
`

const ContentContainer = styled.div<ContentProps>`
  ${({ theme }) => css`
    flex: 1;
    height: auto;
    width: 100%;
    padding: ${theme.spacing.sm}rem ${theme.spacing.sm}rem 0 0;
    margin: 0;
    flex-wrap: wrap;
  `}
`

/**
 *
 * @deprecated Custom implementation should be created instead
 */
const CardContent: React.FC<ContentProps> = ({ contentTitle, children, ...rest }) => {
  return (
    <ContentContainer {...rest}>
      {contentTitle && <StyledTitle {...contentTitle}>{contentTitle.text}</StyledTitle>}
      {children}
    </ContentContainer>
  )
}

CardContent.propTypes = {
  contentTitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    as: PropTypes.string.isRequired as Validator<As>,
    size: PropTypes.string.isRequired as Validator<TitleSize>,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.string as Validator<ThemeColor>]),
  }) as Validator<TitleProps>,
}

CardContent.defaultProps = {
  contentTitle: null,
}

export default CardContent
