import React, { AnchorHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'
import { ArrowRightIcon } from '../../design-tokens/icons'
import { Theme } from '@postidigital/posti-theme'
import { useTheme } from '../../utils/useTheme'
import { ThemeColor } from '../../utils/helpers'
import { DefaultIconProps, IconProps } from '../../design-tokens/icons/icons.types'
import { ButtonIconPosition } from '../Button/Button'

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  iconColor?: ThemeColor | string
  iconPosition?: keyof typeof ButtonIconPosition
  disabled?: boolean
  icon?: React.ComponentType<DefaultIconProps>
}

const StyledLink = styled.a<LinkProps>`
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.0125rem;
  cursor: pointer;
  border: 1px solid transparent;

  ${({ theme, disabled }) => css`
    font-weight: ${theme.fontWeight.semiBold};
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSize.text.md}rem;

    ${disabled &&
    css`
      color: ${theme.xyz.color.neutralPassiveGray};
      pointer-events: none;
    `}

    &:hover,
    &:focus {
      outline-offset: 0;
      text-decoration: underline;
    }

    &:hover {
      color: ${theme.xyz.color.signalHoverBlue};
      svg path {
        fill: ${theme.xyz.color.signalHoverBlue};
      }
    }

    &:focus {
      outline: 0;
      border: 1px solid ${theme.xyz.color.signalLightBlue};
      box-shadow: 0px 0px 7px ${theme.xyz.color.signalBlue};
      background-color: ${theme.xyz.color.neutralWhite};
    }
  `}
`

const StyledIcon = styled(ArrowRightIcon)<IconProps & { iconPosition: keyof typeof ButtonIconPosition }>`
  ${({ theme, iconPosition }) => css`
    flex-shrink: 0;
    width: ${theme.xyz.iconSize.s}rem;
    height: ${theme.xyz.iconSize.s}rem;

    ${iconPosition === ButtonIconPosition.left &&
    css`
      margin-right: ${theme.xyz.spacing.space2}rem;
    `}

    ${iconPosition === ButtonIconPosition.right &&
    css`
      margin-left: ${theme.xyz.spacing.space2}rem;
    `}
  `}
`

const FocusInner = styled.span``

export const Link: React.FC<LinkProps> = (props) => {
  const { icon: Icon, disabled, iconColor, iconPosition, children, ...rest } = props
  const theme = useTheme()
  const color = disabled ? theme.xyz.color.neutralPassiveGray : iconColor
  return (
    <StyledLink aria-disabled={disabled} disabled={disabled} iconPosition={iconPosition} tabIndex={0} {...rest}>
      <FocusInner tabIndex={-1}>
        {!!Icon && iconPosition === ButtonIconPosition.left && (
          <StyledIcon as={Icon} color={color} iconPosition={iconPosition} aria-hidden={true} />
        )}
        {children}
        {!!Icon && iconPosition === ButtonIconPosition.right && (
          <StyledIcon as={Icon} color={color} iconPosition={iconPosition} aria-hidden={true} />
        )}
      </FocusInner>
    </StyledLink>
  )
}

Link.propTypes = {
  iconColor: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  iconPosition: PropTypes.oneOf(Object.values(ButtonIconPosition)),
}

Link.defaultProps = {
  disabled: false,
  iconColor: Theme.color.signalBlue,
  iconPosition: 'right',
}
