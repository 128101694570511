import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { XyzTheme } from '@postidigital/posti-theme'

export interface SnackbarProps extends HTMLAttributes<HTMLDivElement> {
  message: string
}

const Container = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  color: ${XyzTheme.color.neutralWhite};
  font-size: ${XyzTheme.fontSize.label.five}rem;
  background-color: ${XyzTheme.color.neutralNetworkGray};
  position: relative;
  border-radius: ${XyzTheme.borderRadius.xs}rem;
  padding: ${XyzTheme.spacing.space3}rem;
  overflow: hidden;
`

const Message = styled.span`
  font-size: ${XyzTheme.fontSize.label.five}rem;
  color: ${XyzTheme.color.neutralWhite};
  font-weight: ${XyzTheme.fontWeight.medium};
`

export const Snackbar: React.FC<SnackbarProps> = ({ message, ...props }) => {
  return (
    <Container {...props}>
      <Message>{message}</Message>
    </Container>
  )
}

Snackbar.propTypes = {
  message: PropTypes.string.isRequired,
}
