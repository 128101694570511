import React, { HTMLAttributes } from 'react'
import PropTypes, { Validator } from 'prop-types'
import Card from '../../deprecated/Card'
import Square from '../../deprecated/Square'
import OutsideClick from '../../basic-components/OutsideClick'
import styled, { css } from 'styled-components'
import { color, fontSize, fontWeight } from '@postidigital/posti-theme'

const StyledCard = styled(Card)`
  ${({
    theme: {
      xyz: { spacing, borderRadius },
    },
  }) => css`
    padding: ${spacing.space4}rem;
    flex-flow: row wrap;
    min-height: 0;
    border-radius: ${borderRadius.md}rem;
  `}
`

const ServiceItemContainer = styled.a`
  flex: 1 1 33.33%;
  max-width: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${color.neutralNetworkGray};
  font-size: 0.65rem;
  font-weight: ${fontWeight.bold};
  line-height: 1.2;
  &:focus {
    outline: 1px solid ${color.signalBlue};
  }
  &:hover,
  &:active {
    text-decoration: none;
  }
`

const ServiceItemLogo = styled(Square)<{ backgroundColor?: string }>(
  ({ theme, backgroundColor }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${backgroundColor};
    color: ${color.neutralWhite};
    font-size: ${fontSize.text.md}rem;
    margin-bottom: ${theme.xyz.spacing.space2}rem;
  `
)

const ServiceItemTitle = styled.p`
  text-align: center;
  word-break: normal;
  hyphens: auto;
`

const FocusInner = styled.span`
  ${({ theme }) => css`
    padding: ${theme.xyz.spacing.space2}rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`

interface ServiceItemProps extends HTMLAttributes<HTMLAnchorElement> {
  title: string
  href: string
  icon?: JSX.Element
  squareColor?: string
  onClick?: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
}

const ServiceItem: React.FC<ServiceItemProps> = (props) => {
  const { href, title, squareColor = color.neutralNetworkGray, icon, onClick, ...rest } = props

  const handleItemClick = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (onClick) {
      e.preventDefault()
      onClick(e)
    }
  }

  return (
    <ServiceItemContainer href={href} onClick={handleItemClick} aria-label={title} tabIndex={0} {...rest}>
      <FocusInner tabIndex={-1}>
        <ServiceItemLogo backgroundColor={squareColor}>{icon || title.charAt(0).toUpperCase()}</ServiceItemLogo>
        <ServiceItemTitle>{title}</ServiceItemTitle>
      </FocusInner>
    </ServiceItemContainer>
  )
}

export interface AccountOverlayProps extends HTMLAttributes<HTMLElement> {
  toggleOpen: (open) => void
  serviceItems?: ServiceItemProps[]
}

const AccountOverlay: React.FC<AccountOverlayProps> = (props) => {
  const { toggleOpen, serviceItems = [], ...rest } = props
  return (
    <OutsideClick onOutsideClick={() => toggleOpen(false)}>
      <StyledCard role="menu" {...rest}>
        {serviceItems?.map((item, index) => (
          <ServiceItem
            role="menuitem"
            key={`${item.title}-${index}`}
            href={item.href}
            icon={item.icon}
            title={item.title}
            squareColor={item.squareColor}
            onClick={item.onClick}
          />
        ))}
      </StyledCard>
    </OutsideClick>
  )
}

export const serviceItemType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.node,
  squareColor: PropTypes.string,
  onClick: PropTypes.func,
}) as Validator<ServiceItemProps>

AccountOverlay.propTypes = {
  toggleOpen: PropTypes.func.isRequired,
  serviceItems: PropTypes.arrayOf(serviceItemType),
}

/** @component */
export default AccountOverlay
