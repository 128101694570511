import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'

interface ImageProps extends HTMLAttributes<HTMLImageElement> {
  src: string
  alt?: string
  height?: string
  width?: string
}

export interface HeadingProps extends HTMLAttributes<HTMLDivElement> {
  borderBottom?: boolean
  image?: ImageProps
}

const HeadingContainer = styled.div<HeadingProps>`
  ${({ theme, borderBottom }) => css`
    width: 100%;
    top: 0;
    padding: ${theme.spacing.sm}rem ${theme.spacing.sm}rem ${theme.spacing.sm}rem 0;
    border-bottom: ${borderBottom && `1px solid ${theme.color.neutralGray5}`};
    flex-wrap: wrap;
  `}
`

const Image = styled.img<{ height?: string; width?: string }>`
  ${({ height, width }) => css`
    height: ${height};
    width: ${width};
  `}
  max-width: 16.5rem;
`

/**
 *
 * @deprecated Custom implementation should be created instead
 */
const CardHeading: React.FC<HeadingProps> = ({ borderBottom, children, image, ...rest }) => {
  return (
    <HeadingContainer borderBottom={borderBottom} {...rest}>
      {image && <Image {...image} />}
      {children}
    </HeadingContainer>
  )
}

CardHeading.propTypes = {
  borderBottom: PropTypes.bool,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
  }) as Validator<ImageProps>,
}

CardHeading.defaultProps = {
  borderBottom: false,
  image: null,
}

export default CardHeading
