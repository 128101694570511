import React from 'react'
import styled from 'styled-components'
import PropTypes, { Validator } from 'prop-types'
import ToggleButton from '../ToggleButton'
import Text from '../../typography/Text'
import { VisaIcon, MasterCardIcon } from '../../design-tokens/icons'

/**
 * Predefined display types.
 */
export enum PaymentTypeDisplayType {
  Visa = 'Visa',
  Mastercard = 'Mastercard',
  MobilePay = 'MobilePay',
  Other = 'Other',
}

// TODO: add mobilePay icon
const defaultIcons = {
  Visa: <VisaIcon />,
  Mastercard: <MasterCardIcon />,
}

const StyledToggleButton = styled(ToggleButton)`
  padding: 0 1rem 0 0;
  height: 4.5rem;
  max-width: 17rem;
`

const IconContainer = styled.span`
  margin: auto 1rem;
`

const TitleWrapper = styled.span`
  display: inline-block;
  text-align: left;
`

const StyledTitle = styled(Text)`
  color: ${({ theme }) => theme.color.neutralNetworkGray};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`

const StyledSubtitle = styled(Text)`
  color: ${({ theme }) => theme.color.neutralNetworkGray};
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`

export interface Props {
  disabled?: boolean
  displayType: PaymentTypeDisplayType
  icon?: JSX.Element
  label: string
  onClick: (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
  selected: boolean
  subtitle?: string
  title: string
}

/**
 *
 * @deprecated Component is unrelated to posti-web-components and should be moved to its own application.
 */
const PaymentType: React.FC<Props> = (props) => {
  const { displayType, icon, title, subtitle, ...rest } = props

  const resolveIcon = () => {
    if (icon) {
      return icon
    }
    if (defaultIcons[displayType]) {
      return defaultIcons[displayType]
    }
    return null
  }

  return (
    <StyledToggleButton {...rest}>
      <IconContainer>{resolveIcon()}</IconContainer>
      <TitleWrapper>
        <StyledTitle as="span" size="md">
          {title}
        </StyledTitle>
        {subtitle && (
          <StyledSubtitle as="span" size="sm">
            {subtitle}
          </StyledSubtitle>
        )}
      </TitleWrapper>
    </StyledToggleButton>
  )
}

PaymentType.propTypes = {
  disabled: PropTypes.bool,
  displayType: PropTypes.oneOf(Object.keys(PaymentTypeDisplayType)) as Validator<PaymentTypeDisplayType>,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

PaymentType.defaultProps = {
  disabled: false,
}

export default PaymentType
