import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { ChevronRightIcon } from '../../design-tokens/icons'
import { Theme } from '@postidigital/posti-theme'
import { ThemeColor } from '../../utils/helpers'
import { useTheme } from '../../utils/useTheme'

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  textColor?: ThemeColor | string
  chevronColor?: ThemeColor | string
  borderHoverColor?: ThemeColor | string
  backgroundColor?: ThemeColor | string
}

const Rotate = styled.div<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
  transform-origin: 50% 50%;
  transition: transform 100ms ease;
  margin-left: 0.25rem;
  display: inline-block;
`

const TagDropdownButton = styled.button<ButtonProps>`
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: left;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  border-radius: 2rem;
  ${({ theme, textColor, borderHoverColor, backgroundColor }) => css`
    color: ${theme.color[textColor] || textColor};
    border: 1px solid ${theme.color.neutralPassiveGray};
    font-family: ${theme.fontFamily.primary};
    background: ${theme.color[backgroundColor] || backgroundColor};

    &:hover,
    &:focus {
      border-color: ${theme.color[borderHoverColor] || borderHoverColor};
      outline: 0;
    }
  `}
`

const Label = styled.span`
  width: calc(100% - 1rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.primary};
    font-weight: ${theme.fontWeight.normal};
    font-size: ${theme.fontSize.text.md}rem;
  `}
`

interface ToggleProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  onClick: (e: React.MouseEvent) => void
  isOpen: boolean
  label: string
  textColor?: ThemeColor | string
  chevronColor?: ThemeColor | string
  borderHoverColor?: ThemeColor | string
  backgroundColor?: ThemeColor | string
}

const FilterTagDropdownToggle: React.FC<ToggleProps> = (props) => {
  const { chevronColor, isOpen, label, ...rest } = props
  const theme = useTheme()
  return (
    <TagDropdownButton aria-haspopup={true} {...rest}>
      <Label>{label}</Label>
      <Rotate isOpen={isOpen}>
        <ChevronRightIcon width={`${theme.size.icon.xs}em`} height={`${theme.size.icon.xs}em`} color={chevronColor} />
      </Rotate>
    </TagDropdownButton>
  )
}

FilterTagDropdownToggle.defaultProps = {
  textColor: Theme.color.neutralNetworkGray,
  chevronColor: Theme.color.neutralNetworkGray,
  borderHoverColor: Theme.color.signalBlue,
  backgroundColor: Theme.color.neutralGray2,
}

FilterTagDropdownToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  chevronColor: PropTypes.string,
  borderHoverColor: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default FilterTagDropdownToggle
