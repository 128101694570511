/* eslint-disable import/named */
import React, { useEffect } from 'react'
import { useDropzone, DropzoneOptions, FileRejection, DropEvent, DropzoneInputProps } from 'react-dropzone'
import PropTypes, { Validator } from 'prop-types'
import { useTheme } from '../../utils/useTheme'
import { ErrorMessages } from './ErrorMessages'
import { DropdownContainer, Placeholder, Text, StyledUploadIcon, StyledWrapper } from './FileUpload.styles'

export interface FileUploadProps extends DropzoneOptions {
  /**
   * an array of selected files
   */
  files: File[]
  /**
   * updates the array of selected files
   */
  setFiles: (files: File[]) => void
  /**
   * content that will be shown inside dropdown zone
   */
  placeholderText: React.ReactNode
  /**
   * an array of error messages to be displayed
   */
  errorMessages: string[]
  /**
   * set error messages in case of rejected file. Way to add localisation to the error messages
   */
  setErrorMessages: (fileRejections: FileRejection[], event: DropEvent) => void
  /**
   * clears all error messages
   */
  resetErrorMessages: () => void
  /**
   * attributes to add to input tag
   */
  inputProps?: DropzoneInputProps
  maxWidth?: string
  /**
   * Use prop when you need to center the content. Please note that centered content should be applied only for small widths
   */
  centerContent?: boolean
  filesDisplay: React.ReactNode
}

export interface DropdownContainerProps {
  isDragActive?: boolean
  isDisabled?: boolean
}

const FileUpload: React.FC<FileUploadProps> = ({
  files,
  setFiles,
  placeholderText,
  errorMessages,
  setErrorMessages,
  resetErrorMessages,
  inputProps = {},
  maxWidth,
  centerContent,
  filesDisplay,
  ...rest
}) => {
  const theme = useTheme()

  const { getRootProps, getInputProps, isFileDialogActive, isDragActive } = useDropzone({
    ...rest,
    onDropRejected: setErrorMessages,
    onDropAccepted: setFiles,
  })
  const { disabled } = rest

  useEffect(() => {
    if (isFileDialogActive || isDragActive) {
      resetErrorMessages()
    }
  }, [isFileDialogActive, isDragActive, resetErrorMessages])

  return (
    <StyledWrapper maxWidth={maxWidth}>
      <DropdownContainer {...getRootProps({ isDragActive })} isDisabled={disabled}>
        <input {...getInputProps(inputProps)} />
        <Placeholder centerContent={centerContent}>
          <StyledUploadIcon
            aria-hidden={true}
            centerContent={centerContent}
            width={`${theme.xyz.iconSize.s}em`}
            height={`${theme.xyz.iconSize.s}em`}
            color={disabled ? theme.xyz.color.neutralGray5 : theme.xyz.color.neutralIconGray}
          />
          <Text isDisabled={disabled} centerContent={centerContent}>
            {placeholderText}
          </Text>
        </Placeholder>
      </DropdownContainer>
      {filesDisplay}
      <ErrorMessages errorMessages={errorMessages} />
    </StyledWrapper>
  )
}

FileUpload.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      lastModified: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired as Validator<File[]>,
  setFiles: PropTypes.func.isRequired,
  placeholderText: PropTypes.node.isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  setErrorMessages: PropTypes.func.isRequired,
  resetErrorMessages: PropTypes.func.isRequired,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  preventDropOnDocument: PropTypes.bool,
  noClick: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  inputProps: PropTypes.object,
  maxWidth: PropTypes.string,
  centerContent: PropTypes.bool,
  filesDisplay: PropTypes.node.isRequired,
}

FileUpload.defaultProps = {
  preventDropOnDocument: false,
  noClick: false,
  noKeyboard: false,
  noDrag: false,
  noDragEventsBubbling: false,
  maxWidth: 'none',
  centerContent: false,
}

export { FileUpload }
