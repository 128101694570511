import React, { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { XyzTheme } from '@postidigital/posti-theme'
import { CloseIcon } from '../../design-tokens/icons'
import { FocusInner } from '../../basic-components/ActionButton/ActionButton'

enum Size {
  md = 'md',
  sm = 'sm',
}

export interface CloseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  'aria-label': string
  iconColor?: string
  size?: keyof typeof Size
}

const ButtonArea = styled.button<CloseButtonProps>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    background: transparent;
    padding: 0;
    border: none;
    border-radius: 3rem;

    & > span {
      padding: ${theme.xyz.spacing.space1}rem;
    }

    &:hover {
      background: rgba(246, 247, 249, 0.4);
    }

    &:focus {
      outline: 0;
      background: rgba(246, 247, 249, 0.4);
      box-shadow: 0px 0px 7px 0px ${theme.xyz.color.signalBlue};
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
    }
  `
)

/** CloseButton Component
 * @deprecated Use IconButton with CloseIcon instead
 */

export const CloseButton = React.forwardRef<HTMLButtonElement, CloseButtonProps>((props, ref) => {
  const { disabled, iconColor, size, ...rest } = props

  const iconSize = size === Size.sm ? XyzTheme.iconSize.xs : XyzTheme.iconSize.s
  return (
    <ButtonArea ref={ref} disabled={disabled} aria-disabled={disabled} tabIndex={0} {...rest}>
      <FocusInner tabIndex={-1}>
        <CloseIcon
          width={`${XyzTheme.iconSize.s}em`}
          height={`${XyzTheme.iconSize.s}em`}
          color={disabled ? XyzTheme.color.neutralPassiveGray : iconColor}
        />
      </FocusInner>
    </ButtonArea>
  )
})

CloseButton.displayName = 'CloseButton'

CloseButton.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  iconColor: PropTypes.string,
}

CloseButton.defaultProps = {
  iconColor: XyzTheme.color.neutralIconGray,
}
