import styled, { css } from 'styled-components'
import { Headline } from '../../design-tokens/typography'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { BoxIcon, CopyIcon } from '../../design-tokens/icons'
import { Input } from '../Input/Input'

export const ButtonWrapper = styled.button`
  ${({
    theme: {
      xyz: { color, spacing, borderRadius },
    },
    disabled,
  }) => css`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${disabled ? color.neutralGray2 : color.neutralWhite};
    border: 0;
    transition: all 100ms ease-in;
    box-shadow: ${disabled
      ? 'none'
      : '0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04)'};
    border-radius: ${borderRadius.xs}rem;
    padding: 0.75rem ${spacing.space3}rem;
    min-height: 3.5rem;

    ${!disabled &&
    css`
      &:hover {
        background-color: ${color.neutralOnHoverGray};
      }

      &:focus {
        outline: none;
        border-color: ${color.signalBlue};
        box-shadow: 0px 0px 7px ${color.signalBlue};
      }

      &:active {
        background-color: ${color.neutralOnPressGray};
      }

      &:focus:not(:focus-visible) {
        outline: none;
        box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
          0px 3px 1px rgba(0, 0, 0, 0.04);
      }
    `}
  `}
`

export const StyledHeadline = styled(Headline)`
  width: 100%;
  text-align: left;
`

export const StyledIcon = styled(BoxIcon)<DefaultIconProps>`
  ${({ theme }) => css`
    flex-shrink: 0;
    width: ${theme.xyz.iconSize.s}rem;
    height: ${theme.xyz.iconSize.s}rem;
    margin-right: ${theme.xyz.spacing.space3}rem;
  `}
`

export const StyledCopyIcon = styled(CopyIcon)<DefaultIconProps>`
  width: auto;
  height: 2rem;
`

export const StyledInput = styled(Input)`
  ${({ theme }) => css`
    margin-top: ${theme.xyz.spacing.space5}rem;
  `}
`
